

const CouponCodes = [
  {
    couponName: 'over$1000',
    isPercent: true,
    percentOff: 10,
    dollarOff: 0,
    minCost: 1000
  },
  {
    couponName: 'over$2500',
    isPercent: true,
    percentOff: 15,
    dollarOff: 0,
    minCost: 2500
  },
  {
    couponName: 'Olypro5',
    isPercent: true,
    percentOff: 5,
    dollarOff: 0,
    minCost: 0
  },
  {
    couponName: 'Olydealer10',
    isPercent: true,
    percentOff: 10,
    dollarOff: 0,
    minCost: 0
  }
]

export default CouponCodes;