import React from "react";
import { useParams } from "react-router-dom";
import './indivSteroidProfile.css'

import steroidProfilesData from "../../Comps/Products/steroidProfiles";

const IndivSteroidProfile = () => {
  const { spId } = useParams();



  return (
    <div className="indiv-steroid-prof-main">
      {steroidProfilesData.filter(items => items.spId.toString() === spId.toString()).map((item) => (
        <div className="all-indiv-ster-info-div">
          <h1>{item.spName}</h1>
            {item.description.length === 0 ? '' 
            : item.description.length === 1 ? 
            (<p>{item.description[0]}</p>) 
            : 
            (
              item.description.map((descriptions) => (
                <p>{descriptions}</p>
              ))
            )}

            {item.spCategories.length === 0 ? '' 
            : item.spCategories.length === 1 ? 
            (<div>
            <h3>{item.spCategories[0]}</h3>  
                {item.spCatDescriptions[0].map((spCatDescriptions) => (
                  <p>{spCatDescriptions}</p>
                ))}
            </div>) 
            : 
            (
              item.spCategories.map((spCategories, index) => (
              <div>
                <h3>{spCategories}</h3>

                
                {item.spCatDescriptions[index].map((spCatDescriptions) => (
                  <p>{spCatDescriptions}</p>
                ))}
              </div>
              ))
            )}
        </div>
      ))}
    </div>
  )
}

export default IndivSteroidProfile;