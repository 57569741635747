import React, { useEffect, useState } from "react";
import './allSpecials.css'
import { Link, useParams } from "react-router-dom";

import globalProducts from "../../Comps/Products/products";
import prodSubCategories from "../../Comps/Products/productSubCategories";
import productsBackend from "../../Comps/Products/productBackend";
import { useCart } from "../../Comps/Cart/CartContext";

const AllSpecials = () => {
  const { specialName } = useParams();
  const { dispatch } = useCart()
  
  const specialsProducts = [
    // Mass Gain
    [10041, 10042, 10038, 10039, 10051, 10057, 10048, 10055, 10052, 10060, 10043, 10044, 10045, 10046],
    // Muscle Gain
    [10045,10046,10030,10031,10032,10033,10038,10039,10040,10043,10044,10035],
    // Pre Workout
    [],
    // Quality Blends
    [],
    // Lab Tested
    [],
    // Weight Loss
    [10086,10084,10067,10030,10031,10053,10062,10056]
  ]

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const index = specialName === 'mass-gain' ? 0 : 
      specialName === 'muscle-gain' ? 1 :
      specialName === 'pre-workout' ? 2 :
      specialName === 'quality-blends' ? 3 :
      specialName === 'lab-tested' ? 4 : 
      specialName === 'weight-loss' ? 5 :
      0;
    const idsToFilter = specialsProducts[index];
    const result = idsToFilter.map(id => globalProducts.find(product => product.productId === id));
    setFilteredData(result);
    console.log(idsToFilter)
    console.log(result)
  }, [specialName, globalProducts]);

  const addCartItem = (thisProduct) => {
    productsBackend.filter((item) => (
      item
    ));
    const thisCurrentProduct = productsBackend.find(item => item.productBackendId.toString() === (thisProduct.productId.toString() + '111'));
    
    const thisCurrentProductNew = 
      thisCurrentProduct.discountedPrice === null ? thisCurrentProduct
      : thisCurrentProduct.discountedPrice < 1 ? { ...thisCurrentProduct, price: thisCurrentProduct.price * thisCurrentProduct.discountedPrice }
      : thisCurrentProduct.discountedPrice >= 1 ? { ...thisCurrentProduct, price: thisCurrentProduct.price - thisCurrentProduct.discountedPrice }
      : thisCurrentProduct

    
    dispatch({ type: 'ADD_ITEM', payload: thisCurrentProductNew })
    return;
  }

  return (
    <div className="special-home-main">
      <h2 className="special-home-title">
        Top Products: <span>
          {specialName === 'mass-gain' ? " Mass Gain" : 
          specialName === 'muscle-gain' ? " Muscle Gain" :
          specialName === 'pre-workout' ? " Pre Workout" :
          specialName === 'quality-blends' ? " Quality Blends" :
          specialName === 'lab-tested' ? " Lab Tested" : 
          specialName === 'weight-loss' ? " Weight Loss" : 
          ''}
        </span>
      </h2>
      <div className="special-products-div">
        {filteredData.map((item) => (
          <div className="each-products" key={item.productId}>
            
            <Link 
              className="" 
              to={prodSubCategories
                .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
                .map(cat => `/store/` + cat.subName + '/' + item.productId)
                .join('')
              }>
              <img src={item.img} alt={item.name}/>
            </Link>
            <h3>{item.name}</h3>
            {item.discountedPrice === null ? (
              <h2>${item.basePrice}.00</h2>
            ) : item.discountedPrice < 1 ? (
              <div>
                <h2 className="specials-product-cost-slash">{'Price: $' + item.basePrice + ".00"}</h2>
                <h2>{(item.basePrice * item.discountedPrice % 1) === 0 ? `Price: $${item.basePrice * item.discountedPrice}.00` :  `Price: $${Math.round(item.basePrice * item.discountedPrice * 10)/10}0`}</h2>
              </div>
            ) : (
              <div>
                <h2 className="specials-product-cost-slash">{'Price: $' + item.basePrice + ".00"}</h2>
                <h2>{`Price: $${item.basePrice - item.discountedPrice}.00`}</h2>
              </div>
            )}
            <button onClick={() => addCartItem(item)}>
              Add To Cart
            </button>

          </div>
        ))}
      </div>
    </div>
  );
};

export default AllSpecials;