import React from "react";
import './about.css'


const About = () => {
  

  return (
    <div className="about-us-main">
      {/* Heading */}
      <div className="about-heading">
        <h1>About</h1>
      </div>

      {/* About Sections */}
      <div className="about-sections">
        <h5>Who Are We?</h5>
        <div>
          <p>
            At Olympian Pharmaceutical, we understand that when you're ready to elevate your performance, you want your products in hand without delay. That's why our dedicated team ensures the fastest shipping in the industry. We prioritize your needs, processing orders swiftly and efficiently so you can focus on your training and achieve your goals without interruption. Experience speed and reliability with every order.
          </p>
          <p>
            Quality is our cornerstone. Our team is committed to sourcing and creating the industry's top of the line products and employing rigorous testing standards to ensure every product meets the highest benchmarks of today's community. We believe that you deserve only the best and our dedication to superior quality means you can trust our products to deliver.
          </p>
          <p>
            Our knowledgeable and friendly team is here to support you every step of the way, whether you have questions about our products or need guidance on your fitness journey. We pride ourselves on being responsive and accessible, ensuring you feel valued and heard.
          </p>
          <p>
            At Olympian Pharmaceutical, we draw our inspiration from the legendary Greek gods, whose strength, resilience, and determination have inspired countless generations. Just as these mythic figures embodied the pinnacle of human potential, our mission is to empower you to achieve your own greatness.
          </p>
          <p className="bold-span">
            Inspired by Legends, Built for Champions.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About;