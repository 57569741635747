import React, { useState } from "react";
import './popup1.css';

import siteWideDiscount from '../../Products/siteWideDiscount'

const Modal = ({ show, onClose }) => {
    if (!show) return null;
  
    return (
      <div className="modal-overlay modal">
        <div className="modal-content">

            <div>
                <h3>Sitewide Discount!</h3>
                {siteWideDiscount.isPercent ? (
                    <div>
                        <h4 className="pulse-text-popup1">{`${siteWideDiscount.percentOff * 100}% Off On EVERYTHING!`}</h4>
                    </div>
                ) : (
                    <div>
                        <h4 className="pulse-text-popup1">{`$${siteWideDiscount.dollarOff} Off On EVERYTHING!`}</h4>
                    </div>
                )}
            </div>

            {/* Close Modal BTN */}
            <span id="modal-close-btn" className="close" onClick={onClose}>&times;</span>
          
        </div>
      </div>
    );
  };
  


const Popup1 = () => {
    const localDismiss = localStorage.getItem('dismissed-popup1')
    const [modalShown, changeModalState] = useState(localDismiss === 'popup1-dismissed' ? false : true)

    const dismissingModal = () => {
        localStorage.setItem('dismissed-popup1', 'popup1-dismissed')
        changeModalState(false)
    }

    return (
        <div>
            {/* <button id="modal-open-btn" onClick={() => changeModalState(true)}>Open Modal</button>*/}
            <Modal show={modalShown} onClose={() => dismissingModal()} />
        </div>
    )
}

export default Popup1;