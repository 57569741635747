import React from 'react';
import { useCart } from '../../Comps/Cart/CartContext';
import './cart.css'
import { Link } from 'react-router-dom';
import prodSubCategories from '../../Comps/Products/productSubCategories';
import globalProducts from '../../Comps/Products/products';

const Cart = () => {
  const { cart, totalCost, totalCostShipping, dispatch } = useCart();

  const decreaseItem = (item) => {
    dispatch({ type: 'REMOVE_ITEM', payload: { productBackendId: item.productBackendId } });
  };

  const removeItem = (item) => {
    dispatch({ type: 'REMOVE_ITEM_TOTAL', payload: { productBackendId: item.productBackendId } })
  }

  const increaseItem = (item) => {
    dispatch({ type: 'ADD_ITEM', payload: { productBackendId: item.productBackendId } })
  }

  const setPaymentMethod = (method) => {
    dispatch({ type: 'SET_PAYMENT_METHOD', payload: method });
  };

  return (
    <div className='shopping-cart-main'>
      <h2>Shopping Cart</h2>


      {/* Cart Items */}
      {cart.length === 0 ? (
        <div className='empty-cart-div'>
          <h1 className='empty-cart-h1'>Your Cart Is Empty</h1>
        </div>
      ) : 
      <div className='cart-items'>
        <div className='cart-titles'>
          <h3 id='cart-titles-1'>Item</h3>
          <h3 id='cart-titles-2'>Price</h3>
          <h3 id='cart-titles-3'>Quantity</h3>
          <h3 id='cart-titles-4'>Subtotal</h3>
          <h3 id='cart-titles-5'></h3>
        </div>
        {cart.map(item => (
        <div className='cart-item' key={item.productFrontId}>
          
          <hr className='responsive-item-top-hr'/>
          <div className='cart-img-div'>
          <Link
            to={prodSubCategories
              .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
              .map(cat => 
              `/store/` + cat.subName + '/' + item.productFrontId)
              .join('')}>
              <img src={item.img} alt={item.name}/>
            </Link>
          </div>
          <div className='cart-num-quant'>
            <div className='cart-num-quant-l'>
              <h3>{item.name}</h3>
              <div>
                {item.concentration.length === 0 
                ? '' : 
                (<p>Concentraion:<span>{item.concentration}</span></p>)}
                {item.volume.length === 0 
                ? '' : 
                (<p>Volume:<span>{item.volume}</span></p>)}
                {item.carrierOil.length === 0 
                ? '' : 
                (<p>Carrier Oil:<span>{item.carrierOil}</span></p>)}
                <p className='cart-price-responsive-p'>Price: <span>{(item.price % 1) === 0 ? ` $${item.price}.00` :  ` $${Math.round(item.price * 10)/10}0`}</span></p>
                <p className='cart-price-responsive-p'>Quantity: 
                  <h5 className='cart-price-responsive-h5 cart-quantity-minus-r' onClick={() => decreaseItem(item)}>-</h5>
                  <h5 className='cart-price-responsive-h5'>{` ${item.quantity}`}</h5>
                  <h5 className='cart-price-responsive-h5 cart-quantity-plus-r' onClick={() => increaseItem(item)}>+</h5>
                </p>
                {item.quantity >= 2 ? <p className='cart-price-responsive-p'>Subtotal: <span>{item.price * item.quantity % 1 === 0 ? `${(item.price * item.quantity).toLocaleString(undefined, {maximumFractionDigits:2})}.00` : `$${Math.round(item.price * item.quantity * 10)/10}0`}</span></p> : ''}
              </div>
              
            </div>
          </div>
            <div className='cart-num-quant-r'>
              <div>
                <div className='responsive-cart-num-r'>
                  <h4>Price:</h4>
                </div>
                <h5 className='cart-num-quant-r-h5'>
                {/* {'$' + item.price + '.00'} */}
                {(item.price % 1) === 0 ? `$${item.price}.00` :  `$${Math.round(item.price * 10)/10}0`}
                </h5>
              </div>
              <div className='cart-num-quant-r-quantity'>
                <div className='responsive-cart-num-r'>
                  <h4>Quantity:</h4>
                </div>
                <h5 className='cart-num-quant-r-h5'>
                  <span id='cart-quantity-minus' onClick={() => decreaseItem(item)}>-</span>
                    {item.quantity}
                  <span id='cart-quantity-plus' onClick={() => increaseItem(item)}>+</span>
                </h5>
              </div>
              <div>
                <div className='responsive-cart-num-r'>
                  <h4>Sub Total:</h4>
                </div>
                <h5 className='cart-num-quant-r-h5'>{item.price * item.quantity % 1 === 0 ? `${(item.price * item.quantity).toLocaleString(undefined, {maximumFractionDigits:2})}.00` : `$${Math.round(item.price * item.quantity * 10)/10}0`}</h5>
              </div>
            </div>
            <div className='remove-from-cart-btn-div'>
              <button className='remove-from-cart-btn' onClick={() => removeItem(item)}>
                Remove
              </button>
            </div>
        </div>
        ))}
      </div>
      }

      {cart.length === 0 ? '' :<div>
        <h2>Checkout</h2>
        <hr/>
        {totalCost >= 150 ? 
        <div className='cart-summary-div'>
        <h4>Order Summary</h4>
          <p>Subtotal: <span className='total-pulse'>{totalCost % 1 === 0 ? `$${totalCost.toLocaleString(undefined, {maximumFractionDigits:2})}.00` : `$${Math.round(totalCost * 10)/10}0`}</span></p>
          
          <h3 className='shipping-warning-cart'>{`Shipping :`}{totalCost < 500 ? ` +$14  : (Free > $500)}` : ` Free!`}</h3>

          <p>Total Cost: <span className='total-pulse'>{totalCost % 1 === 0 ? `$${totalCostShipping.toLocaleString(undefined, {maximumFractionDigits:2})}.00` : `$${Math.round(totalCostShipping * 10)/10}0`}</span></p>
        <h4>Submit Order Now</h4>
        
      <div className='crypto-checkout'>
        <div>
          <Link className='crypto-link' to='/cart/checkout'>
            <button onClick={() => setPaymentMethod(1)}>Bitcoin</button>
          </Link>
        </div>
        <div>
          <Link className='crypto-link' to='/cart/checkout'>
            <button onClick={() => setPaymentMethod(2)}>Ethereum</button>
          </Link>
        </div>
        {/* <div>
          <Link className='crypto-link' to='/cart/checkout'>
            <button onClick={() => setPaymentMethod(3)}>Solana</button>
          </Link>
        </div>
        <div>
          <Link className='crypto-link' to='/cart/checkout'>
            <button onClick={() => setPaymentMethod(4)}>Litecoin</button>
          </Link>
        </div> */}
      </div>
    </div>
    
    :
     
      <div>
        <div className='cart-summary-div-low'>
        <h4>Order Summary</h4>
          <h3>Subtotal: <span className='total-low'>{totalCost % 1 === 0 ? `$${totalCost.toLocaleString(undefined, {maximumFractionDigits:2})}.00` : `$${Math.round(totalCost * 10)/10}0`}</span></h3>

          <h3 className='total-low-desc'>Order is Under $150.00 Minimum</h3>
          <h3 className='total-low-desc'>Please Add More to Continue</h3>
        </div>
      </div>
      }
        
      </div>}
    </div>
  );
};

export default Cart;
