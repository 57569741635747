import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarDropdown = () => {
    const [selectedDate, setSelectedDate] = useState(null);
  
    return (
      <div style={
        {display: 'flex', flexDirection: 'column', justifyContent: 'center'}} className='date-picker-div'>
        <h3 style={{textAlign: 'center'}}>Select a date</h3>
        <DatePicker
            className='date-picker-picker'
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="MM-dd-yyyy"
            placeholderText="Select or Leave Blank"
        />
      </div>
    );
  };

  export default CalendarDropdown;