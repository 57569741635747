import React from "react";
import './contact.css'


const Contact = () => {
  

  return (
    <div>
      {/* Heading */}
      <div className="contact-heading">
        <h1>Contact</h1>
      </div>
    </div>
  )
}

export default Contact;