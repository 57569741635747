

const faqDatabase = [
  {
    Question: "How do I know that your products are properly dosed?",
    Answer: [
      "Almost all of our products are sent off for testing before being listed and results are public so customers can verify the dosage. In cases where something does come back underdosed, we’ll usually list that product at a discount.",
      "We also encourage our customers to do their own blind testing/bloodwork and offer store credit for doing so. This keeps us honest about our products and ensures you’re getting what is advertised.",
      "All test results are available for viewing on our “Test Reports” page and linked in each products’ description.",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "Is my personal information safe when making a purchase through the site?",
    Answer: [
      "Of course! Customer safety was a top priority and an absolute non-negotiable when developing the site. We use highly secured SSL protocols and have Operational Security (OPSEC) in place, which prevents sensitive information from getting into the wrong hands.",
      "Servers are deeply encrypted by advanced infrastructure, which is completely developed and maintained by professional programmers and security specialists. Our team works 24/7 to analyze any threats or vulnerabilities and always maintains an extra layer of patch to protect and secure the data.",
      "Rest assured, you're in good hands!"
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "Do you accept any other forms of payment besides BTC or ETH?",
    Answer: [
      "We only accept BTC and ETH on our website at this current moment. If you would like to use another form of crypto (LTC, USDC, XMR, etc.), we can likely accommodate your preference via email. Although, ordering through our website is the fastest way for your order to be processed.",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: ["we don’t accept any other forms of payment besides cryptocurrency. Please do not ask."],
    Notice: []
  },
  // {
  //   Question: "How do I use BTC to send a payment?",
  //   Answer: [
  //     "A wallet is a digital application or device that allows you to store, send, and receive BTC. There are various types of wallets available, please do your own research and choose a wallet that suits your needs and follow the instructions to create one.",
  //     "Register on a reputable platform, complete the necessary verification process, and deposit fiat currency into your account. Then, you can use the funds to buy BTC at market price. Ideally, you’ll want to purchase a bit more than you plan on spending to cover any extra fees you may incur during the transaction (no fees are added directly from us, but all cryptocurrency costs a small fee to send).",
  //     `Copy/paste the address at the bottom of your invoice into your wallet's "send" or "withdraw" function. Copy/paste the BTC amount from the invoice, then confirm the transaction.`,
  //   ],
  //   hasSubs: true,
  //   subTitles: [
  //     "First, you will need to set up a BTC wallet:",
  //     "After you have created your wallet, you’ll need to buy BTC from a cryptocurrency exchange:",
  //     "Once you have $BTC in your wallet, you can easily send a payment:",
  //   ],
  //   Note: ["BTC transactions are irreversible, so double-check all transaction details before confirming."],
  //   Notice: ["Please be sure to use the BTC address provided on your current invoice. DO NOT REUSE A BTC ADDRESS FROM A PREVIOUS ORDER!! Otherwise, our system won’t be able to differentiate payments and your order won’t be processed correctly."]
  // },
  {
    Question: "How do I use BTC/ETH to send a payment?",
    Answer: [
      "A wallet is a digital application or device that allows you to store, send, and receive BTC/ETH. There are various types of wallets available, please do your own research and choose a wallet that suits your needs and follow the instructions to create one.  Wallets for both Bitcoin and Ethereum do exist, but they are entirely seperate, please do your own research before sending money to any wallet.",
      "Register on a reputable platform, complete the necessary verification process, and deposit fiat currency into your account. Then, you can use the funds to buy BTC/ETH at market or a set limit price. Ideally, you’ll want to purchase a bit more than you plan on spending to cover any extra fees you may incur during the transaction (no fees are added directly from us, but all cryptocurrency costs a small fee to send).",
      `Copy/paste the address given to you into your wallet's "send" or "withdraw" function (Note the address is different for each cryptocurrency). Copy/paste the BTC/ETH amount from your order, then confirm the transaction.`,
    ],
    hasSubs: true,
    subTitles: [
      "First, you will need to set up a BTC and/or ETH wallet:",
      "After you have created your wallet, you’ll need to buy BTC/ETH from a cryptocurrency exchange:",
      "Once you have $BTC or $ETH in your respected wallet, you can easily send a payment:",
    ],
    Note: ["BTC and ETH transactions are irreversible, so double-check all transaction details before confirming."],
    Notice: ["Please be sure to use the respective BTC or ETH address provided. DO NOT REUSE A BTC OR ETH ADDRESS FROM A PREVIOUS ORDER UNLESS OTHERWISE SAID!! Otherwise, your order may not be processed correctly.  Do NOT send funds to a address for a different cryptocurrency than you are using, your funds WILL BE LOST!"]
  },
  {
    Question: "How do I know my order/payment has been received?",
    Answer: [
      "After you send payment, please allow some time for your transaction to be confirmed on the blockchain (confirmation times may vary). Once confirmed, you will receive a confirmation email shortly after, and tracking is usually sent within 24 hours of that. As long as you receive confirmation, your order will be processed and you should receive tracking. Please allow up to 48 hours to receive a tracking number before reaching out to our team.",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "I sent my payment but never received a confirmation/tracking number what should I do?",
    Answer: [
      "If you notice that your payment has been confirmed on the blockchain and you have yet to receive a confirmation within 48 hours, please reach out and we’ll be happy to assist you.",
      "Although this is unusual, it can happen for a variety of reasons so we will need to look into it to find the cause. But we assure you, if your payment was indeed sent, you’ll be taken care of.",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "How long will it take to receive my order?",
    Answer: [
      "Typically, we send out packages within 24 hours of the payment being received. They usually arrive within 2-4 business days. If your pack is taking longer than usual, there’s no need to panic; we have virtually 100% success rate.",
      "So, just double check that the address on your confirmation is correct and be patient. If the tracking says it’s on its way, it almost always shows up eventually. Please allow a reasonable amount of time before reaching out to inquire about a reship. If the mistake was on us, we will always do our best to make you whole in the end."
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "I can’t complete my order because I lost access to my order number, what do I do?",
    Answer: [
      "If your order number got lost in the sauce, reach out to our team with your order information and we can send you your order number to complete your order.",
      "Or if you’d like, you may create a new order and disregard the old one, it will eventually expire in our system."
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "I placed an order but can’t make the payment immediately, how long before it expires?",
    Answer: [
      "Orders are set to expire after 2-3 days. Please create a new order if you cannot make the payment before the expiration.",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: ["if a payment is sent after expiration, it will likely be processed incorrectly and may need to be brought to our attention to be manually processed."],
    Notice: []
  },
  {
    Question: "What is your refund policy?",
    Answer: [
      "We do not allow refunds/exchanges. If you believe you’re entitled to a refund/exchange, feel free to reach out and share why. We will always try to give customers the benefit of the doubt and put them first, so there is a possibility that we’ll give out a refund/exchange in some cases.. but we are not obligated to do so under most circumstances.",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "I received my order but some items are missing/incorrect, what can I do?",
    Answer: [
      "If you received your package and items are incorrect or you’re missing something, please reach out to our team with proof and we will respond accordingly. Typically, you’ll get to keep the items and we’ll send out the correct ones, free of charge!",
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "Do you offer any special deals or bulk discounts?",
    Answer: [
      "We do catalog sales frequently! You can sign up for our newsletter and/or keep an eye on our forum posts to stay up to date on any upcoming sales!",
      "We currently offer 10% discounts on orders over $1,000, and 15% discounts on orders over $2,500."
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "Do you offer any store credit for blind testing/bloodwork?",
    Answer: [
      "Yes! We currently offer store credit for blind testing and bloodwork! We also offer credit for reviews posted about us on forums! Just send the proof to Olympicpharma@proton.me to receive a code that you can apply on your next purchase!",
      "Testing: $200",
      "Bloodwork: $100",
      "Reviews: $15"
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: [],
    Notice: []
  },
  {
    Question: "I have an issue/concern that hasn’t been mentioned… how do I contact the team?",
    Answer: [
      "We recommend that you first create a ProtonMail account before attempting to contact us. This ensures the safety of both you, and ourselves.",
      `ProtonMail is an end-to-end encrypted email service that helps to protect users’ privacy when sending or receiving emails. It’s very easy to use, free to set up, and takes seconds to create an account. Once set up, you can reach out to us at: Olympicpharma@proton.me`,
    ],
    hasSubs: false,
    subTitles: [
      "",
    ],
    Note: ["We do, in fact, use ProtonMail to encrypt all of our communications. (Encryption only works if both users have Proton. It will not be effective otherwise, even if you’re using a different end-to-end encryption platform)"],
    Notice: []
  },
]

export default faqDatabase;