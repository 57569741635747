// Comps/CryptoPrice/ethPrice.js
import axios from 'axios';

export const getEthereumPrice = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: 'ethereum',
        vs_currencies: 'usd',
      },
    });
    return response.data.ethereum.usd;
  } catch (error) {
    console.error('Error fetching Ethereum price:', error);
    return null;
  }
};
