import React from "react";
import './footer.css'
import { Link } from "react-router-dom";


import OPLogo3 from '../../images/OPLogo4.png'

// Seals
import abxSeal from '../../images/home-icons-images/anabolex-approved.png'
import ioSeal from '../../images/home-icons-images/ironoverloadio-approved.png'
import mesoSeal from '../../images/home-icons-images/meso-badge.png'
import mcSeal from '../../images/home-icons-images/musclchemistry-approved.png'

const Footer = () => {
  

  return (
    <div className="footer-main">
      <div className="footer-div">
        <div className="footer-js-div">
          <h3>Olympian Pharmaceutical</h3>
        </div>
        <div className="footer-img-div"> 
          <img src={OPLogo3} alt="OPLogo"/>
        </div>
        <div className="footer-nav">
          {/* <h3></h3> */}
          <div>
            <Link className="footer-links" to="/steroid-profiles">
            <h3>Steroid Profiles</h3>
            </Link>
            <Link className="footer-links" to="/order-status">
            <h3>Order Status</h3>
            </Link>
          </div>
          <div>
            <Link className="footer-links" to="/restorepmt">
            <h3>Finish Payment</h3>
            </Link>
            <Link className="footer-links" to="/faqs">
            <h3>FAQs</h3>
            </Link>
            {/* <Link className="footer-links" to="/contact">
            <h3>Contact</h3>
            </Link> */}
          </div>
        </div>
      </div>

        <hr id="seal-hr"/>
      {/* Seals */}
      <div className="seals-div">
        <div>
          <Link target="_blank" to='https://anabolex.com/community/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.13106/'>
            <img src={abxSeal} alt="abxSeal"/>
          </Link>
          <Link target="_blank" to='https://www.ironoverload.io/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.3025/'>
            <img src={ioSeal} alt="ioSeal"/>
          </Link>
        </div>
        <div>
          <Link target="_blank" to='https://www.meso-morph.com/community/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.1365/'>
            <img src={mesoSeal} alt="mesoSeal"/>
          </Link>
          <Link target="_blank" to='https://www.musclechemistry.com/forums/threads/olympianpharma-ltd-reviews-delivery-proof-and-feedback.139160/'>
            <img src={mcSeal} alt="mcSeal"/>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer;