import vileOils from '../../images/vile v2.png'
import pillBottleOrals from '../../images/pill container v2.png'

import siteWideDiscount from './siteWideDiscount';

const productsBackend = [
  // Zeus Blend
//   {
//     img: vileOils,
//     name: "Zeus' Power Bulk",
//     productBackendId: 10001111,
//     productFrontId: 10001,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Tren A (100mg) / NPP(100) / Prop(100mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 95,
//     description: [ 
//       'Vials filled slightly above selected volume.', 
//       '*For intramuscular use only'
//     ],
//   },
// //  Basic Bulk
//   {
//     img: vileOils,
//     name: "Basic Bulk",
//     productBackendId: 10002111,
//     productFrontId: 10002,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Prop(100mg) / NPP',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 65,
//     description: [

//     ],
//   },
// //   Achilles Blend
//   {
//     img: vileOils,
//     name: "Achilles Advanced Bulk",
//     productBackendId: 10003111,
//     productFrontId: 10003,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Tren E (150mg) / Deca(150mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 90,
//     description: [ 
//         'Vials filled slightly above selected volume.', 
//       '*For intramuscular use only'
//   ],
//   },
// //   Hades PreWorkout
//   {
//     img: vileOils,
//     name: "Hades Wrath PreWorkout",
//     productBackendId: 10004111,
//     productFrontId: 10004,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Tren Base(30mg) / Halo(10mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 90,
//     description: [ 
//       'Vials filled slightly above selected volume.', 
//       '*For intramuscular use only',
//       '**CONTAINS GUAIACOL**',
//   ],
//   },
// //   Poseidon Blend
//   {
//     img: vileOils,
//     name: "Poseidon's Prime Shred",
//     productBackendId: 10005111,
//     productFrontId: 10005,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Primo E(100mg) / Test(200mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 80,
//     description: [

//     ],
//   },
// //   Clasic Bulk
//   {
//     img: vileOils,
//     name: "Clasic Bulk",
//     productBackendId: 10006111,
//     productFrontId: 10006,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Test E(100mg) / Deca(200mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 85,
//     description: [

//     ],
//   },
// //  Ares Preworkout
//   {
//     img: vileOils,
//     name: "Ares Awakening PreWorkout",
//     productBackendId: 10007111,
//     productFrontId: 10007,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'TestBase(30mg) / TrenBase(30mg) / Superdrol(10mg) / Halo(10mg) / M-Tren(2mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 100,
//     description: [
//         '**CONTAINS GUAIACOL**'
//     ],
//   },
// //  Lean Gains 
//   {
//     img: vileOils,
//     name: "Lean Gains",
//     productBackendId: 10008111,
//     productFrontId: 10008,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'EQ(200mg) / Mast E (100mg) / Tren E(50mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 85,
//     description:[

//     ]
//   },
// //  Apollo Blend
//   {
//     img: vileOils,
//     name: "Apollo's Advanced Bulk",
//     productBackendId: 10009111,
//     productFrontId: 10009,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Test P(100mg) / Mast P(100mg) / NPP(50mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 110,
//     description:[
    
//     ]
//   },
// //   Prep Blend
//   {
//     img: vileOils,
//     name: "Prep Blend",
//     productBackendId: 10010111,
//     productFrontId: 10010,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Mast E(100mg) / Primo E(100mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 100,
//     description:[
//     'Vials filled slightly above selected volume.',
//     '*For intramuscular use only.',

//     ]
//   },
// //   Quick Bulk
//   {
//     img: vileOils,
//     name: "Quick Bulk",
//     productBackendId: 10011111,
//     productFrontId: 10011,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Test P(250mg) / NPP(100mg) / (Deca200mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 70,
//     description:[
//     '**CONTAINS GUAIACOL**'
//     ],
    
//   },
// //  Shred Blend
//   {
//     img: vileOils,
//     name: "Shred Blend",
//     productBackendId: 10012111,
//     productFrontId: 10012,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Tren A(100mg) / Mast P(100mg) / Deca(100mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 100,
//     description:[
//       'Vials filled slightly above selected volume.',
//       '*For intramuscular use only.',
//     ]
    
//   },
// // Shred Blend Long
//   {
//     img: vileOils,
//     name: "Shred Blend Long",
//     productBackendId: 10013111,
//     productFrontId: 10013,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Tren E(100mg) / Mast E(100mg) / Test E(50mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 90,
//     description:[
    
//     ]
//   },
// //  Hephaestus PreWorkout
// {
//     img: vileOils,
//     name: "Zeus Power Shred",
//     productBackendId: 10014111,
//     productFrontId: 10014,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'TestBase(30mg) / TrenBase(30mg)M-Tren(1mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 80,
//     description:[
//       'Vials filled slightly above selected volume.',
//       '*For intramuscular use only.',
//       '**CONTAINS GUAIACOL**',
//     ]
//   },
// // Ultimate Bulk
// {
//     img: vileOils,
//     name: "Ultimate Bulk",
//     productBackendId: 10015111,
//     productFrontId: 10015,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Test P(100mg) / Anadrol(40mg) / Dbol(30mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 65,
//     description:[
//     '**CONTAINS GUAIACOL**'
//     ]
//   },
// //  Winter Gains Blend
//   {
//     img: vileOils,
//     name: "Winter Gains Blend",
//     productBackendId: 10016111,
//     productFrontId: 10016,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'Deca(200mg) / Dbol(30mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 85,
//     description:[
//         'Vials filled slightly above selected volume.',
//         '*For intramuscular use only.',
//       ]
//   },
// //   Hercules Blend
//   {
//     img: vileOils,
//     name: "Hercules Heavyweight Blend",
//     productBackendId: 10017111,
//     productFrontId: 10017,
//     discountedPrice: 
//     siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
//     : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
//     : null,
//     subCategory: 9,
//     sProfile: 0,
//     concentration: 'NPP(100mg) / Superdrol(20mg)',
//     hasDiscount: false, 
//     volume: "10ml",
//     carrierOil: [],
//     price: 110,
//     description: [ 
//         'Vials filled slightly above selected volume.', 
//       '*For intramuscular use only'
//   ],
//   },
  // Test E
  {
    img: vileOils,
    name: "Test E",
    productBackendId: 10019111,
    productFrontId: 10019,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 13,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 40,
  },
  {
    img: vileOils,
    name: "Test E",
    productBackendId: 10019112,
    productFrontId: 10019,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 13,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 40
  },
  {
    img: vileOils,
    name: "Test E",
    productBackendId: 10019121,
    productFrontId: 10019,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 13,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 65
  },
  {
    img: vileOils,
    name: "Test E",
    productBackendId: 10019122,
    productFrontId: 10019,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 13,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 65
  },
  // Test E High
  {
    img: vileOils,
    name: "Test E High",
    productBackendId: 10020111,
    productFrontId: 10020,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 13,
    concentration: '500mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 65,
  },
  {
    img: vileOils,
    name: "Test E High",
    productBackendId: 10020112,
    productFrontId: 10020,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 13,
    concentration: '500mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 65,
  },
  // Test C low
  {
    img: vileOils,
    name: "Test C low",
    productBackendId: 10021111,
    productFrontId: 10021,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 30,
  },
  {
    img: vileOils,
    name: "Test C low",
    productBackendId: 10021112,
    productFrontId: 10021,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 30,
  },
  // Test C
  {
    img: vileOils,
    name: "Test C",
    productBackendId: 10022111,
    productFrontId: 10022,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 40,
  },
  {
    img: vileOils,
    name: "Test C",
    productBackendId: 10022112,
    productFrontId: 10022,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 40,
  },
  {
    img: vileOils,
    name: "Test C",
    productBackendId: 10022121,
    productFrontId: 10022,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 65
  },
  // {
  //   img: vileOils,
  //   name: "Test C",
  //   productBackendId: 10022211,
  //   productFrontId: 10022,
  //   discountedPrice: 
  //   siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
  //   : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
  //   : null,
  //   subCategory: 1,
  //   sProfile: 12,
  //   concentration: '250mg',
  //   hasDiscount: false, 
  //   volume: "10ml",
  //   carrierOil: "MCT",
  //   description: [
  //     "Vials filled slightly above selected volume.",
  //     "*For intramuscular use only.",
  //   ],
  //   price: 45
  // },
  {
    img: vileOils,
    name: "Test C",
    productBackendId: 10022122,
    productFrontId: 10022,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 65
  },
  // {
  //   img: vileOils,
  //   name: "Test C",
  //   productBackendId: 10022221,
  //   productFrontId: 10022,
  //   discountedPrice: 
  //   siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
  //   : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
  //   : null,
  //   subCategory: 1,
  //   sProfile: 12,
  //   concentration: '250mg',
  //   hasDiscount: false, 
  //   volume: "20ml",
  //   carrierOil: "MCT",
  //   description: [
  //     "Vials filled slightly above selected volume.",
  //     "*For intramuscular use only.",
  //   ],
  //   price: 70
  // },
  // {
  //   img: vileOils,
  //   name: "Test C",
  //   productBackendId: 10022212,
  //   productFrontId: 10022,
  //   discountedPrice: 
  //   siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
  //   : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
  //   : null,
  //   subCategory: 1,
  //   sProfile: 12,
  //   concentration: '250mg',
  //   hasDiscount: false, 
  //   volume: "10ml",
  //   carrierOil: "GSO",
  //   description: [
  //     "Vials filled slightly above selected volume.",
  //     "*For intramuscular use only.",
  //   ],
  //   price: 45
  // },
  // {
  //   img: vileOils,
  //   name: "Test C",
  //   productBackendId: 10022222,
  //   productFrontId: 10022,
  //   discountedPrice: 
  //   siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
  //   : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
  //   : null,
  //   subCategory: 1,
  //   sProfile: 12,
  //   concentration: '250mg',
  //   hasDiscount: false, 
  //   volume: "20ml",
  //   carrierOil: "GSO",
  //   description: [
  //     "Vials filled slightly above selected volume.",
  //     "*For intramuscular use only.",
  //   ],
  //   price: 70
  // },
  // Test C High
  {
    img: vileOils,
    name: "Test C High",
    productBackendId: 10023111,
    productFrontId: 10023,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 70,
  },
  {
    img: vileOils,
    name: "Test C High",
    productBackendId: 10023112,
    productFrontId: 10023,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 12,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 70,
  },
  // Test P 
  {
    img: vileOils,
    name: "Test P",
    productBackendId: 10024111,
    productFrontId: 10024,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 15,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 30,
  },
  {
    img: vileOils,
    name: "Test P",
    productBackendId: 10024112,
    productFrontId: 10024,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 15,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 30,
  },
  {
    img: vileOils,
    name: "Test P",
    productBackendId: 10024121,
    productFrontId: 10024,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 15,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 45,
  },
  {
    img: vileOils,
    name: "Test P",
    productBackendId: 10024122,
    productFrontId: 10024,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 15,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 45,
  },
  // Test P High
  {
    img: vileOils,
    name: "Test P High",
    productBackendId: 10025111,
    productFrontId: 10025,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 15,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Test P High",
    productBackendId: 10025112,
    productFrontId: 10025,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 15,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 50,
  },
  // Test (No Ester)
  {
    img: vileOils,
    name: "Test (No Ester)",
    productBackendId: 10026111,
    productFrontId: 10026,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 14,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Having no ester attached means that the test is adsorbed by your muscle, released into your system, and gone within a few hours.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 40,
  },
  {
    img: vileOils,
    name: "Test (No Ester)",
    productBackendId: 10026112,
    productFrontId: 10026,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 14,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Having no ester attached means that the test is adsorbed by your muscle, released into your system, and gone within a few hours.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 40,
  },
  // 10027 TAKEN
  // Test U
  {
    img: vileOils,
    name: "Test U",
    productBackendId: 10028111,
    productFrontId: 10028,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 14,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Testosterone undecanoate is a longer-acting ester that maintains serum testosterone levels within the normal range without major fluctuations and its longer half-life allows for administration every 3 months after an initial loading dose in a 6-week interval.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Test U",
    productBackendId: 10028112,
    productFrontId: 10028,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 14,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Testosterone undecanoate is a longer-acting ester that maintains serum testosterone levels within the normal range without major fluctuations and its longer half-life allows for administration every 3 months after an initial loading dose in a 6-week interval.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 50,
  },
  // Sustanon
  {
    img: vileOils,
    name: "Sustanon",
    productBackendId: 10029111,
    productFrontId: 10029,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 11,
    concentration: '250mg', 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Sustanon",
    productBackendId: 10029112,
    productFrontId: 10029,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 1,
    sProfile: 11,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 50,
  },
  // Tren A
  {
    img: vileOils,
    name: "Tren A",
    productBackendId: 10030111,
    productFrontId: 10030,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 16,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Tren A",
    productBackendId: 10030112,
    productFrontId: 10030,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 16,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Tren A",
    productBackendId: 10030121,
    productFrontId: 10030,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 16,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 85,
  },
  {
    img: vileOils,
    name: "Tren A",
    productBackendId: 10030122,
    productFrontId: 10030,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 16,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 85,
  },
  // Tren A High
  {
    img: vileOils,
    name: "Tren A High",
    productBackendId: 10031111,
    productFrontId: 10031,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 16,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 85,
  },
  {
    img: vileOils,
    name: "Tren A High",
    productBackendId: 10031112,
    productFrontId: 10031,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 16,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 85,
  },
  // Tren E
  {
    img: vileOils,
    name: "Tren E",
    productBackendId: 10032111,
    productFrontId: 10032,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 17,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Tren E",
    productBackendId: 10032112,
    productFrontId: 10032,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 17,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Tren E",
    productBackendId: 10032121,
    productFrontId: 10032,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 17,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 105,
  },
  {
    img: vileOils,
    name: "Tren E",
    productBackendId: 10032122,
    productFrontId: 10032,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 17,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 105,
  },
  // Tren E High
  {
    img: vileOils,
    name: "Tren E",
    productBackendId: 10033111,
    productFrontId: 10033,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 17,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 100,
  },
  {
    img: vileOils,
    name: "Tren E",
    productBackendId: 10033112,
    productFrontId: 10033,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 17,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only."
    ],
    price: 100,
  },
  // Tren Base
  {
    img: vileOils,
    name: "Tren Base",
    productBackendId: 10034111,
    productFrontId: 10034,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 0,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Trenbolone Base is one of the most popular products for users who wish to either improve their physique or strengthen their body both appearance-wise and internally.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 65,
  },
  {
    img: vileOils,
    name: "Tren Base",
    productBackendId: 10034112,
    productFrontId: 10034,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 0,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Trenbolone Base is one of the most popular products for users who wish to either improve their physique or strengthen their body both appearance-wise and internally.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 65,
  },
  // Mast E
  {
    img: vileOils,
    name: "Mast E",
    productBackendId: 10035111,
    productFrontId: 10035,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Mast E",
    productBackendId: 10035112,
    productFrontId: 10035,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Mast E",
    productBackendId: 10035121,
    productFrontId: 10035,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 105,
  },
  {
    img: vileOils,
    name: "Mast E",
    productBackendId: 10035122,
    productFrontId: 10035,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 105,
  },
  // Mast E High
  {
    img: vileOils,
    name: "Mast E High",
    productBackendId: 10027111,
    productFrontId: 10027,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 6,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 110,
  },
  {
    img: vileOils,
    name: "Mast E High",
    productBackendId: 10027112,
    productFrontId: 10027,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 6,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 110,
  },
  // Mast P
  {
    img: vileOils,
    name: "Mast P",
    productBackendId: 10036111,
    productFrontId: 10036,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 55,
  },
  {
    img: vileOils,
    name: "Mast P",
    productBackendId: 10036112,
    productFrontId: 10036,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 55,
  },
  {
    img: vileOils,
    name: "Mast P",
    productBackendId: 10036121,
    productFrontId: 10036,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 90,
  },
  {
    img: vileOils,
    name: "Mast P",
    productBackendId: 10036122,
    productFrontId: 10036,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 90,
  },
  // Mast P High
  {
    img: vileOils,
    name: "Mast P High",
    productBackendId: 10037111,
    productFrontId: 10037,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 80,
  },
  {
    img: vileOils,
    name: "Mast P High",
    productBackendId: 10037112,
    productFrontId: 10037,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 3,
    sProfile: 7,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 80,
  },
  // Deca
  {
    img: vileOils,
    name: "Deca",
    productBackendId: 10038111,
    productFrontId: 10038,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 4,
    sProfile: 3,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Deca",
    productBackendId: 10038112,
    productFrontId: 10038,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 4,
    sProfile: 3,
    concentration: '250mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
    ],
    price: 50,
  },
  // Deca Low
  {
    img: vileOils,
    name: "Deca Low",
    productBackendId: 10039111,
    productFrontId: 10039,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 4,
    sProfile: 3,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 45,
  },
  {
    img: vileOils,
    name: "Deca Low",
    productBackendId: 10039112,
    productFrontId: 10039,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 4,
    sProfile: 3,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 45,
  },
  // Deca High
  {
    img: vileOils,
    name: "Deca High",
    productBackendId: 10040111,
    productFrontId: 10040,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 4,
    sProfile: 3,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 70,
  },
  {
    img: vileOils,
    name: "Deca High",
    productBackendId: 10040112,
    productFrontId: 10040,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 4,
    sProfile: 3,
    concentration: '400mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 70,
  },
  // NPP
  {
    img: vileOils,
    name: "NPP",
    productBackendId: 10041111,
    productFrontId: 10041,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 5,
    sProfile: 0,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 45,
  },
  {
    img: vileOils,
    name: "NPP",
    productBackendId: 10041112,
    productFrontId: 10041,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 5,
    sProfile: 0,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 45,
  },
  // NPP High
  {
    img: vileOils,
    name: "NPP High",
    productBackendId: 10042111,
    productFrontId: 10042,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 5,
    sProfile: 0,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 75,
  },
  {
    img: vileOils,
    name: "NPP High",
    productBackendId: 10042112,
    productFrontId: 10042,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 5,
    sProfile: 0,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 75,
  },
  // EQ
  {
    img: vileOils,
    name: "EQ",
    productBackendId: 10043111,
    productFrontId: 10043,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 6,
    sProfile: 5,
    concentration: '300mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "EQ",
    productBackendId: 10043112,
    productFrontId: 10043,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 6,
    sProfile: 5,
    concentration: '300mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 50,
  },
  // EQ High
  {
    img: vileOils,
    name: "EQ High",
    productBackendId: 10044111,
    productFrontId: 10044,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 6,
    sProfile: 5,
    concentration: '500mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 70,
  },
  {
    img: vileOils,
    name: "EQ High",
    productBackendId: 10044112,
    productFrontId: 10044,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 6,
    sProfile: 5,
    concentration: '500mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 70,
  },
  // Primo E
  {
    img: vileOils,
    name: "Primo E",
    productBackendId: 10045111,
    productFrontId: 10045,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 7,
    sProfile: 9,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Primo E",
    productBackendId: 10045112,
    productFrontId: 10045,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 7,
    sProfile: 9,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 60,
  },
  // Primo E High
  {
    img: vileOils,
    name: "Primo E High",
    productBackendId: 10046111,
    productFrontId: 10046,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 7,
    sProfile: 9,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 110,
  },
  {
    img: vileOils,
    name: "Primo E High",
    productBackendId: 10046112,
    productFrontId: 10046,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 7,
    sProfile: 9,
    concentration: '200mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 110,
  },
  // DHB
  {
    img: vileOils,
    name: "DHB",
    productBackendId: 10047111,
    productFrontId: 10047,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "DHB",
    productBackendId: 10047112,
    productFrontId: 10047,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 50,
  },
  // Injectable Anadrol
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048111,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 35,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048112,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 35,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048211,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048212,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048121,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 55,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048122,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 55,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048221,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 85,
  },
  {
    img: vileOils,
    name: "Injectable Anadrol",
    productBackendId: 10048222,
    productFrontId: 10048,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 1,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
      '**CONTAINS GUAIACOL**',
    ],
    price: 85,
  },
  // BAC Water
  {
    img: vileOils,
    name: "BAC Water",
    productBackendId: 10049111,
    productFrontId: 10049,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '',
    hasDiscount: false, 
    volume: "30ml",
    carrierOil: "",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 16,
  },
  // Sterile MCT
  {
    img: vileOils,
    name: "Sterile MCT",
    productBackendId: 10050111,
    productFrontId: 10050,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 20,
  },
  // Injectable Dbol
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051111,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051112,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051121,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 80,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051122,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 80,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051211,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051212,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051221,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 120,
  },
  {
    img: vileOils,
    name: "Injectable Dbol",
    productBackendId: 10051222,
    productFrontId: 10051,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 4,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
      "**CONTAINS GUAIACOL**",
    ],
    price: 120,
  },
  // Injectable Superdrol
  {
    img: vileOils,
    name: "Injectable Superdrol",
    productBackendId: 10052111,
    productFrontId: 10052,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Injectable Superdrol",
    productBackendId: 10052112,
    productFrontId: 10052,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 50,
  },
  {
    img: vileOils,
    name: "Injectable Superdrol",
    productBackendId: 10052121,
    productFrontId: 10052,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 80,
  },
  {
    img: vileOils,
    name: "Injectable Superdrol",
    productBackendId: 10052122,
    productFrontId: 10052,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      'Vials filled slightly above selected volume.',
      '*For intramuscular use only.',
    ],
    price: 80,
  },
  // Injectable Winstrol
  {
    img: vileOils,
    name: "Injectable Winstrol",
    productBackendId: 10053111,
    productFrontId: 10053,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 19,
    concentration: '30mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "**CONTAINS GUAIACOL**",
    ],
    price: 40,
  },
  {
    img: vileOils,
    name: "Injectable Winstrol",
    productBackendId: 10053112,
    productFrontId: 10053,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 19,
    concentration: '30mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "**CONTAINS GUAIACOL**",
    ],
    price: 40,
  },
  {
    img: vileOils,
    name: "Injectable Winstrol",
    productBackendId: 10053121,
    productFrontId: 10053,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 19,
    concentration: '30mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "MCT",
    description: [
      "**CONTAINS GUAIACOL**",
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Injectable Winstrol",
    productBackendId: 10053122,
    productFrontId: 10053,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 19,
    concentration: '30mg',
    hasDiscount: false, 
    volume: "20ml",
    carrierOil: "GSO",
    description: [
      "**CONTAINS GUAIACOL**",
    ],
    price: 60,
  },
  // Ment
  {
    img: vileOils,
    name: "Ment",
    productBackendId: 10054111,
    productFrontId: 10054,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 18,
    concentration: '30mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "Ment",
    productBackendId: 10054112,
    productFrontId: 10054,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 18,
    concentration: '30mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 60,
  },
  // Ment High
  {
    img: vileOils,
    name: "Ment High",
    productBackendId: 10089111,
    productFrontId: 10089,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 130,
  },
  {
    img: vileOils,
    name: "Ment High",
    productBackendId: 10089112,
    productFrontId: 10089,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 130,
  },
//   Anadrol
  {
    img: pillBottleOrals,
    name: "Anadrol",
    productBackendId: 10055111,
    productFrontId: 10055,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 1,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 35
  },
  {
    img: pillBottleOrals,
    name: "Anadrol",
    productBackendId: 10055211,
    productFrontId: 10055,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 1,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 50
  },
// Anavar
  {
    img: pillBottleOrals,
    name: "Anavar",
    productBackendId: 10056111,
    productFrontId: 10056,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 2,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 30
  },
  {
    img: pillBottleOrals,
    name: "Anavar",
    productBackendId: 10056211,
    productFrontId: 10056,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 2,
    concentration: '20mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 40
  },
  {
    img: pillBottleOrals,
    name: "Anavar",
    productBackendId: 10056311,
    productFrontId: 10056,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 2,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 70
  },
//   Dianabol
  {
    img: pillBottleOrals,
    name: "Dianabol (Dbol)",
    productBackendId: 10057111,
    productFrontId: 10057,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 4,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 50
  },
//   Halotestin
{
    img: pillBottleOrals,
    name: "Halotestin",
    productBackendId: 10058111,
    productFrontId: 10058,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 6,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 95
  },
//   Proviron
{
    img: pillBottleOrals,
    name: "Proviron",
    productBackendId: 10059111,
    productFrontId: 10059,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 10,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 50
  },
// Superdrol
{
    img: pillBottleOrals,
    name: "Superdrol",
    productBackendId: 10060111,
    productFrontId: 10060,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 0,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Superdrol is known for its ability to promote rapid gains in muscle mass and strength, with users often reporting impressive results in a short period.",
    ],
    price: 50
  },
//   Turninabol
{
    img: pillBottleOrals,
    name: "Turninabol",
    productBackendId: 10061111,
    productFrontId: 10061,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 8,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      
    ],
    price: 45
  },
//   Winstrol
  {
    img: pillBottleOrals,
    name: "Winstrol",
    productBackendId: 10062111,
    productFrontId: 10062,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 10,
    sProfile: 19,
    concentration: '25mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "**CONTAINS GUAIACOL**",
    ],
    price: 40
  },
// Accutane
{
    img: pillBottleOrals,
    name: "Accutane",
    productBackendId: 10063111,
    productFrontId: 10063,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 0,
    concentration: '20mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      
    ],
    price: 35
  },
  {
    img: pillBottleOrals,
    name: "Accutane",
    productBackendId: 10063211,
    productFrontId: 10063,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 0,
    concentration: '40mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
     
    ],
    price: 55
  },
//   Arimidex
{
    img: pillBottleOrals,
    name: "Arimidex",
    productBackendId: 10064111,
    productFrontId: 10064,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 20,
    concentration: '1mg',
    hasDiscount: false, 
    volume: "60ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 80
  },
//  Aromasin
  {
    img: pillBottleOrals,
    name: "Aromasin",
    productBackendId: 10065111,
    productFrontId: 10065,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 21,
    concentration: '12.5mg',
    hasDiscount: false, 
    volume: "60ct",
    carrierOil: [],
    description: [
      
    ],
    price: 70
  },
// Caber
  {
    img: pillBottleOrals,
    name: "Caber",
    productBackendId: 10066111,
    productFrontId: 10066,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 25,
    concentration: '.5mg',
    hasDiscount: false, 
    volume: "20ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 130
  },
  {
    img: pillBottleOrals,
    name: "Caber",
    productBackendId: 10066211,
    productFrontId: 10066,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 25,
    concentration: '1mg',
    hasDiscount: false, 
    volume: "20ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 210
  },
//   Clenbuterol
{
    img: pillBottleOrals,
    name: "Clenbuterol",
    productBackendId: 10067111,
    productFrontId: 10067,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 23,
    concentration: '40mcg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      
    ],
    price: 60
  },
//   Clomid
  {
    img: pillBottleOrals,
    name: "Clomid",
    productBackendId: 10068111,
    productFrontId: 10068,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 24,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "60ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 60
  },
//   Finasteride
  {
    img: pillBottleOrals,
    name: "Finasteride",
    productBackendId: 10069111,
    productFrontId: 10069,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 31,
    concentration: '1mg',
    hasDiscount: false, 
    volume: "60ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 60
  },
// Letozole
  {
    img: pillBottleOrals,
    name: "Letozole",
    productBackendId: 10070111,
    productFrontId: 10070,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 0,
    concentration: '2.5mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      
    ],
    price: 50
  },
// Nolvadex
  {
    img: pillBottleOrals,
    name: "Nolvadex",
    productBackendId: 10071111,
    productFrontId: 10071,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 30,
    concentration: '20mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 45
  },
//   Raloxifene
{
    img: pillBottleOrals,
    name: "Raloxifene",
    productBackendId: 10072111,
    productFrontId: 10072,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 11,
    sProfile: 26,
    concentration: '50mg',
    hasDiscount: false, 
    volume: "60ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 65
  },
// Cialis
{
    img: pillBottleOrals,
    name: "Cialis",
    productBackendId: 10073111,
    productFrontId: 10073,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 12,
    sProfile: 22,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 40
  },
//  Viagra 
  {
    img: pillBottleOrals,
    name: "Viagra",
    productBackendId: 10074111,
    productFrontId: 10074,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 12,
    sProfile: 33,
    concentration: '100mg',
    hasDiscount: false, 
    volume: "30ct",
    carrierOil: [],
    description: [
      "Size 2 clear capsules with baking soda filler.",
    ],
    price: 60
  },
//   BPC-157
{
    img: vileOils,
    name: "BPC-157",
    productBackendId: 10075111,
    productFrontId: 10075,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 0,
    concentration: '5mg',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      "Repair Damaged Joints and Boost Recovery! BPC-157 is a peptide that helps alleviate joint pain, improve joint mobility, and boost recovery from injuries.",
      "Must be reconstituted with BAC water before use."
    ],
    price: 40
  },
//   HCG
{
    img: vileOils,
    name: "HCG",
    productBackendId: 10076111,
    productFrontId: 10076,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 27,
    concentration: '5000iu',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      "Must be reconstituted with BAC water before use.",
    ],
    price: 30
  },
//   HGH Generic Black Tops (kit)
  {
    img: vileOils,
    name: "HGH Generic Black Tops (Kit)",
    productBackendId: 10077111,
    productFrontId: 10077,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 13,
    sProfile: 28,
    concentration: '120iu',
    hasDiscount: false, 
    volume: "2ml (x10)",
    carrierOil: [],
    description: [
      "Must be reconstituted with BAC water before use.",
    ],
    price: 160
  },
// HGH Generic Black Tops (single)
  {
    img: vileOils,
    name: "HGH Generic Black Tops (Single)",
    productBackendId: 10078111,
    productFrontId: 10078,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 13,
    sProfile: 28,
    concentration: '12iu',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      "Must be reconstituted with BAC water before use.",
    ],
    price: 35
  },
//   HGH Generic Blue Tops (kit)
  {
    img: vileOils,
    name: "HGH Generic Blue Tops (Kit)",
    productBackendId: 10079111,
    productFrontId: 10079,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 13,
    sProfile: 28,
    concentration: '100iu',
    hasDiscount: false, 
    volume: "2ml (x10)",
    carrierOil: [],
    description: [
      "Must be reconstituted with BAC water before use.",
    ],
    price: 150
  },
//   HGH Generic Blue Tops (single)"
  {
    img: vileOils,
    name: "HGH Generic Blue Tops (Single)",
    productBackendId: 10080111,
    productFrontId: 10080,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 13,
    sProfile: 28,
    concentration: '10iu',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      "Must be reconstituted with BAC water before use.",
    ],
    price: 30
  },
//  IGF-LR3
  {
    img: vileOils,
    name: "IGF1-LR3",
    productBackendId: 10081111,
    productFrontId: 10081,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 29,
    concentration: '1mg',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      "Must be reconstituted with BAC water before use.",
    ],
    price: 90
  },
//  IGF-LR3(kit)
  {
    img: vileOils,
    name: "IGF1-LR3 (Kit)",
    productBackendId: 10082111,
    productFrontId: 10082,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 29,
    concentration: '10mg',
    hasDiscount: false, 
    volume: "2ml (x10)",
    carrierOil: [],
    description: [
      
    ],
    price: 510
  },
//   L-Carnitine
  {
    img: vileOils,
    name: "L-Carnitine",
    productBackendId: 10083111,
    productFrontId: 10083,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '600mg',
    hasDiscount: false, 
    volume: "30ml",
    carrierOil: [],
    description: [
      "L-carnitine is an amino acid produced by the body that’s also found in food and supplements. Some studies suggest it may offer health benefits including increased weight loss, improved brain function, and more.",
      "*Shipped with ice pack."
    ],
    price: 65
  },
//   Semaglutide
  {
    img: vileOils,
    name: "Semaglutide",
    productBackendId: 10084111,
    productFrontId: 10084,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 0,
    concentration: '4mg',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      
    ],
    price: 85
  },
//  TB-500
  {
    img: vileOils,
    name: "TB-500",
    productBackendId: 10085111,
    productFrontId: 10085,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 32,
    concentration: '5mg',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [],
    price: 45
  },
//  Tirzepatideo
  {
    img: vileOils,
    name: "Tirzepatide",
    productBackendId: 10086111,
    productFrontId: 10086,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 15,
    sProfile: 0,
    concentration: '5mg',
    hasDiscount: false, 
    volume: "2ml",
    carrierOil: [],
    description: [
      
    ],
    price: 65
  },
  // Sterile GSO Oil
  {
    img: vileOils,
    name: "Sterile GSO Oil",
    productBackendId: 10087111,
    productFrontId: 10087,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 8,
    sProfile: 0,
    concentration: '',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "",
    description: [
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 20,
  },
  // M-Tren
  {
    img: vileOils,
    name: "M-Tren",
    productBackendId: 10088111,
    productFrontId: 10088,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 0,
    concentration: '2mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "MCT",
    description: [
      "Methyl Trenbolone is structurally similar to powerful Trenbolone. This compound enhances muscle-mind coordination helping users to activate most of the muscle fibers in muscles causing massive surge in lifting power. It is only recommended for advanced and pro users.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 60,
  },
  {
    img: vileOils,
    name: "M-Tren",
    productBackendId: 10088112,
    productFrontId: 10088,
    discountedPrice: 
    siteWideDiscount.siteWide && siteWideDiscount.isPercent ? (1 - siteWideDiscount.percentOff)
    : siteWideDiscount.siteWide && !siteWideDiscount.isPercent ? siteWideDiscount.dollarOff
    : null,
    subCategory: 2,
    sProfile: 0,
    concentration: '2mg',
    hasDiscount: false, 
    volume: "10ml",
    carrierOil: "GSO",
    description: [
      "Methyl Trenbolone is structurally similar to powerful Trenbolone. This compound enhances muscle-mind coordination helping users to activate most of the muscle fibers in muscles causing massive surge in lifting power. It is only recommended for advanced and pro users.",
      "Vials filled slightly above selected volume.",
      "*For intramuscular use only.",
    ],
    price: 60,
  },
  // 10089 TAKEN
]

export default productsBackend;