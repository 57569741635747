const pushCartToServer = async (cart, paymentMethodKeys, totalCost, costInCrypto, formData, couponCode, orderReferral) => {
  const response = await fetch('/api/push-cart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ cart, paymentMethodKeys, totalCost, costInCrypto, formData, couponCode, orderReferral }),
  });

  if (!response.ok) {
    throw new Error('Failed to push cart data to server');
  }

  const data = await response.json();
  console.log(data)
  localStorage.setItem('jhlltd-order-num', data.orderNumber); // Store the order number in local storage
  if (data.pmtMethod === 'Ethereum') {
    localStorage.setItem('jhlltd-order-num-pmt', 'eth'); // Store the order number in local storage
  } else if (data.pmtMethod === 'Bitcoin') {
    localStorage.setItem('jhlltd-order-num-pmt', 'btc'); // Store the order number in local storage
  }
  return data;
};

export default pushCartToServer;
