// Comps/CryptoPrice/btcPriceContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getBitcoinPrice } from './btcPrice';

export const BitcoinPriceContext = createContext();

export const BitcoinPriceProvider = ({ children }) => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const btcPrice = await getBitcoinPrice();
      setPrice(btcPrice);
    };

    fetchPrice();
  }, []);

  return (
    <BitcoinPriceContext.Provider value={price}>
      {children}
    </BitcoinPriceContext.Provider>
  );
};
