const allCountries = [
  { countryName: "Afghanistan", countryCode: "AF", countryNum: "004"},
  { countryName: "Albania", countryCode: "AL", countryNum: "008"},
  { countryName: "Algeria", countryCode: "DZ", countryNum: "012"},
  { countryName: "Andorra", countryCode: "AD", countryNum: "020"},
  { countryName: "Angola", countryCode: "AO", countryNum: "024"},
  { countryName: "Antigua and Barbuda", countryCode: "AG", countryNum: "028"},
  { countryName: "Argentina", countryCode: "AR", countryNum: "032"},
  { countryName: "Armenia", countryCode: "AM", countryNum: "051"},
  { countryName: "Australia", countryCode: "AU", countryNum: "036"},
  { countryName: "Austria", countryCode: "AT", countryNum: "040"},
  { countryName: "Azerbaijan", countryCode: "AZ", countryNum: "031"},
  { countryName: "Bahamas", countryCode: "BS", countryNum: "044"},
  { countryName: "Bahrain", countryCode: "BH", countryNum: "048"},
  { countryName: "Bangladesh", countryCode: "BD", countryNum: "050"},
  { countryName: "Barbados", countryCode: "BB", countryNum: "052"},
  { countryName: "Belarus", countryCode: "BY", countryNum: "112"},
  { countryName: "Belgium", countryCode: "BE", countryNum: "056"},
  { countryName: "Belize", countryCode: "BZ", countryNum: "084"},
  { countryName: "Benin", countryCode: "BJ", countryNum: "204"},
  { countryName: "Bhutan", countryCode: "BT", countryNum: "064"},
  { countryName: "Bolivia", countryCode: "BO", countryNum: "068"},
  { countryName: "Bosnia and Herzegovina", countryCode: "BA", countryNum: "070"},
  { countryName: "Botswana", countryCode: "BW", countryNum: "072"},
  { countryName: "Brazil", countryCode: "BR", countryNum: "076"},
  { countryName: "Brunei", countryCode: "BN", countryNum: "096"},
  { countryName: "Bulgaria", countryCode: "BG", countryNum: "100"},
  { countryName: "Burkina Faso", countryCode: "BF", countryNum: "854"},
  { countryName: "Burundi", countryCode: "BI", countryNum: "108"},
  { countryName: "Cabo Verde", countryCode: "CV", countryNum: "132"},
  { countryName: "Cambodia", countryCode: "KH", countryNum: "116"},
  { countryName: "Cameroon", countryCode: "CM", countryNum: "120"},
  { countryName: "Canada", countryCode: "CA", countryNum: "124"},
  { countryName: "Central African Republic", countryCode: "CF", countryNum: "140"},
  { countryName: "Chad", countryCode: "TD", countryNum: "148"},
  { countryName: "Chile", countryCode: "CL", countryNum: "152"},
  { countryName: "China", countryCode: "CN", countryNum: "156"},
  { countryName: "Colombia", countryCode: "CO", countryNum: "170"},
  { countryName: "Comoros", countryCode: "KM", countryNum: "174"},
  { countryName: "Congo (Congo-Brazzaville)", countryCode: "CG", countryNum: "178"},
  { countryName: "Costa Rica", countryCode: "CR", countryNum: "188"},
  { countryName: "Croatia", countryCode: "HR", countryNum: "191"},
  { countryName: "Cuba", countryCode: "CU", countryNum: "192"},
  { countryName: "Cyprus", countryCode: "CY", countryNum: "196"},
  { countryName: "Czechia (Czech Republic)", countryCode: "CZ", countryNum: "203"},
  { countryName: "Denmark", countryCode: "DK", countryNum: "208"},
  { countryName: "Djibouti", countryCode: "DJ", countryNum: "262"},
  { countryName: "Dominica", countryCode: "DM", countryNum: "212"},
  { countryName: "Dominican Republic", countryCode: "DO", countryNum: "214"},
  { countryName: "Ecuador", countryCode: "EC", countryNum: "218"},
  { countryName: "Egypt", countryCode: "EG", countryNum: "818"},
  { countryName: "El Salvador", countryCode: "SV", countryNum: "222"},
  { countryName: "Equatorial Guinea", countryCode: "GQ", countryNum: "226"},
  { countryName: "Eritrea", countryCode: "ER", countryNum: "232"},
  { countryName: "Estonia", countryCode: "EE", countryNum: "233"},
  { countryName: "Eswatini (fmr. 'Swaziland')", countryCode: "SZ", countryNum: "748"},
  { countryName: "Ethiopia", countryCode: "ET", countryNum: "231"},
  { countryName: "Fiji", countryCode: "FJ", countryNum: "242"},
  { countryName: "Finland", countryCode: "FI", countryNum: "246"},
  { countryName: "France", countryCode: "FR", countryNum: "250"},
  { countryName: "Gabon", countryCode: "GA", countryNum: "266"},
  { countryName: "Gambia", countryCode: "GM", countryNum: "270"},
  { countryName: "Georgia", countryCode: "GE", countryNum: "268"},
  { countryName: "Germany", countryCode: "DE", countryNum: "276"},
  { countryName: "Ghana", countryCode: "GH", countryNum: "288"},
  { countryName: "Greece", countryCode: "GR", countryNum: "300"},
  { countryName: "Grenada", countryCode: "GD", countryNum: "308"},
  { countryName: "Guatemala", countryCode: "GT", countryNum: "320"},
  { countryName: "Guinea", countryCode: "GN", countryNum: "324"},
  { countryName: "Guinea-Bissau", countryCode: "GW", countryNum: "624"},
  { countryName: "Guyana", countryCode: "GY", countryNum: "328"},
  { countryName: "Haiti", countryCode: "HT", countryNum: "332"},
  { countryName: "Honduras", countryCode: "HN", countryNum: "340"},
  { countryName: "Hungary", countryCode: "HU", countryNum: "348"},
  { countryName: "Iceland", countryCode: "IS", countryNum: "352"},
  { countryName: "India", countryCode: "IN", countryNum: "356"},
  { countryName: "Indonesia", countryCode: "ID", countryNum: "360"},
  { countryName: "Iran", countryCode: "IR", countryNum: "364"},
  { countryName: "Iraq", countryCode: "IQ", countryNum: "368"},
  { countryName: "Ireland", countryCode: "IE", countryNum: "372"},
  { countryName: "Israel", countryCode: "IL", countryNum: "376"},
  { countryName: "Italy", countryCode: "IT", countryNum: "380"},
  { countryName: "Jamaica", countryCode: "JM", countryNum: "388"},
  { countryName: "Japan", countryCode: "JP", countryNum: "392"},
  { countryName: "Jordan", countryCode: "JO", countryNum: "400"},
  { countryName: "Kazakhstan", countryCode: "KZ", countryNum: "398"},
  { countryName: "Kenya", countryCode: "KE", countryNum: "404"},
  { countryName: "Kiribati", countryCode: "KI", countryNum: "296"},
  { countryName: "Kuwait", countryCode: "KW", countryNum: "414"},
  { countryName: "Kyrgyzstan", countryCode: "KG", countryNum: "417"},
  { countryName: "Laos", countryCode: "LA", countryNum: "418"},
  { countryName: "Latvia", countryCode: "LV", countryNum: "428"},
  { countryName: "Lebanon", countryCode: "LB", countryNum: "422"},
  { countryName: "Lesotho", countryCode: "LS", countryNum: "426"},
  { countryName: "Liberia", countryCode: "LR", countryNum: "430"},
  { countryName: "Libya", countryCode: "LY", countryNum: "434"},
  { countryName: "Liechtenstein", countryCode: "LI", countryNum: "438"},
  { countryName: "Lithuania", countryCode: "LT", countryNum: "440"},
  { countryName: "Luxembourg", countryCode: "LU", countryNum: "442"},
  { countryName: "Madagascar", countryCode: "MG", countryNum: "450"},
  { countryName: "Malawi", countryCode: "MW", countryNum: "454"},
  { countryName: "Malaysia", countryCode: "MY", countryNum: "458"},
  { countryName: "Maldives", countryCode: "MV", countryNum: "462"},
  { countryName: "Mali", countryCode: "ML", countryNum: "466"},
  { countryName: "Malta", countryCode: "MT", countryNum: "470"},
  { countryName: "Marshall Islands", countryCode: "MH", countryNum: "584"},
  { countryName: "Mauritania", countryCode: "MR", countryNum: "478"},
  { countryName: "Mauritius", countryCode: "MU", countryNum: "480"},
  { countryName: "Mexico", countryCode: "MX", countryNum: "484"},
  { countryName: "Micronesia", countryCode: "FM", countryNum: "583"},
  { countryName: "Moldova", countryCode: "MD", countryNum: "498"},
  { countryName: "Monaco", countryCode: "MC", countryNum: "492"},
  { countryName: "Mongolia", countryCode: "MN", countryNum: "496"},
  { countryName: "Montenegro", countryCode: "ME", countryNum: "499"},
  { countryName: "Morocco", countryCode: "MA", countryNum: "504"},
  { countryName: "Mozambique", countryCode: "MZ", countryNum: "508"},
  { countryName: "Myanmar (formerly Burma)", countryCode: "MM", countryNum: "104"},
  { countryName: "Namibia", countryCode: "NA", countryNum: "516"},
  { countryName: "Nauru", countryCode: "NR", countryNum: "520"},
  { countryName: "Nepal", countryCode: "NP", countryNum: "524"},
  { countryName: "Netherlands", countryCode: "NL", countryNum: "528"},
  { countryName: "New Zealand", countryCode: "NZ", countryNum: "554"},
  { countryName: "Nicaragua", countryCode: "NI", countryNum: "558"},
  { countryName: "Niger", countryCode: "NE", countryNum: "562"},
  { countryName: "Nigeria", countryCode: "NG", countryNum: "566"},
  { countryName: "North Korea", countryCode: "KP", countryNum: "408"},
  { countryName: "North Macedonia", countryCode: "MK", countryNum: "807"},
  { countryName: "Norway", countryCode: "NO", countryNum: "578"},
  { countryName: "Oman", countryCode: "OM", countryNum: "512"},
  { countryName: "Pakistan", countryCode: "PK", countryNum: "586"},
  { countryName: "Palau", countryCode: "PW", countryNum: "585"},
  { countryName: "Palestine State", countryCode: "PS", countryNum: "275"},
  { countryName: "Panama", countryCode: "PA", countryNum: "591"},
  { countryName: "Papua New Guinea", countryCode: "PG", countryNum: "598"},
  { countryName: "Paraguay", countryCode: "PY", countryNum: "600"},
  { countryName: "Peru", countryCode: "PE", countryNum: "604"},
  { countryName: "Philippines", countryCode: "PH", countryNum: "608"},
  { countryName: "Poland", countryCode: "PL", countryNum: "616"},
  { countryName: "Portugal", countryCode: "PT", countryNum: "620"},
  { countryName: "Qatar", countryCode: "QA", countryNum: "634"},
  { countryName: "Romania", countryCode: "RO", countryNum: "642"},
  { countryName: "Russia", countryCode: "RU", countryNum: "643"},
  { countryName: "Rwanda", countryCode: "RW", countryNum: "646"},
  { countryName: "Saint Kitts and Nevis", countryCode: "KN", countryNum: "659"},
  { countryName: "Saint Lucia", countryCode: "LC", countryNum: "662"},
  { countryName: "Saint Vincent and the Grenadines", countryCode: "VC", countryNum: "670"},
  { countryName: "Samoa", countryCode: "WS", countryNum: "882"},
  { countryName: "San Marino", countryCode: "SM", countryNum: "674"},
  { countryName: "Sao Tome and Principe", countryCode: "ST", countryNum: "678"},
  { countryName: "Saudi Arabia", countryCode: "SA", countryNum: "682"},
  { countryName: "Senegal", countryCode: "SN", countryNum: "686"},
  { countryName: "Serbia", countryCode: "RS", countryNum: "688"},
  { countryName: "Seychelles", countryCode: "SC", countryNum: "690"},
  { countryName: "Sierra Leone", countryCode: "SL", countryNum: "694"},
  { countryName: "Singapore", countryCode: "SG", countryNum: "702"},
  { countryName: "Slovakia", countryCode: "SK", countryNum: "703"},
  { countryName: "Slovenia", countryCode: "SI", countryNum: "705"},
  { countryName: "Solomon Islands", countryCode: "SB", countryNum: "090"},
  { countryName: "Somalia", countryCode: "SO", countryNum: "706"},
  { countryName: "South Africa", countryCode: "ZA", countryNum: "710"},
  { countryName: "South Korea", countryCode: "KR", countryNum: "410"},
  { countryName: "South Sudan", countryCode: "SS", countryNum: "728"},
  { countryName: "Spain", countryCode: "ES", countryNum: "724"},
  { countryName: "Sri Lanka", countryCode: "LK", countryNum: "144"},
  { countryName: "Sudan", countryCode: "SD", countryNum: "729"},
  { countryName: "Suriname", countryCode: "SR", countryNum: "740"},
  { countryName: "Sweden", countryCode: "SE", countryNum: "752"},
  { countryName: "Switzerland", countryCode: "CH", countryNum: "756"},
  { countryName: "Syria", countryCode: "SY", countryNum: "760"},
  { countryName: "Taiwan", countryCode: "TW", countryNum: "158"},
  { countryName: "Tajikistan", countryCode: "TJ", countryNum: "762"},
  { countryName: "Tanzania", countryCode: "TZ", countryNum: "834"},
  { countryName: "Thailand", countryCode: "TH", countryNum: "764"},
  { countryName: "Timor-Leste", countryCode: "TL", countryNum: "626"},
  { countryName: "Togo", countryCode: "TG", countryNum: "768"},
  { countryName: "Tonga", countryCode: "TO", countryNum: "776"},
  { countryName: "Trinidad and Tobago", countryCode: "TT", countryNum: "780"},
  { countryName: "Tunisia", countryCode: "TN", countryNum: "788"},
  { countryName: "Turkey", countryCode: "TR", countryNum: "792"},
  { countryName: "Turkmenistan", countryCode: "TM", countryNum: "795"},
  { countryName: "Tuvalu", countryCode: "TV", countryNum: "798"},
  { countryName: "Uganda", countryCode: "UG", countryNum: "800"},
  { countryName: "Ukraine", countryCode: "UA", countryNum: "804"},
  { countryName: "United Arab Emirates", countryCode: "AE", countryNum: "784"},
  { countryName: "United Kingdom", countryCode: "GB", countryNum: "826"},
  { countryName: "United States of America", countryCode: "US", countryNum: "840"},
  { countryName: "Uruguay", countryCode: "UY", countryNum: "858"},
  { countryName: "Uzbekistan", countryCode: "UZ", countryNum: "860"},
  { countryName: "Vanuatu", countryCode: "VU", countryNum: "548"},
  { countryName: "Vatican City", countryCode: "VA", countryNum: "336"},
  { countryName: "Venezuela", countryCode: "VE", countryNum: "862"},
  { countryName: "Vietnam", countryCode: "VN", countryNum: "704"},
  { countryName: "Yemen", countryCode: "YE", countryNum: "887"},
  { countryName: "Zambia", countryCode: "ZM", countryNum: "894"},
  { countryName: "Zimbabwe", countryCode: "ZW", countryNum: "716"}
];
  
export default allCountries;