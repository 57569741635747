import React, { useEffect, useState } from "react";
import './restorePayment.css';
import { Link } from "react-router-dom";

const RestorePayment = () => {
  const [btnTouched, changebtnTouched] = useState('');

  const btnTouch = (target) => {
    console.log(target);
    changebtnTouched(target.id);
  };

  return (
    <div className="restore-pmt-main">
      <div>
        <h1>Restore Payment</h1>
      </div>
      <div>
        <h3>Have the order number?</h3>

        <Link className="restore-pmt-btn" to='/cart/checkout/eth'>
        <button
          className={btnTouched === 'rbtn2' ? 'pulse-rbtn' : ''}
          id="rbtn2"
          onMouseEnter={(e) => btnTouch(e.target)}
          onMouseLeave={() => changebtnTouched('')}
        >
          Enter Order Number
        </button>
        </Link>

        <h3>Don't know your Order Number?</h3>
        <h3>Email Us! <Link to='mailto:Olympicpharma@proton.me'>Olympicpharma@proton.me</Link></h3>
      </div>
    </div>
  );
};

export default RestorePayment;