

const steroidProfilesData = [
  {
    spName: "Anadrol (Oxymetholone)",
    spId: 1,
    spMainCategory: 1,
    description: ["Anadrol (oxymetholone) is most likely second only to Dianabol (methandrostenolone) as a bodybuilding anabolic steroid. Additionally, it has had considerable medical importance particularly for treatment of anemia, and more recently to help maintain lean body mass in HIV-compromised patients. While many have the subjective opinion that Anadrol is a harsh drug in terms of side effects, medical findings and the findings of many athletes are quite different."],
    spCategories: [
      'Stacking Anadrol with Other Steroids',
      'Anadrol Side Effects',
      'Anadrol and Liver Toxicity',
      'Anadrol Dosages',
      'Anadrol and Women',
      'Anadrol and Virilization',
      'Anadrol Guidelines'
    ],
    spCatDescriptions: [
      ['An interesting further question concerns combination of Anadrol with other anabolic steroids. For exmaple, adding Anadrol to 50 mg/day of Dianabol gives little added benefit to a steroid cycle; in contrast, adding Anadrol to 50-100 mg/day trenbolone acetate or 60-80 mg/day Anavar (oxandrolone) gives dramatic improvement. In this its stacking behavior is similar to that of Dianabol, but not to that of trenbolone. Likely this is because unlike trenbolone, oxymetholone does not bind strongly to the androgen receptor, and most of its anabolic effect is likely not genomically mediated via the AR.'],
      ['With regard to harsh side effects sometimes perceived in bodybuilding, this principally or only occurs when estrogen levels are elevated. While oxymetholone does not itself aromatize and does not have estrogenic activity, it may be that it can interfere with estrogen metabolism. Whether that it is the cause of the problem or not, keeping estradiol levels under control keeps Anadrol similar in side effects to other oral anabolic steroids. Estradiol levels may be kept under control either by using an aromatase inhibitor such as letrozole or Arimidex, or by limiting use of aromatizing steroids such as testosterone.'],
      [
        'The principal side effect of concern is liver toxicity, as is the case for all alkylated oral anabolic steroids. This may be addressed simply by limiting duration of use to 6-8 weeks, and allowing suitable time off of the drug after each period of use. Because the liver regenerates quickly, a time of even half the period of use is often sufficient. However, allowing an equal amount of time is more conservative.',
        'Per milligram, Anadrol appears less liver toxic than any other alkylated anabolic steroid, but per amount of anabolic effect, the ratio of toxicity to anabolic effect seems similar.',
        'Other side effects of concern are the same as with other anabolic steroids in general, including potential increase in blood pressure and/or hematocrit, worsening of blood lipid profile, prostate enlargement which may be temporary, and possible promotion of acne and/or male pattern baldness, if having the gene for male pattern baldness.'
      ],
      ['Typical usage is 50-150 mg/day. In some cases larger doses such as 300 mg/day are used, but when suitably stacked with one or more other anabolic steroids, often there is little further benefit from exceeding 150 mg/day.'],
      [
        'While it’s common for bodybuilders to suspect that Anadrol would be an even riskier choice for use by women than most anabolic steroids, actually the reverse is true. Medically, once per day dosing of 50 mg/day over extended periods has shown only a moderate rate of virilization problems. The same certainly cannot be said of Anavar, Dianabol, or Winstrol (stanozolol.) While not necessarily sufficient for high level female bodybuilding competition by today’s standards, such a dose actually is considerably more than needed by most women for excellent results in adding muscle, losing fat, and generally improving their physiques. Half this dose — 25 mg/day, preferably taken as divided doses — is very effective and is so far as I know the lowest-risk way to obtain this degree of benefit from anabolic steroids.',
        'Even 12.5 mg/day in divided doses can be remarkably effective.'
      ],
      ['This is not to say, however, that at even these reduced doses there is no risk of virilization. As seen from the fact that some women with time develop facial hair and voice hoarseness simply from their natural androgen levels, it’s possible for an individual woman to be on the threshold of virilization in the first place. In these cases, any added androgen, even oral DHEA supplementation, can trigger virilizing side effects. So it must be understood that risk does exist.'],
      [
        'Briefly: Using Anadrol, or Dianabol, in combination with injectable anabolic steroids is one of the most effective ways to improve a steroid cycle. It is not necessary to use both Dianabol and Anadrol simultaneously: usually only one or the other is chosen. With proper care, side effects are generally very tolerable and pose a health risk low enough to generally be acceptable by bodybuilding standards.',
        'Oxymetholone is the name of the active ingredient in Anadrol. Anadrol is a registered trademark of Unimed Pharmaceuticals in the United States and/or other countries.'
      ]
    ],
  },
  {
    spName: 'Anavar (Oxandrolone)',
    spId: 2,
    spMainCategory: 1,
    description: [
      'Anavar (Oxandrolone) Dragon Pharma Anavar Anavar (oxandrolone), unlike most oral compounds is categorized as a Class I anabolic steroid, most efficiently stacked with Class II compounds such as Dianabol or Anadrol.',
      'It adds little if anything to high-dose use of Class I anabolic steroids such as trenbolone, or to high-dose testosterone, which is classified as having mixed activity. It can be an aid, albeit an expensive one, to moderate dose testosterone usage.',
      'Anavar has often been called a weak steroid. Part of the reason for this is that use of a Class I steroid alone never is maximally effective. The other cause is that bodybuilders and authors in the field sometimes make unfortunate and unreasonable comparisons when judging anabolic steroids. If say 8 tablets per day does little, then a drug is pronounced useless or weak. And traditionally, oxandrolone was available in 2.5 mg Anavar tablets, proving only 20 mg daily with such usage, which totals to only 140 mg/week. For comparison, testosterone at that dose also gives little results. Indeed, few anabolic steroids give dramatic results at that dose, but they are not called weak on that account. The proper conclusion is that such Anavar tablets were individually weak, but not that the drug lacks potency.',
      'As higher-dose Anavar tablets have become available, the oxandrolone’s reputation has improved. However, it still is not a particularly cost-effective Class I steroid, and if used alone cannot match the performance of a good stack.',
      'Pharmacologically, it has been found that oxandrolone binds weakly to the androgen receptor. This seems inconsistent with the Class I / Class II system, but it is what has been found. Perhaps it is the case that what occurs in the body is not the same as occurs in in vitro study, or perhaps there is another interesting phenomenon occurring',
      'From the practical standpoint, however, oxandrolone’s stacking behavior requires that it be classified as a Class I steroid: it combines synergistically with those categorized as Class II, but only additively with Class I compounds. From the practical standpoint, it is a rather potent drug – that is to say, it has good effectiveness per milligram. Stacked with a Class II steroid, Anavar is quite effective at only 75 mg/day, or even 50.',
      'Anavar does not aromatize or convert to DHT, and has an 8 hour half-life. Thus, a moderate dose taken in the morning is largely out of the system by night, yet supplies reasonable levels of androgen during the day and early evening.',
      'One study found oxandrolone to be superior to testosterone and to Deca (nandrolone) for reducing abdominal fat in men, or at least in obese older men at the specific low doses studied, which were not necessarily equipotent. From this, some have made broad generalizations to bodybuilding. However, this does not necessarily carry over to anabolic steroid cycles at doses commonly used in bodybuilding. In the case of the study in question, I expect the difference in outcomes was dose-related.',
      'In practice, at total androgen doses typically used, one can cut just as effectively without oxandrolone as with, given any of various possible substitutions for the oxandrolone. This is not to say this drug is ineffective, but rather that other androgens including testosterone are also effective at high dose for abdominal fat loss.',
      'In the case of low-dose use however, I do think it is a correct conclusion that for most, low dose Anavar use is more effective for cutting than equal dosages of most other anabolic steroids. This may be partly or entirely from additive effect with natural testosterone: such oxandrolone use may not suppress such its production, the user may enjoy both the full effect of his natural testosterone and the effect of the oxandrolone. In contrast, low-dose testosterone or nandrolone use results in substantial suppression of natural testosterone, and so there is less total effect.',
      'Oxandrolone, as with other 17-alkylated steroids, is hepatotoxic. At one time it was thought that it is not, but both clinical and practical experience with Oxandrin has shown that liver toxicity can indeed be an issue with prolonged use. I believe the usual principle of limiting 17-alkylated use to 6 weeks at a time should be applied when oxandrolone is used, just as with any alkylated oral.',
      'Trenbolone or Primobolan are suitable substitutes for Anavar, without the liver toxicity issues. As a substitute, Primobolan shares the property of being low-suppressive, while trenbolone does not.',
      'An interesting application of the drug that takes advantage of its oral administration is use as a morning-only bridging agent between cycles, which in my opinion should be done – if done – only after fully recovering normal testosterone production from the last cycle. At least 20 mg is usually acceptable in this application. Ideally, testosterone levels will be measured to monitor such bridging. A factor limiting to such bridging is the liver toxicity issue.',
      'With regard to use by women, while there is a common belief that Anavar is minimally virilizing to female, in fact virilization is not unusual at 20 mg/day and can occur at considerably lower doses than that. Even 5 mg/day is not side-effect-free for all.',
      'During a cycle, oxandrolone is not particularly recommended because there are more cost-efficient choices that will fully accomplish the same goals and do not add to liver toxicity.',
      'The two best uses for Anavar are in optional bridging periods between cycles, if such are employed, while keeping care to avoid excessive duration of continuous 17-alkylated use; and, if short-acting injectables are not available, to supplement cycles as levels fall between the time of last injection and the start of post-cycle therapy so that that time period can remain effective for gains.',
      'Oxandrolone is the chemical name of active ingredient in Oxandrin and Anavar. Anavar was originally the registered trademark of Searle Laboratories. Oxandrin is a registered trademark of Bio-Technology General Corp. in the United States and/or other countries.'
    ],
    spCategories: [
      
    ],
    spCatDescriptions: [
      
    ],
  },
  {
    spName: 'Deca Durabolin (Nandrolone Decanoate)',
    spId: 3,
    spMainCategory: 1,
    description: [
      'Deca, also known as Deca Durabolin or nandrolone decanoate, is perhaps the second-best known injectable anabolic steroid after testosterone. It seems that almost every steroid novice and many who are experienced with steroid cycles believe that for best results they may need to include Deca. However, in fact this compound has no special anabolic properties: other injectable steroids can do everything for muscle growth that Deca can.',
    ],
    spCategories: [
      'Popularity of Deca Durabolin',
      'Deca Durabolin Effects on Hair and Skin',
      'Deca Durabolin and Joints',
      'Deca Durabolin and Recovery of Testosterone Production',
      'Deca Durabolin Recommendations'
    ],
    spCatDescriptions: [
      [
        'The unusual properties of Deca Durabolin are due to its parent steroid, nandrolone, also known as 19-nortestosterone. One of the principal positive qualities of nandrolone is that it has relatively low conversion to estrogen compared to testosterone.',
        'In earlier years, testosterone and nandrolone were the only pharmaceutical injectables that were both widely available to bodybuilders and economical. Estrogen control did not then exist. For this reason, Deca’s low aromatizing properties were then quite useful. Deca therefore won wide popularity.'
      ],
      ['An additional advantage of Deca Durabolin (nandrolone) is that this compound is relatively mild in terms of adverse side effects on the scalp and skin. This is because the 5-alpha reductase enzyme acts to reduce the potency of nandrolone, while in contrast many other anabolic steroids are unaffected by this enzyme, and testosterone’s potency is actually increased by it.'],
      ['Further, where athletes have joint problems, in many instances use of Deca in a steroid cycle has the positive side effect of providing some aid. Even doses as low as 100 mg/week can entirely suffice for this purpose in many instances.'],
      ['Unfortunately, Deca Durabolin usage very commonly has the adverse side effect of interfering with recovery of natural testosterone production after a steroid cycle. Adverse side effect on libido and/or erectile function is also common, but typically only when dosage is at least 200-400 mg/week or when the drug is used alone at any effective dose. Problems are worse when Deca is not combined with testosterone or substantial doses of more androgenic compounds such as trenbolone, Masteron, or Dianabol. There is wide individual variability in response with regard to side effects on libido and erectile function. Depression of mood is also possible.'],
      [
        'For these reasons I generally recommend against Deca Durabolin use except where the athlete already knows that he can experience joint relief from the compound, or he wishes to see if that will be the case for him and he understands the possible adverse side effects. I prefer for Deca use to be as low as is effective for any such joint problems, and for the majority of the total steroid dosage to be from other compounds.',
        'The long half-life of the drug results in slow clearance. For this reason, Deca Durabolin is unsuited to short cycles or where drug testing is a possibility. I also consider the drug a poor choice for women due to being at least as virilizing per milligram as most other anabolic steroids except testosterone and taking too long to clear if side-effects occur.',
        '“Deca Durabolin” is a registered trademark belonging to Organon. Injectable steroid products are properly called this only when produced by Organon, but the name is often used for underground preparations as well. The Organon preparation is provided at 200 mg/mL.',
      ],
    ],
  },
  {
    spName: 'Dianabol (Methandrostenolone)',
    spId: 4,
    spMainCategory: 1,
    description: [
      'By Bill Roberts – Dianabol (methandrostenolone, methandienone) has been one of the most important anabolic steroids in bodybuilding ever since its introduction in 1958. Also commonly known as “Dbol”, this oral compound is best used for steroid cycles in combination with injectable steroids, but can be of value used alone as well.',
    ],
    spCategories: [
      'Stacking Dianabol with Injectable Steroids',
      'Dianabol and Testosterone Stack',
      'Dianabol and Anavar Stack',
      'Dianabol and Anadrol Stack',
      'Dianabol Only Cycles',
      'Dianabol Side Effects',
      'Dianabol and Aromatase Inhibitors',
      'Dianabol Availability',
      'Dianabol Recommendations',
    ],
    spCatDescriptions: [
      ['The effect of most injectable anabolic steroids is greatly enhanced by addition of Dianabol. The improvement is greater than from simply increasing the amount of injectable by the same amount. A classic and dramatic example is trenbolone. Though the total amount of steroid used is the same in all three cases, 50 mg/day each of trenbolone acetate stacked with 50 mg/day Dianabol provides a far more effective steroid cycle than either 100 mg/day of trenbolone acetate alone or 100 mg/day Dbol alone. The side effect profile is superior as well. In other words, the compounds act synergistically: the whole is greater than the parts. This is likely due to differing mechanisms of action.'],
      ['All injectables stack well with Dianabol, with partial exception that at higher doses of testosterone Dianabol becomes less useful and eventually entirely unnecessary. As examples, at 500 mg/week of testosterone use large improvement in a steroid cycle can be expected from adding Dianabol, but at 1000 mg/week only a moderate improvement is likely. At 2000 mg/week, possibly no noticeable further increase in effect will be seen except with individuals who have already reached a plateau at that amount of testosterone-only usage. For most this will not be the case.'],
      ['For those who like to base steroid cycles on oxandrolone (Anavar) rather than an injectable, Dianabol also stacks very well with this oral steroid. However, liver toxicity will be less if combining Dbol with an injectable instead of oxandrolone.'],
      ['There is little to no synergistic effect from combining Dianabol with Anadrol. Most users do not do this. If choosing to do so, for example because of having both compounds on hand and not enough of either to use alone, I prefer using half-doses of each. In other words, rather than using for example 50 mg/day Dianabol or 150 mg/day Anadrol, a good alternate is to combine 25 mg/day Dbol with 75 mg/day Anadrol.'],
      ['Dianabol can also be used alone. Many treat such usage with disdain, but in times past many excellent physiques were built with Dianabol as the sole performance-enhancing drug, and even today a few get good benefit from Dbol used alone. However, in my opinion it is only high responders who do well with this compound used alone, rather than this being a typical result.'],
      ['Adverse side effects of Dianabol include conversion to estradiol, suppression of natural testosterone production, and liver toxicity. Additionally, all other side effects common to anabolic steroids are possible, such as increased blood pressure, increased hematocrit, possible adverse effect on scalp and skin, and worsened blood lipid profile. In general however Dianabol usage is favorably experienced.'],
      [
        'An aromatase inhibitor such as anastrozole (Arimidex) or letrozole can correct problems of conversion to estrogen, and appropriate cycling is generally sufficient with regard to liver concerns. This means limiting use to preferably no more than 8 weeks, followed by an off period at least half as long as the period of use.',
        'For example, if Dianabol is used for 6 weeks, then this should be followed by at least 3 weeks without use of any oral anabolic steroid, and more preferably at least 6 weeks.'
      ],
      [
        'Because of low manufacturing cost, in addition to all the above advantages Dianabol is less often counterfeited than many other anabolic steroids.',
        '“Dianabol” was originally a trademark of Ciba-Geigy and properly referred only to their product, but for many years now any methandrostenolone product has been commonly referred to by this name.'
      ],
      [
        'The dosage range for Dianabol is typically 20-50 mg/day. Unlike most injectables, little further benefit occurs from going beyond this milligram amount. If wishing more effect, the answer is instead to add another anabolic steroid. My preference is for dosing to be 3-5 times per day, but some prefer to use it only once per day.',
        'Typically, this usage is combined with about 500-1000 mg/week total of injected anabolic steroids.',
        'As with any anabolic steroid cycle, Dianabol usage should be followed with PCT (post-cycle therapy) employing Clomid, Nolvadex, or toremifene.',
        'Methandrostenolone is the chemical name of active ingredient in Dianabol. Dianabol was originally a registered trademark of Ciba-Geigy Corporation in the United States and/or other countries prior to cancellation.',
      ]
    ],
  },
  {
    spName: 'Equipoise (Boldenone Undecylenate)',
    spId: 5,
    spMainCategory: 1,
    description: [
      'Equipoise (boldenone undecylenate) is historically a veterinary steroid but for some time has been available as an UGL human preparation as well.',
      'In the past, the principal reason for Equipoise’s use was it’s easy availability as a Mexican veterinary product. Presently, for the most part there is little specific reason to use Equipoise in an anabolic steroid cycle. There is nothing that it does anabolically that Masteron, Primobolan, Deca, or trenbolone will not do. In terms of side effects, likewise it has no advantage over Masteron or Primobolan, unless one desires the occasional side effect of increased appetite, or the side effect of its moderate conversion to estrogen. This can be useful if no other aromatizing steroid is being used.',
    ],
    spCategories: [
      'Effectiveness of Equipoise',
      'Half-Life of Equipoise',
      'Frontloading Equipoise',
      'Equipoise Side Effects',
      'Stacking Equipoise with Other Steroids',
    ],
    spCatDescriptions: [
      ['This is not intended to disparage the compound: it certainly can be put to good use in a steroid cycle. Its potency (effect per milligram) is comparable to most other injectable steroids, though less than that of trenbolone, and so for example addition of 400 mg/week is a substantial boost to most cycles. Alternately, if substituting boldenone for another injectable this can be done on a milligram-for-milligram basis.'],
      ['A serious drawback of Equipoise is its long half-life, which is probably at least 12 days. This results in an extended period of time after the last injection during which levels are neither sufficiently high for optimal anabolism, nor low enough to allow recovery to occur. A solution for this problem is to use Equipoise in only the earlier part of a cycle.'],
      [
        'Another effect of the long half-life is that to achieve reasonably prompt effect, a large frontload is needed. I recommend that the first injection be an amount equal to 1.7 – 1.8 times the amount that will be used per week, plus the amount of the usual injection. So if for example the ongoing dosing will be 200 mg twice per week, on the first day the frontload should be about 900 mg.',
        'Most do not do this, and as a result they experience little effect from Equipoise until many weeks into the cycle. For this reason, it’s widely claimed that Equipoise cycles need to be long or otherwise they do not work, but this really is not so. Instead the problem is failure to frontload, or failure to fronload sufficiently.',
      ],
      ['Boldenone is metabolized by aromatase to estradiol, but to a lesser a degree than testosterone. It also is metabolized by the 5-AR enzyme, in this case to 17b-hydroxyandrost-1-ene-3-one, commonly but incorrectly called “1-testosterone” and sometimes called dihydroboldenone. This molecule’s potency is similar to that of boldenone, so the molecule is neither potentiated or deactivated by the 5-AR enzyme and therefore that metabolism is unimportant.'],
      [
        'Boldenone might be used in a couple of interesting ways, for example in combination with trenbolone to allow a lower dose of trenbolone while retaining similar anabolic effect and providing a needed amount of aromatizing steroid. It might also combined with Masteron or Primobolan, instead of testosterone, in self-prescribed HRT. The advantage would be reduced production of DHT. However, these uses have yet to be much explored.',
        'But in general, for most Equipoise is not a first-call anabolic steroid. Not for reason of any real lacks, but simply because there are other choices that generally will work as well for the overall cycle plan.',
        'Boldenone undecylenate is the chemical name of the active ingredient in Equipoise. Equipoise is a registered trademark of Wyeth Holdings Corporation in the United States and/or other countries.',
      ],
    ],
  },
  {
    spName: 'Halotestin',
    spId: 6,
    spMainCategory: 1,
    description: [
      'Halotestin (fluoxymesterone) is a halogenated derivative of 17-alpha-methyltestosterone. Contrary to what one might expect, Halotestin has poor binding to the Androgen Receptor (AR). Presumably the effects of fluoxymesterone are largely non-AR mediated. Halotestin, however, is a rather toxic drug and I consider it to be a poor choice except perhaps pre-contest for those willing to suffer its toxicity, or for endurance athletes in non-tested competitions.',
      'Fluoxymesterone is the chemical name of active ingredient in Halotestin. Halotestin is a registered trademark of Pharmacia & Upjohn Company in the United States and/or other countries.',
    ],
    spCategories: [
      
    ],
    spCatDescriptions: [
      
    ],
  },
  {
    spName: 'Masteron (Drostanolone Propionate)',
    spId: 7,
    spMainCategory: 1,
    description: [
      'by Bill Roberts – Masteron (dromostanolone propionate, also known as drostanolone propionate) after a long period of time as a rare drug is now enjoying greater popularity, and deservedly so.',
      'Where one does not wish to use a large amount of injectable testosterone and wishes to use either no trenbolone or only a modest amount of that drug, Masteron is often an ideal choice for inclusion in a steroid stack, or in some cases for use as the sole injectable for a steroid cycle.'
    ],
    spCategories: [
      'Masteron Side Effects',
      'Masteron as an Anti-Estrogen',
      'How Masteron Compares to Other Steroids',
      'Masteron Stacks',
      'Problem with Masteron-Only Cycles',
      'Masteron Recommendations',
    ],
    spCatDescriptions: [
      [
        'Masteron undergoes no aromatization (conversion to estrogen), no conversion to DHT or potentiation by the 5-AR enzyme, and as an unalkylated steroid it poses no liver issues. In these regards and also in overall side effects, Masteron is best compared with Primobolan Depot.',
        'In terms of positive effects in an anabolic steroid cycle, Masteron is at least as effective as Primobolan per milligram for mass gain and for fat loss, and appears better for hardening.'
      ],
      ['Dromostanolone has some reputation for anti-estrogenic activity, but in actuality this effect is fairly subtle. If due to use of aromatizing steroids a steroid cycle would produce substantially excessive levels of estrogen, adding Masteron to the cycle will not fix that. Instead, an anti-aromatase such as letrozole or anastrozole should be used. However, where only a moderate amount of aromatizing steroid is being used, Masteron can in many instances be sufficient as the sole anti-estrogenic agent.'],
      [
        'Many have called Masteron a “weak” steroid, but this definitely is not the case. What often has been weak has been the doses! Doses of this drug have historically been low because of rarity, price, and low concentration of the preparations (100 mg/mL.) However, both availability and price have improved markedly in recent years.',
        'When included as part of a steroid stack, Masteron’s contribution per milligram is at least as great as that of testosterone, boldenone (Equipoise), nandrolone (Deca), or methenolone (Primobolan.) But of course, if only 100 or 200 mg is added per week, there will be no large change seen from such a small addition.'
      ],
      [
        'Masteron can be used as the sole injectable: in this case, I prefer to see dosing of 500-700 mg/week. A Masteron/Dianabol stack is more effective however, as is a Masteron/Anadrol stack. Testosterone also combines well with Masteron.',
        'Another use of Masteron which has become more common lately is combination with trenbolone. Unlike combination with Dianabol or Anadrol, there is only an additive effect, not a synergistic effect. However, including Masteron allows a lower trenbolone dosage while achieving very similar or equal physique benefit with less potential for trenbolone-specific side effects of night-sweats, increased tendency to aggression, and/or insomnia.',
        'Masteron also is useful in combination with testosterone for self-prescribed hormone-replacement therapy (HRT.) For example, 100 mg/week each of Masteron and testosterone can be superior for physique benefits to 200 mg/week testosterone alone, while being milder in side effects due to lower resulting levels of estradiol (estrogen) and DHT.'
      ],
      ['I don’t recommend using Masteron alone for a steroid cycle or HRT because estradiol levels typically fall too low when this is done. Inclusion of for example at least 100 mg/week testosterone, at least 10 mg/day Dianabol, or at least 700 IU/week total HCG will maintain normal estradiol levels during a Masteron-only cycle, should one wish to do this.'],
      [
        'As a propionate ester, Masteron has a half-life of probably two days and therefore is preferably injected at least every other day, and more preferably daily. An enanthate ester version having a half-life of probably 5 days has become available. This version of Masteron may be injected as infrequently as twice per week.',
        'The traditional (propionate) form of Masteron is also advantageous for brief cycles or for use at the end of a longer cycle, giving quick transition from high, anabolically-effective blood levels to levels sufficiently low to allow recovery of natural testosterone production.',
        'Very simply, Masteron does very well what it’s supposed to do, and has as mild a side-effect profile as is possible for an anabolic steroid. I have never known anyone to be disappointed with this steroid, provided sufficient dosing was used.',
        'Dromostanolone propionate is the chemical name of the active ingredient in Masteron. Masteron was a registered trademark of Sarva-Syntex in Belgium and/or other countries prior to cancellation.',
      ],
    ],
  },
  {
    spName: 'Turinabol',
    spId: 8,
    spMainCategory: 1,
    description: [
      'Oral-Turinabol (dehydrochloromethyltestosterone) is an oral anabolic steroid which is interesting principally for reasons other than its unexceptional performance. It can however meet some specific needs.',
    ],
    spCategories: [
      'What Is Oral-Turinabol?',
      'History Of Oral Turinabol',
      'Dosing Of Oral Turinabol For Men',
      'Oral Turinabol For Women?',
      'Summary',
    ],
    spCatDescriptions: [
      [
        'Oral Turinabol is, to some extent, enigmatic and prone to misunderstanding.',
        'It is not simply a tablet version of injectable Turinabol, or an alkylated version of it. Nor, despite claims, is it an anabolic steroid developed specifically for doping. That claim results from confusion with mestanolone.',
        'Structurally, it is Dianabol with an added chlorine at the -4 position, which has the predictable advantage of preventing aromatization.',
        'Caution must be taken however when trying to arrive at conclusions about anabolic steroids from structure. Substituting an atom or chemical bond makes large changes to the entire shape rather than affecting only a single point. This can result in changes in properties beyond the predictable. An example would be if one tried to predict the properties of Dianabol from its structural relation to boldenone (they are the same except for the 19-methylation of Dianabol.) While the methylation does provide the predictable oral bioavailability, in actuality Dianabol’s other properties are such that one can’t reasonably call “oral boldenone.”',
        'In the case of Oral Turinabol, due to the above structural relation to Dianabol it’s commonly called a dry version of it. Now if this were true though, one could tell no difference between using OT alone and using Dianabol plus aromatase inhibitor. There is considerable difference in practice however.',
        'OT must be considered its own compound rather than simply as a dry Dianabol. That said, this popular description is not a bad first approximation. Simply be aware of its limitations.',
      ],
      [
        'Ordinarily in anabolic steroid profiles I don’t discuss history of the drugs, but here it’s worth making an exception.',
        'OT received an East German patent in 1961 and was soon approved as a prescription drug. At the time, there was wide medical belief that anabolic steroids were useful for recovery from surgery, burns, or nearly any condition that caused substantial loss of bodyweight. Newly developed anabolic steroids were considered suitable even for women and children, as it was thought that synthetic modifications dissociated androgenic from anabolic effects.',
        'In 1966, use of OT for athletic doping began. This drug became the principal cause of the obvious virilization of East German female athletes of the era, despite the theoretical belief in separation of effects. Women did not respond as the theories from rat testing had predicted.',
        'Why did the East Germans choose this specific compound for athletic doping? The reason does not seem to be from superior performance to Western anabolic steroids, or from drug testing concerns. That did not become an issue until much later. And a particularly favorable side-effect profile seems unlikely as the reason, as no other anabolic steroid has such a thoroughly documented track record for adverse effect on health.',
        'A key doping advantage of OT for the East Germans was that it was locally produced. This provided easy availability, economy, and secrecy. And it was effective enough, especially for female athletes (but then again, every anabolic steroid is.)',
        'The fact that its bad side effect profile made it quite harmful to the athletes was irrelevant to the East German Communist regime. Many lives were destroyed, at least in the judgment of the unknowing female doping victims.',
        'Why mention all this? First, this history has created a mystique, whether warranted or not. And second, it has provided us with a great deal of toxicological information. Last, one should be sure that if choosing the drug, the decision is not simply from the mystique. Being the old East German doping drug of choice should be irrelevant to whether an athlete or bodybuilder should choose it today. Instead it should be considered in terms of its positive and negative effects. Among available anabolic steroids, OT offers nothing outstanding here, though it’s certainly usable.',
      ],
      [
        'When Oral Turinabol is used in an anabolic steroid cycle, less than 20 mg per day will be an almost unnoticeable addition to a stack, or will be a very weak cycle if used alone. There is little point in exceeding 50 or 60 mg/day, as added anabolic effect will be small if any. And adverse side effects of excessive muscle pumps and/or blood pressure elevation are often at a tolerance limit at this point, while becoming excessive past it.',
        'I don’t recommend combining OT with other oral anabolic steroids. Instead, when using OT and wishing to increase anabolism, one or more injectables should be added rather than another oral. A sufficient reason for this is that results are much better from combining an injectable rather than any other oral (with possible exception of oxandrolone.) Another reason is that at effective doses of OT, hepatotoxicity is already as high as can be considered reasonable. Another alkylated steroid should not be added.',
        'Still another reason to not combine OT with other oral anabolic steroids in a stack with injectables is this: If Dianabol or Anadrol are included in the stack, then there’s no reason to also include OT. It will do nothing anabolically that they will not.',
        'Oral Turinabol can be used alone with significant results for novice steroid users or for experienced users who have experienced losses and are regaining. However in general I don’t recommend oral only cycles, and this is no exception.',
        'When using OT, dosing is preferably divided to twice per day, although once per day is acceptable.',
      ],
      [
        'East German female athletes took OT at 5-15 mg per day for two to six weeks at a time. Aside from obvious virilization, many of these female athletes also suffered liver disease, heart disease, infertility, psychiatric issues, and even death. Oral Turinabol is proven not to be a safe anabolic steroid for women. I am not saying these consequences are inevitable, but rather that it’s a proven fact that the incidence rate of such consequences is very substantial.',
        'Risk will exist for some women at doses less than 5 mg/day as well.',
        'Nearly any anabolic steroid is better choice for women than OT.',
      ],
      ['Oral Turinabol is of interest mostly for its history, not its performance. When chosen, dosing range should be 20-60 mg/day, taken either once per day or preferably twice. It is usable by men, although there are better choices. OT is a very poor choice for women.'],
    ],
  },
  {
    spName: 'Primobolan Depot (Methenolone Enanthate)',
    spId: 9,
    spMainCategory: 1,
    description: [
      'By Bill Roberts – Primobolan Depot (methonolone enanthate) is often of interest to beginning steroid users and sometimes to experienced users. The first question to consider is really not pharmacological but one of economics. Usually for any given level of effect, Primobolan is an unusually expensive choice. And for most users, there is no unique benefit gained from its use. Accordingly, most experienced steroid users do not include it in their steroid cycle planning.',
    ],
    spCategories: [
      'Primobolan Depot Not a “Weak” Steroid',
      'Primobolan Depot Stacks',
      'Primobolan and HCG',
      'Primobolan and Trenbolone',
      'Primobolan Depot Recommendations',
      'Primobolan Depot Does Not Convert to Estrogen or DHT',
    ],
    spCatDescriptions: [
      [
        'That said, contrary to common opinion, Primobolan really is not a weak steroid, at least not on a milligram for milligram basis. It certainly is not weak in terms of anabolic effect versus side effects. It is a good performer in these regards. However, because the oil solubility of methenolone enanthate is only moderate, preparations are typically of only 100 mg/mL. This can give a psychological impression of not being as strong a compound as more concentrated products.',
        'Another likely reason for perceived weakness is that it is most often used for anabolic steroid cycles which deliberately are very conservative. For example, a classic beginner cycle is 400 mg/week Primobolan as the only steroid used. Of course, this does not give extreme gains. But then again neither does 400 mg/week testosterone!',
        'With most anabolic steroids or anabolic steroid stacks, total use needs to be at least 500 mg/week and more preferably 700-1000 mg/week before a cycle is likely to be highly effective. This rule is no different when Primobolan is used as the sole anabolic steroid or as part of a performance-enhancing stack (combination of drugs.)',
      ],
      ['Primobolan presents an interesting situation with regard to stacking. Pharmacologically there is probably no difference in stacking behavior compared to Masteron, and certainly no important difference, but in practice those who wish to use Primobolan almost always are looking for mild stacking choices as well. For this reason, though Dianabol, Anadrol, or testosterone are effective stacking choices, they usually are not combined with Primobolan.'],
      [
        'For those wishing a mild steroid cycle, perhaps the best performance-enhancing drug to combine with Primobolan is not another anabolic steroid directly, but HCG at doses totalling approximately 1500 IU/week. This may be taken as, for example, 200 IU daily, 400 IU every other day, or 500 IU three times per week. This typically yields high-normal testosterone levels and maintains normal estrogen levels, aiding the effectiveness of Primobolan with relatively little added androgenic side effects. Such usage may be counted as comparable to injecting 100 mg/week testosterone, and so for example 500 mg/week Primobolan use combined with this amount of HCG may be considered comparable to 600 mg/week total usage of anabolic steroids.',
        'For those seeking to absolutely minimize effect on hair in skin, addition of this amount of HCG may be excessive. Because more moderate testosterone levels are desirable in such instances, the HCG dose should be reduced to a total of only 700-750 IU/week. Further, the amount of Primobolan should be kept to no more than 400 mg/week, as Primobolan itself also has effect on hair and skin.'
      ],
      ['An alternate situation, not commonly seen, is where a cycle is trenbolone-based but rather than using a relatively high dose of trenbolone, the athlete limits the dose of that drug and uses another non-aromatizing injectable to support it. The combination can be milder in side effects while being still equally effective, at least in terms of anabolism, to higher-dose trenbolone. While Masteron is a more cost-effective choice for this purpose, Primobolan also will work well in this situation.'],
      ['The half-life of methenolone enanthate is probably about 5 days. As a result, Primobolan is most effectively used when injected at least twice per week. At the 400 mg/week usage level, post-cycle therapy (PCT) may be started only 5 days after the last injection, whereas at a higher level of usage such as 1000 mg/week, at least 10 days will be needed until recovery is likely to become possible.'],
      [
        'Methenolone is not subject to metabolism by the 5-alpha reductase enzyme (5AR), nor the aromatase enzyme. It therefore does not convert to DHT or to estradiol. These are among the advantages of the compound. However, its lack of conversion to DHT does not mean that it has no effect on the scalp: all anabolic steroids do.',
        'Briefly, Primobolan is an effective injectable anabolic steroid that usually is expensive for the anabolic effect achieved, is most commonly used for mild cycles, and in most – but not all – cases is an inefficient choice for more advanced cycles.',
        'Methenolone enanthate is the chemical name of active ingredient in Primobolan. Primobolan was a registered trademark of Schering AG in the United States and/or other countries prior to cancellation.'
      ],
    ],
  },
  {
    spName: 'Proviron (Mesterolone)',
    spId: 10,
    spMainCategory: 1,
    description: [
      'Proviron (mesterolone) is an interesting anabolic steroid, though it is not of much value to bodybuilding. It seems that the most common reason for many to consider including it in a steroid stack is for anti-estrogenic activity. In the days before anti-aromatases and the use of SERMs (selective estrogen receptor modulators) such as Clomid and Nolvadex, there was some merit to this.',
      'An amount of aromatizing steroid marginally over the amount that would be tolerable without Proviron became tolerable with the inclusion of it. However, the effect is indeed only marginal, and much better anti-estrogenic agents now exist. When these are used, there is no need for Proviron as an anti-estrogen.',
      'The mechanism by which it has some effectiveness in this regard is from binding weakly to the estrogen receptor without activating it, and to the aromatase enzyme. In the first case, this partially reduces the number of receptor sites momentarily available to bind estrogen, thus reducing estrogenic activity. In the second case, some fraction of aromatase molecules at any given moment are unavailable to bind and convert testosterone, their binding sites being occupied with mesterolone.',
      'The second most common reason is probably to address a fear that libido might be lost without it. It is true that when added to normal androgen levels, Proviron has an androgenic effect that in many cases improves libido. However, most anabolic steroids also have this same property. In a steroid cycle, adding Proviron accomplishes nothing further. Or in the case of anabolic steroids such as nandrolone (Deca) which for other reasons may adversely affect libido, Proviron provides no greater help against that than do various other anabolic steroids.',
      'One of the interesting things about Proviron is that while it assays (tests) as being an effective anabolic in the rat, it is virtually useless for building muscle in man. This may be due to enhanced conversion in muscle tissue to the diol, but it may instead be due to some unknown reason.',
      'Another interesting thing is that it enjoys some practical use as a pro-sexual agent inbetween cycles. Typically 50 mg is taken shortly before improved performance is expected to be useful.',
      'Contrary to common belief, Proviron is somewhat inhibitory of the HPTA. Using it during the recovery phase as part of post-cycle therapy is counterproductive. It is also inadvisable for this reason to use it continuously while intending to be off-cycle, but occasional recreational use presents no problem.',
      'Proviron has been used in female bodybuilding, but it has almost undoubtedly the worst ratio of anabolic effect to virilizing effect of any anabolic steroid in common use. The perceived value is in fat loss and “hardening,” but friendlier choices exist for this. Most of that value is from anti-estrogenic effect, which Nolvadex can also accomplish. And for an androgen component, there are better choices, including Primobolan.',
      'Unlike almost all other orals, Proviron is not hepatotoxic, as it is not 17-alkylated.',
      'Tablets are typically 25 mg, and taken one or two at a time. Amounts greater than this have no further effect on improving libido off-cycle, but if used for a specific occasion, whether on-cycle or off-cycle, may temporarily improve vascularity, if it is already noticeable but has room for improvement.',
      'Mesterolone is the chemical name of active ingredient in Proviron. Proviron was a registered trademark of Schering AG in the United States and/or other countries prior to cancellation.'
    ],
    spCategories: [
      
    ],
    spCatDescriptions: [
      
    ],
  },
  {
    spName: 'Sustanon',
    spId: 11,
    spMainCategory: 1,
    description: [
      'Testosterone is the most important and popular anabolic steroid, and Sustanon 250 is the best-known form of injectable testosterone. It has become so popular for anabolic steroid cycles that the name now extends past the Schering brand and is often given to any steroid preparation made to the same formulation.',
    ],
    spCategories: [
      'Sustanon 250 Contains Four Testosterone Esters',
      'Sustanon 250 Side Effects',
      'Sustanon 250 Dosages',
      'Sustanon 250 Recommendations',
    ],
    spCatDescriptions: [
      ['Sustanon 250 contains, per mL, short-acting testosterone propionate, 60 mg of testosterone phenylpropionate, 60 mg of testosterone isocaproate, and 100 mg of testosterone decanoate. The first, testosterone propionate, is short-acting and gives Sustanon a quick onset of action in a steroid cycle. The other esters are medium to long-acting.'],
      [
        'Side effects of Sustanon are, for the same amount of testosterone, identical to other testosterone esters such as testosterone enanthate.',
        'The principal reasons to choose between Sustanon and testosterone enanthate are price and availability. Quality of results in the steroid cycle will not be affected by the decision.'
      ],
      [
        '250mg per Week:',
        'At a usage level of 250 mg/week, Sustanon provides basically only a high level of testosterone replacement therapy. Individuals with low testosterone may see a marked improvement, but many with mid-normal or high natural testosterone will see little added effect at this dosage level. Yet, Sustanon is suppressive of the hypothalamus and pituitary at this dosage and will largely shut down natural testosterone production while being used. So, this dosage has relatively little of the benefits of most steroid cycles, but shares the adverse side effect of suppressed testosterone production.',
        '500mg per Week:',
        '500 mg/week is a minimal dosage level for most to see good results. At this dosage, gains are typically good for a first cycle and often no ancillary drug is required to control estrogen. But in many instances, estrogen control is already desirable.',
        '750mg per Week:',
        'The next step up of 750 mg/week provides a noticeable further increase in gains, and if estrogen is controlled, side effects are generally not much different than at 500 mg/week.',
        '1,000mg per Week:',
        'A dose of 1,000 mg/week is generally sufficient for intermediate to even many advanced users. In some cases, even more than this is used. However, this generally is not necessary unless results have reached a plateau already at the 1 g/week level.',
      ],
      [
        'Sustanon dosing during a steroid cycle should generally be divided into at least two injections per week, and more preferably at least three.',
        'Post-cycle therapy is highly advisable after any steroid cycle, including a Sustanon cycle.',
        'Further information on testosterone, the active anabolic steroid delivered by Sustanon, can be found in my Testosterone Profile.',
      ],
    ],
  },
  {
    spName: 'Testosterone Cypionate',
    spId: 12,
    spMainCategory: 1,
    description: [
      'Testosterone cypionate is a long-acting version of the parent hormone testosterone with an attached cypionate ester to delay its release into the body. Testosterone is the most potent, naturally occurring androgen that is formed in the human body. It is responsible for characteristics specific to males and their sexual traits.',
    ],
    spCategories: [
      'Background',
      'Steroid Action',
      'Technical Data',
      'User Notes',
    ],
    spCatDescriptions: [
      ['Testosterone was first synthesized in 1935. Many pharmaceutical forms have been created since. Most major pharmaceutical houses such as Organon and UpJohn, manufacture Testosterone Cypionate, as do many underground labs.'],
      [
        'When this long-acting version of testosterone is injected, it becomes stored in what is known as a depot in the body, and slowly released over a short period of time. Peaking within 1-2 days after injection, the testosterone is then steadily released over the next 12 days and completely tapers out after approximately 3 weeks.',
        'Testosterone is responsible for promoting health and well being through enhanced libido, energy, immunity, increased fat loss, gaining and maintaining lean muscle mass, preventing Osteoporosis (loss of bone density), and possible protection against heart disease. Testosterone is also responsible for normal growth and development of male sex organs and maintenance of secondary sex characteristics. Secondary s ex characteristics are specific traits that separate the two sexes, but are not directly part of the reproductive system, for example: chest and facial hair, a distinguished jaw line, broad shoulders and increased muscle mass. Testosterone binds to the Androgen Receptors (AR), which thus causes accelerated muscle gain, fat loss, and muscle repair and growth. These mechanisms are stimulated by activation of the Androgen Receptors (either directly or as DHT), as well as through a hormonal cascade.',
        'There are many possible side effects associated with the use of Testosterone Cypionate. This product has a high level of aromatization into estrogen and coverts to DHT (dihydrotestosterone) as well. Estrogen is the female sex hormone, and too much of it can cause a high risk of gynecomastia (formation of breast tissue in males), water retention, and other undesirable side effects. DHT can cause male pattern baldness as well as acne.',
        'Also, supplementing Testosterone to your body will result in the shutting down of the body’s natural production of the hormone. The severity of side effects depends mostly on the levels and duration of circulating free testosterone as well as its conversion into other hormones. Testosterone’s anabolic/androgenic effects (as well as side effects) are dependant upon dosage, and the higher the dose the higher the muscle building effect.',
        'Testosterone Cypionate is one of the best mass building anabolics known to man and is a highly recommended as the base of any mass building cycle. An effective dose of this drug for a man is between 200-2000 mg per week. An effective dose for a woman (wanting to become a man) is similar. Other women (i.e. those not wanting to become a man) should avoid this compound.',
      ],
      [
        'Overall, the most common effect reported by subjects using Testosterone Cypionate was gains in strength',
        '1. Alterations in size, shape, and appearance of the muscle were reported',
        '2. The degree of effects typically experienced with Testosterone use depends on dosage. Generally, the higher the dose used, the better the results, yet more potential for side effects due to higher amounts of androgens in the body',
        '3. Its been suggested strongly through studies, that doses as high as 600 mg per week produce greater results verses those who received lower doses. At the highest dose, (600 mg/week), the best results were achieved. At this dosage, results reported were: fat loss, muscle growth, lowered HDL cholesterol and increased size and strength',
        '4. There was (roughly) a 15% gain in Lean Body Mass achieved through 20 weeks of 600 mg/week of Testosterone therapy',
      ],
      [
        'Testosterone Cypionate is the most commonly prescribed form of testosterone in the United States of America. In fact, it is a bit of a rare find outside of the USA, with most places in the world deferring to the very similar Testosterone Enanthate.',
        'In truth, I’ve used both and find no difference at all between the two. Testosterone Cypionate is identical with regards to results to any long acting testosterone. In the original Underground Steroid Handbook, Dan Duchaine said that users could feel more “kick” from this form over Enanthate, but I honestly haven’t found that to be true personally.',
        'The prime advantage of this product is the fact that Testosterone Cypionate can be injected once per week and it gives some very good gains in strength and size. I would bet that many careers in bodybuilding and athletics were built with the long term use of this drug. I’ve used it at doses of 250-2,000 mg per week, and found that the best gains versus side effects are probably had somewhere below the 750 mg/week mark. Of course this is far below what many competitors in the upper ranks of bodybuilding use, but for the average (recreational) bodybuilder or athlete, the side effects above this dose do not constitute an acceptable trade off for the gains.',
      ],
    ],
  },
  {
    spName: 'Testosterone Enanthate',
    spId: 13,
    spMainCategory: 1,
    description: [
      'Testosterone Enanthate a long acting form of the parent hormone testosterone. In this particular case, the parent hormone has been attached to the Enanthate ester to delay its release into the bloodstream over several days.',
    ],
    spCategories: [
      'Background',
      'Steroid Action',
      'Technical Data',
      'User Notes',
    ],
    spCatDescriptions: [
      [
        'Testosterone was first synthesized by being isolated from an animal source in 1935. Many different esters, including enanthate, have been attached to the parent hormone since then.',
        'Testosterone Enanthate is the most popular form of testosterone prescribed everywhere in the world except for the United States, where the very similar Testosterone Cypionate is typically preferred. The most common form of Tesosterone Enanthate (other then generic or underground) available in the United States is called “Delatestryl.”',
      ],
      [
        'Testosterone exerts both genomic and non-genomic effects in the human body. This means that while many of testosterone’s effects are mediated by the androgen receptor, and gene transcription, many also occur without such stimulation. Testosterone promotes health and well-being, enhances libido, increases energy and promotes fat loss. It can also boost immunity. Testosterone aids in gaining and preserving lean muscle mass. It prevents against bone loss as well as heart disease.',
        'Testosterone Enanthate has a release time of between 8-10 days. It is typically injected once every one to three weeks, though some users opt for a twice a week frequency as well. This version of testosterone will cause the usual side effects associated with Testosterone, including: hair loss, acne, gynecomastia and eventual cessation of the body’s natural testosterone production. Many of these side effects can be associated (if not totally attributed) with the conversion of Testosterone into Dihydrotestosterone and estrogen.'
      ],
      [
        'Testosterone’s anabolic/androgenic effects are dependant upon the dose administered; usually the higher the dose, the better the results',
        '1. In a study done on Testosterone Enanthate, a dose as high as 600 mg’s produced better results in subjects compared to those who received lower doses. The most fat was lost and lean body mass, strength and size was gained by the group who used the highest dose (600 mg/week), when compared to any of the lower doses studied',
        '2. In the same study, HDL cholesterol was lowered and some acne was experienced by the subjects. There was roughly a 15% gain in Lean Body Mass from 20 weeks of 600mgs/week of testosterone enanthate. HDL cholesterol was also lowered and the subjects experienced acne. Overall, the most common effect reported by subjects using testosterone is gains in strength',
        '3. And typically, testosterone is implicated in positive alterations in size, shape, and appearance of muscle',
        '4. Due to stimulation of the Androgen Receptors (AR), accelerated muscle gain, fat loss, increased muscle repair and growth is experienced by those using injectable testosterone',
        '5. Testosterone can also binds to the AR on fat cells; therefore, adipose (fat) tissue can be broken down more readily and new fat formation is prevented',
        '6. It is thought, therefore, that many of testosterone’s effects are receptor mediated; although many effects are also non-receptor mediated. While using injectable Testosterone Enanthate, since the body is building muscle at an accelerated rate, more foodstuffs are shuttled directly to the muscle tissue (this is known as nutrient portioning) and away from fat. This is another indirect effect of testosterone on fat loss. Testosterone also promotes glycogen synthesis, which is activated by insulin in response to high glucose levels',
        '7. Thus allowing someone using testosterone to more efficiently utilize food as fuel.',
      ],
      [
        'Testosterone Enanthate is, to me, probably no different than testosterone cypionate (another long acting form of testosterone). It allows for once a week administration and can be found on the black market in doses anywhere from 200-500 mg/ml.',
        'Personally I have used this form of testosterone on many occasions. Although I prefer the shorter esters, Testosterone Cypionate is probably the cheapest form of testosterone that can be found on the black market, and that has influenced my choice to use it more than once.',
        'Water retention with testosterone enanthate often requires some kind of anti-estrogen to combat it (for me, anyway), although I seem to be able to handle pretty high doses without having any other problems with bloodwork or gyno.',
        'Most people will find that 500-1000 mg of this form of testosterone is going to be more than enough to build tons of new muscle. My own personal anabolic routine (doctor prescribed) rarely has me going over 500mgs of testosterone, and I typically feel that 750 mg is the upper limit of the acceptable risk/reward ratio, unless the user is in a profession where it’s necessary to do more (i.e. is a professional bodybuilder, etc.)',
        'Testosterone enanthate is the chemical name of active ingredient in Delatestryl. Delatestryl is a registered trademark of Indevus Pharmaceuticals Inc. in the United States and/or other countries.'
      ],
    ],
  },
  {
    spName: 'Testosterone Profile',
    spId: 14,
    spMainCategory: 1,
    description: [
      'Testosterone, as the natural product drug and one of the most widely used anabolic steroids, is the most convenient choice for a reference drug to which all others will be compared. And while it is entirely possible to construct maximally-effective steroid cycles without employing testosterone, most do not do this, but instead use testosterone as their foundation. Either approach can be entirely sound.',
      'As a bodybuilding drug, testosterone is almost always used as an injectable ester, due to poor oral bioavailability and the impracticality of high dose transdermal or sublingual delivery. Testosterone also is provided as an injectable suspension. Discussion here is in reference to these injectable preparations.',
      'Pharmacologically, testosterone acts both via the androgen receptor and via other means. In practice, it is found to combine synergistically both with those anabolic steroids categorized as Class I and those categorized as Class II, and therefore is described as having mixed activity.',
      'Particular properties of testosterone that are of note include that it converts enzymatically both to dihydrotestosterone (DHT) and to estradiol (the most important of the estrogens.)',
      'While with normal levels of testosterone and normal enzyme activity these conversions are in fact desirable, with supraphysiological testosterone levels caused by drug administration they can be undesirable. DHT is at least three times more potent (effective per milligram) than testosterone at the androgen receptor (AR): therefore, in those tissues which convert testosterone to DHT, there is effectively three times as much androgen as elsewhere in the body. Thus, whatever level of androgen is experienced by the muscle tissue is effectively multiplied threefold or more in the skin and in the prostate. This can be excessive.',
      'Dutasteride (Avodart) can be used to keep DHT levels normalized despite heavy testosterone use. Most users do not do this out of concern for excessively reducing DHT, which may be a valid concern at full label dosing, but which I do not think is a concern with low-dose use (½ tab every other day) in the context of a high-dose testosterone cycle.',
      'Finasteride (Proscar) may be employed instead, if one wishes to use a 5alpha-reductase inhibitor. In this case, in the context of a high-dose testosterone cycle, one tab (5 mg) of this drug per day is unlikely to excessively decrease DHT.',
      'Excess conversion to estrogen is another undesirable occurrence since it contributes to inhibition of the hypothalamic/pituitary/testicular axis (HPTA), can cause or aggravate gynecomastia, can cause bloating, and can give unfavorable fat pattern distribution. This conversion can be controlled by use of aromatase inhibitors such as Arimidex or letrozole, and/or the effects of excess estradiol may be blocked in relevant tissues by Clomid or Nolvadex.',
      'Among the most significant differences of synthetic anabolic steroids compared to testosterone is that they may avoid either or both of these enzymatic conversions. In the past, this was a very important advantage. However, now that these conversions can be well-controlled, high-dose testosterone need not have all the adverse side effects that once inevitably accompanied its use.',
      'Testosterone used as the sole androgen is capable of giving very effective results, particularly with doses of one gram or more per week, and can give substantial results with only 500 mg/week. If no other drugs are used to control estrogen, however, side effects such as gynecomastia are fairly likely. Prostate enlargement, acne or worsening of acne, and acceleration of male pattern baldness (for those genetically susceptible to it) are more problematic with testosterone – again, in the absence of enzymatic control — than with many synthetics because of the effectively-higher androgen levels seen in these tissues as a result of local conversion to the more-potent DHT.',
      'So, to minimize these effects, the choices for a highly-effective cycle that is low in side effects are to either control these enzymatic conversions with ancillary compounds while using testosterone at high dose; to instead use synthetics which do not undergo these conversions; or to combine moderate dose testosterone (100-200 mg/week) with synthetics.',
      'An anti-aromatase is preferable in a testosterone cycle to a selective estrogen receptor modulator (SERM) such as Clomid or Nolvadex for controlling estrogen because the SERMs either do nothing towards reducing effect of elevated estrogen in aggravating or causing acne, or themselves contribute adversely. Additionally, abnormally elevated estrogen levels may be deleterious for other reasons.',
      'With regard to inhibition of the hypothalamic/pituitary/testicular axis (HPTA), 200 mg/week of injected testosterone is approximately 2/3 to 3./4 suppressive, while 100 mg/week is about 50% suppressive. For this reason, low dose testosterone use is not particularly efficient, as natural production is already “worth” 100-200 mg/week, and this is mostly lost with the first 200 mg/week of injectable that is used. The particular synthetics which are low-suppressive are, for this reason, more efficient for low-dose use than is testosterone.',
      'In terms of planning HPTA recovery after a cycle, for the above reason there is little point in beginning post-cycle therapy (PCT) until testosterone levels from the cycle have fallen to being commensurate with use of no more than about 200 mg/week. So for example, if using 800 mg/week, it would be advisable to wait two half-lives. (After a number of days equal to the half life, levels will drop to that commensurate with 400 mg/week use, and after that same number of days again levels will again fall in half, now to levels to commensurate with 200 mg/week use.) So for example if the half-life of the ester used were 5 days, one would wait till 10 days after the last injection to begin PCT, when the drug in question is testosterone, due to the particulars of its suppressive properties.',
      'With use of an anti-aromatase, 600-750 mg/week of injected testosterone is a good dosage range for a novice. Without an anti-aromatase, it may be preferred to limit usage to 500 mg/week, although there can be risk of gynecomastia at doses even as low as 200 mg/week if no anti-estrogen is used. More advanced users may favor one gram of testosterone per week. Still-higher doses such as 2 grams per week generally provide only a small further increment in performance, with that generally being noticeable only if a plateau has been reached at 1 gram per week. Amounts higher than this are employed by some pro bodybuilders but probably with only a slight further incremental effect.',
    ],
    spCategories: [
      
    ],
    spCatDescriptions: [
      
    ],
  },
  {
    spName: 'Testosterone Propionate',
    spId: 15,
    spMainCategory: 1,
    description: [
      'Testosterone is the primary male sex hormone. It is the responsible for producing mainly male-specific sexual traits. When synthesized, it is usually attached to an ester to delay its release into the body. Testosterone Propionate is the shortest commonly ester attached to the Testosterone hormone. This means it takes your body the least amount of time to rid itself of the ester and release the parent hormone into the body. Due to its short active life, testosterone propionate typically needs to be injected every other day at a minimum. Anecdotally, testosterone propionate causes the least side effects and the least bloating; these side effects usually subside very quickly when use is ceased.',
    ],
    spCategories: [
      'Background',
      'Steroid Action',
      'Technical Data',
      'User Notes',
    ],
    spCatDescriptions: [
      ['Testosterone was the first anabolic steroid developed, in the 1930’s. It has been used as the most potent mass builder for decades. Brand names of testosterone propionate include “Testovis” and “Virormone.”'],
      [
        'Testosterone is responsible for promoting health and well-being through enhanced libido, energy, immunity, increased fat loss, gaining and maintaining lean muscle mass, preventing Osteoporosis (loss of bone density) and possible protection against heart disease. Testosterone is also responsible for normal growth and development of male sex organs and maintenance of secondary sex characteristics. Secondary sex characteristics are specific traits that separate the two sexes, but are not directly part of the reproductive system, for example: chest and facial hair, a distinguished jaw line, broad shoulders and increased muscle mass. Testosterone binds to the Androgen Receptors (AR), which thus causes accelerated muscle gain, fat loss, and muscle repair and growth. These mechanisms are stimulated by activation of the Androgen Receptors (either directly or indirectly as DHT).',
        'There are many possible side-effects associated with Testosterone Propionate use. This product has a high level of aromatization into estrogen and coverts to DHT (dihydrotestosterone) as well. Testosterone can convert to estrogen, the female sex hormone, creating a high risk of gynecomastia (formation of breast tissue in males). Also, supplementing Testosterone to your body will result in the shutting down of the body’s natural production of the hormone. The severity of side effects depend mostly on the dose and duration of circulating free testosterone and it’s conversion to estrogen and DHT. Testosterone’s anabolic/androgenic effects are dependant upon also dosage; the higher the dose the higher the muscle building effect. Testosterone typically promotes aggressive and dominant behavior.',
        'Testosterone is the best mass builder known to man and recommended as the base of any mass building cycle.'
      ],
      [
        'Testosterone’s anabolic/androgenic effects are dependant upon the dose administered; usually the higher the dose, the better the results',
        '1. In a study done on Testosterone (Enanthate in this case), a dose as high as 600 mg (per week) produced better results in subjects compared to those who received all of the lower doses. At the highest dose, 600 mg/week, the greatest results were achieved in comparison to any of the lower doses studied. The highest fat loss, most muscle growth, and increased size and strength were achieved at the higher dose',
        '2. In the same study, HDL cholesterol was lowered and the subjects experienced acne. There was roughly a 15% gain in Lean Body Mass from 20 weeks of 600 mgs/week of Testosterone therapy.Overall, the most common effect reported by subjects using testosterone was immense gains in strength',
        '3. Alterations in size, shape, and appearance of the muscle were reported',
        '4. Due to stimulation of the Androgen Receptors (either directly or as DHT), accelerated muscle gain, fat loss, increased muscle repair and growth was experienced',
        '5. Testosterone binds to the AR on fat cells; therefore, adipose (fat) tissue can be broken down more readily while new fat formation is prevented',
        '6. Since the body is building muscle at an accelerated rate, more ingested food is shuttled directly to the muscle tissue (this is known as nutrient portioning) and away from fat. This is another indirect effect of testosterone on fat loss. Testosterone also promotes glycogen synthesis, which is activated by insulin in response to high glucose levels',
        '7. Glycogen provides fuel to the muscle; therefore endurance and strength increases were reported during severe muscle breakdown in intense training and workouts.',
        'Testosterone Propionate also converts to both Estrogen (through Aromatization) and Dihydrotestosterone (through 5a-reduction). Most of the side effects people experience with testosterone use is actually from it’s conversion to these two substrates. Thus, hair loss, water retention, acne, and other side effects are possible with use of this drug. Conversion to these hormones is also responsible for some of testosterone’s ability to build muscle; therefore when many side effects are avoided with the use of ancillary compounds, some of the muscle building properties are also stunted.',
        'Also noteworthy is that Testosterone Propionate administration typically causes the shutdown of natural testosterone production.'
      ],
      [
        'Testosterone Propionate is actually my favorite form of injectable testosterone. Anecdotally, this is because in myself as well as other users, this short acting ester would seem to produce far less water retention and a quicker onset of gains. The only down side to this particular form of testosterone is that it must be injected frequently, with many users (myself included) preferring to inject it every day or every other day at a dose of around 100 mg.',
        'One of the most interesting things I found with my personal research on Testosterone Propionate is that when I was using it at a 100 mgs Every-Other-Day, my testosterone levels were still at the high end of normal. This would seem to indicate that drug tested athletes could probably use this same dose and still pass a doping test.'
      ],
    ],
  },
  {
    spName: 'Trenbolone Acetate',
    spId: 16,
    spMainCategory: 1,
    description: [
      'by Bill Roberts – Among the synthetic injectable anabolic steroids – compounds other than testosterone, the natural product – trenbolone is perhaps the most remarkable. Of them, it is certainly the most remarkable for mass gain, strength gain, and contest preparation.',
    ],
    spCategories: [
      'Trenbolone Side Effects',
      'Avoid Trenbolone-Only Cycles',
      'Trenbolone and Suppressed Estradiol Production',
      'Stacking Trenbolone',
      'Typical Trenbolone Dosages',
      'Trenbolone Acetate versus Trenbolone Enanthate',
      'Trenbolone and Cortisol',
      'Trenbolone and Progestagenic Activity?',
      '“Tren Cough”',
      'Trenbolone Summary'
    ],
    spCatDescriptions: [
      ['To some extent, there is a price in side effects to be paid for this, but not nearly to the extent that many imagine. While a few find trenbolone problematic for reasons of increased aggressive tendency, night sweats, or reduced aerobic performance, most find these side effects slight and easily managed, or non-existent for them personally.'],
      ['Trenbolone, whether as the acetate (Finaplix, Finajet), enanthate, or cyclohexylmethylcarbonate (Parabolan), should not be used as the sole anabolic in a steroid cycle. Mass gains are greatly limited when this is done. Instead, trenbolone should be stacked with Dianabol, Anadrol, or testosterone as the most common and highly suitable choices.'],
      [
        'A second reason is that estradiol levels fall too low when trenbolone is used alone. This occurs because natural testosterone production is suppressed when using trenbolone, and this in turn suppresses natural estradiol production. And since trenbolone itself does not aromatize (convert to estrogen), then there is very little substrate available for aromatization, and estrogen production becomes abnormally low. This can cause joint, mood, and libido problems.',
        'Concurrent use of testosterone, Dianabol, or HCG with trenbolone can solve the above estrogen issue. Typically at least 100 mg/week of testosterone will be used for this purpose, or at least 10 or 15 mg/day of Dianabol.'
      ],
      [
        'Trenbolone exhibits interesting stacking behavior. Combination with either Dianabol or Anadrol gives a very strong synergistic effect. Even if the same total milligram amount of steroid is used per week, results are much better with such a combination than with any of these steroids used alone.',
        'For example, 50 mg/day of trenbolone acetate used with 50 mg/day Dianabol gives much better results in a steroid cycle than either 100 mg/day TA used alone, or that amount of Dianabol used alone. I wouldn’t recommend either of those single-drug usages for a steroid cycle, but the trenbolone/Dianabol stack has produced amazing physique changes in very many instances.',
        'In contrast, trenbolone’s stacking behavior with oxandrolone (Anavar), methenolone (Primobolan), or drostanolone (Masteron) is entirely different. If keeping the same total milligram amounts, these combinations do not outperform trenbolone used alone. So, while it’s acceptable to combine these drugs, the purpose should not be to achieve increased total effect. Such combinations may be useful however to reduce side effects, or to accommodate the materials that are on hand.',
      ],
      [
        'Trenbolone acetate is usually used at doses of 35-150 mg/day, and more typically 50-100 mg/day. The 35 mg figure generally is appropriate only when having high personal sensitivity to trenbolone-specific side effects. When trenbolone usage is this low and an effective cycle is desired, another injectable anabolic steroid should be added. Masteron is a good choice for this purpose. Another, quite different choice is testosterone.',
        'As for the higher 150 mg/figure, this generally is used for the purpose of increased nervous system stimulation compared to 100 mg/day rather than for further mass or strength improvements, which are already maximized or very nearly maximized at 100 mg/day.',
        'Most users find 50-75 mg/day to be an ideal dosage range, giving excellent benefit as part of an anabolic steroid stack.',
        'These milligram amounts are unusually low for an injectable anabolic steroid. Part of the reason is that trenbolone is remarkably potent (effective per milligram.) Another reason is that because the acetate ester is unusually light, a very high percentage of the weight of the trenbolone acetate molecule is the active steroid.'
      ],
      [
        'In contrast, trenbolone enanthate’s percentage of active steroid is nearly 20% lower. For this reason, weekly total dosing for trenbolone enanthate is a little higher than for the acetate. For the enanthate, weekly total dosing is typically 300-800 mg.',
        'Unlike trenbolone acetate which has a half-life of only about 1 day and therefore should be injected daily, trenbolone enanthate is typically injected 2-4 times per week. The half life is probably about 5 days.'
      ],
      ['While the mass gain results from trenbolone can instead be achieved with high milligram amounts of other injectable anabolic steroids, trenbolone differs from them in being stronger as a glucocorticoid (cortisol) antagonist and in decreasing blood corticosteroid levels. For this reason, it reduces subcutaneous water retention. And further, this anti-cortisol effect can make a significant difference in fat loss. It may also be a partial explanation for any increased tendency to aggression that is sometimes seen with trenbolone.'],
      [
        'With regard to activity at other receptors, many have written regarding theories of progestagenic activity of trenbolone. Where legitimate trenbolone is used, practical experience shows no detectable effect with regard to water retention or gynecomastia. Veterinary literature reports no detectable progestagenic effect. And if this were not enough, recent cell culture research shows progestagenic potency to be only about 1% that of progesterone itself, which would not be an important amount.',
        'For these reasons, I would make no decisions against trenbolone from fears of claimed progestagenic activity. It is a very well-proven compound in bodybuilding, including in contest-prep situations where an actually-progestagenic compound would be very unsuited.'
      ],
      ['The final unusual thing about trenbolone to be mentioned is “tren cough.” This is a very brief episode which may occur with only a small percentage of injections, perhaps not even 1%. It can be very unpleasant for a minute or two. The most likely explanation is from irritant effect of a small amount of the oil preparation entering the bloodstream, not by direct injection into a vein but by leakage into a small blood vessel that was cut as the needle entered. There is no evidence of lasting harm from this.'],
      [
        'If uncertain about personal response to trenbolone, the acetate ester is preferable as it clears the system quickly. It is also very desirable for short cycle use or for the ending weeks of longer cycles because the rapid clearance provides quick transition from having high levels suited for optimal results to low levels allowing recovery.',
        'While trenbolone use is not necessary to achieve excellent results in an anabolic steroid cycle, it can be a remarkable aid towards such results.',
        'Trenbolone acetate is the chemical name of the active ingredient in Finaplix. Finaplix is a registered trademark of Roussel Uclaf Corporation in the United States and/or other countries.'
      ],
    ],
  },
  {
    spName: 'Trenbolone Enanthate',
    spId: 17,
    spMainCategory: 1,
    description: [
      'Trenbolone Enanthate is a strictly underground product, and not available for human use by any major pharmaceutical supply house in the world. It combines a very popular parent hormone (trenbolone) with a long acting ester (enanthate). This allows the advantage of once a week injections.'
    ],
    spCategories: [
      'Background',
      'Steroid Action',
      'Technical Data',
      'User Notes',
    ],
    spCatDescriptions: [
      ['For a fairly long time, the only version of legitimate Trenbolone available on the black market was Trenbolone Acetate. This was a fast acting version of Trenbolone often made from powder or cattle implant pellets. Since Parabolan (Trenbolone with a slightly longer ester) had been removed from the market, it developed a cult-like reputation, and a longer acting Trenbolone product was in demand on the black market. The underground labs of the time responded with Trenbolone Enanthate.'],
      [
        'Trenbolone Enanthate is a19-nor steroid, very similar to Nandrolone. The primary difference between Trenbolone is that there is a double carbon bond present at the 9 and 11 position on the steran nucleus. Trenbolone can not aromatize to estrogen nor be 5a-reduced.',
        'It bonds very tightly to the Androgen Receptor, and for this reason it is thought that much of its fat-burning and muscle building abilities are receptor mediated.'
      ],
      [
        'Trenbolone Enanthate use produces an impressive amount of new muscle fiber, with minimal water retention',
        '1. This may be done partly through an increase in IGF-1 within muscle tissue',
        '2. And increased sensitivity of muscle satellite to IGF-1 and other growth factors',
        '3. And an increase in the amount of DNA per muscle cell. It has a very strong binding affinity to the androgen receptor (A.R), binding much more strongly than testosterone',
        '4. As well as nandrolone. Trenbolone increases both protein synthesis as well as nitrogen retention in muscle tissue',
        '5. Trenbolone can also bind with the (anti-anabolic) glucocorticoid receptor',
        '6. Thus aiding the muscle building process. Finally, it also has the ability to improve nutrient efficiency and mineral absorption in animals given the drug',
      ],
      [
        'You have probably noticed that there is no “Technical Data” for Trenbolone Enanthate per se (all of the studies cited are actually on Trenbolone with a different ester)',
        'Well…there’s a very good reason for that. As noted previously, trenbolone enanthate is a strictly underground product…so while you will easily be able to find it available from GA Labs or DL Pharmaceuticals INC., you will not find it available from UpJohn or Steris. In fact, at this point, you will only find Trenbolone (Acetate) available in the veterinary industry.',
        'In my experience with Trenbolone Enanthate, it is a very potent cutting agent, and will even help keep you lean while on a bulking cycle (it did for me, anyway). However, it will also cause some undesirable effects as well. It can cause insomnia and night sweats, as well as possible anxiety. It seems to not cause “tren cough” (coughing associated with Trenbolone Acetate) but still seems to reduce cardiovascular ability.',
        'All in all, though, Trenbolone (any ester) is a really great drug for both building muscle as well as burning fat. It’s probably most people’s favorite behind testosterone, side effects be damned…and for good reason.',
        'If you’re not a competitive athlete, this is a really great drug. Bodybuilders love the stuff, and we see it in many pre-contest cycles now-a-days. Athletes need to avoid it, however, because it simply reduces cardiovascular output too greatly, probably due to prostaglandin induced bronchial constriction.',
      ],
    ],
  },
  
    {
      spName: 'Trestolone Acetate (MENT)',
      spId: 18,
    spMainCategory: 1,
      description: [
          'Testosterone and its esters are widely used for androgen replacement therapy. Testosterone undergoes 5 alpha-reduction to dihydrotestosterone (DHT) in the prostate and other tissues leading to potentially undesirable consequences in adult males.',
          'Trestolone or 7 alpha-methyl-19-nortestosterone (MENT) is a synthetic androgen that is ten times as potent as testosterone. MENT is not 5-alpha reduced to DHT. It inhibits gonadotrophin release, suppresses testosterone and sperm production. Yet, MENT provides adequate replacement therapy for most androgen-dependant functions. MENT has a faster metabolic clearance rate than testosterone and, in contrast to testosterone, MENT does not bind to sex hormone binding globulin (SHBG). MENT remains capable of aromatization (to 7-alpha-methyl-estradiol) preserving the benefits estrogen imparts on male physiology.',
          'The Population Council has investigated MENT [specifically MENT Acetate (MENT Ac)] for long-term clinical use for contraceptive purposes and hormone replacement therapy. Initial trials suggest it may be an ideal candidate since it is a non-5-alpha reducible androgen and requires lower doses due to its significantly increased potency over testosterone.',
          'Various forms of MENT in human pharmaceutical preparations and devices for contraception and hormone therapy, specifically MENT Ac implant and MENT transdermal gel and patch formulations, are currently under clinical investigation. MENT is absorbed transdermally up to three times the rate of testosterone – 17 methyl testosterone and 17-α methyl testosterone.',
          'MENT, as a transdermal and/or intramuscular preparation, will have application in a wide range of indications beyond androgen replacement therapy and contraception, including, without limitation, primary hypogonadism, testicular failure, ASIH, baldness, sarcopenia, loss of bone mass, muscle wasting and cachexia, BPH, prostate cancer and of course, bodybuilding and sports performance enhancement.'
      ],

      spCategories: [
        'References'
      ],
      spCatDescriptions: [
        'Sundaram K, Numar K. 7alpha-methyl-19-nortestosterone (MENT): the optimal androgen for male contraception and replacement therapy. Int J Androl. 2000;23 Suppl 2:13-5.',
        'Anderson, Richard A., A. Michael Wallace, Naveed Sattar, Narender Kumar, and Kalyan Sundaram. “Evidence for tissue selectivity of the synthetic androgen 7α-methyl-19-nortestosterone in hypogonadal men,” Journal of Clinical Endocrinology and Metabolism 88(6): 2784–2793.',
        'von Eckardstein, Sigrid, Gabriela Noe, Vivian Brache, Eberhard Nieschlag, Horacio Croxatto, Francisco Alvarez, Alfred Moo-Young, Irving Sivin, Narender Kumar, Margaret Small, and Kalyan Sundaram, Population Council International Committee for Contraception Research. “A clinical trial of 7-α-methyl-19-nortestosterone implants for possible use as a long-acting contraceptive for men,” Journal of Clinical Endocrinology and Metabolism 88(11): 5232–5239.'
      ],
    },
    {
      spName: 'Winstrol (Stanozolol)',
      spId: 19,
      spMainCategory: 1,
      description: [
          'Winstrol (stanozolol) is a potent anabolic, but also binds to the progesterone receptor and to LAGS in the liver. In muscle tissue, Winstrol has been found to stimulate immediate-early gene expression by a means independent of the androgen receptor.',
          'Stanozolol can stimulate the production of prostaglandin E2 and the matrix metalloproteases collegenase and stromelysin in skin fibroblasts. It has been found to inhibit growth factor stimulated DNA synthesis and fibroblasts. Winstrol has substantial fibrinolytic properties, and has been effective in the treatment of urticaria, Raynaud’s phenomenon, cryptofibrinogenemia, and lipodermatosclerosis. Stanozolol has also effected cures of osteonecrosis in cases resistant to all other therapy. Stanozolol has been used successfully in treatment of AIDS wasting syndrome.',
          'Winstrol is also useful in treatment of hereditary angioedema. It is somewhat hepatotoxic, but less so than many other oral anabolic steroids. It influences some immunological processes. Stanozolol has been found to increase lymphocyte count and CD8+ cell numbers, but to decrease CD4+ and CD3+ in postmenopausal women using it for osteoporosis. This effect would plausibly be useful for treatment of autoimmune disorders.',
      
      ],
      spCategories: [
        'Winstrol also lowers lipoprotein'
      ],
      spCatDescriptions: [
        ['Stanozolol is the chemical name of active ingredient in Winstrol. Winstrol is a registered trademark of Sanofi-Synthelabo Inc. in the United States and/or other countries. Sanofi has licensed rights of Wnstrol to Ovation Pharmaceuticals.']
      
      ],
    },

  {
    spName: 'Arimidex (Anastrozole)',
    spId: 20,
    spMainCategory: 2,
    description: [
        'Arimidex (anastrozole) was the first selective aromatase inhibitor used in bodybuilding, and is still the most popular estrogen control drug. It’s commonly available in both tablet and liquid form for oral use. Dosing is typically 0.25, 0.5, or 1.0 mg at a time, at a frequency of once per day or per two days.',
        'Estradiol, the most potent form of estrogen, is produced in the body by aromatization of testosterone. This occurs via the aromatase enzyme. Because certain levels of estradiol are needed for men, some conversion of testosterone to estradiol is required. However, in two circumstances effective aromatase inhibition can give important benefits.',
        'First, when an anabolic steroid cycle causes very aromatizable steroid levels and there is no control of the aromatase enzyme, conversion to estradiol becomes excessive. The resulting high estradiol can cause gynecomastia, water retention, depression, and/or loss of libido. It may also make it difficult to maintain a lean condition.',
        'And second, some men have excessive estradiol levels even when not on an anabolic steroid cycle. This will act to decrease their natural testosterone production, and can also cause the above adverse side effects.',
        'Arimidex is highly suitable for solving either problem. With correctly chosen dosing, free estradiol level can be brought fairly accurately to a desired range. When not using anabolic steroids, for most men I recommend about 20-29 pg/mL for best effect on mood, performance, libido, and long term health and to provide excellent benefit to natural testosterone production. During a steroid cycle, levels can be allowed to rise a little higher, because high androgen levels act towards countering adverse actions of estradiol. Sometimes levels are deliberately made higher, as being a little “wet” can improve lifting performance. Still, I recommend that even during a strong anabolic cycle, estradiol level be kept to no more than about 40 pg/mL.This will usually require an aromatase inhibitor such as Arimidex, if dosing of aromatizable steroids is high.',
        'The most highly aromatizing steroids are testosterone, methyltestosterone, and Dianabol, but Deca (nandrolone) and Equipoise (boldenone) also aromatize.',
        'Most medical studies of effect of Arimidex on men have used it at 1 mg/day, with this dosage appearing about optimal judging from effect on estradiol level. However, real world use for either steroid cycles or optimization of hormone levels is generally at a lower dosage, from 0.25 mg every other day to about 0.5 mg/day. I generally recommend 0.5 mg every other day as a starting point for a steroid cycle, and half that for hormone optimization if a high estrogen problem exists.',
        'Overdosing of Arimidex leads to abnormally low estradiol level and typical side effects of joint pain, reduced libido, and/or gastrointestinal distress. The first two are directly caused by low estradiol. If experiencing these effects, reduce dose. Effect on the GI tract appears a side effect of Arimidex itself, but one which fortunately affects only a few. If experiencing this effect, I recommend switching to letrozole as an alternate choice of aromatase inibitor.',
        'Anastrozole has a half life of about two days. For this reason, when use is ongoing, on taking a dose of Arimidex the user will have in his system not only the dose that he just took, but also about another two days’ worth that has built up in his system. If just starting usage, however, there is no such buildup and effect will be less.',
        'Rather than waiting a couple of weeks for buildup to occur, I instead recommend frontloading Arimidex. This is done by taking as the first dose not only the regular amount, but also an additional two days’ worth. For example, if intending to take 0.5 mg every other day, then this would be an additional 0.5 mg. The frontloading dose would therefore be 1.0 mg. This would be taken only on the first day of use. With this method, the proper effect is achieved nearly immediately.',
        'A controversial use of Arimidex is in PCT. Some advocate driving estradiol levels abnormally low in PCT in order to stimulate testosterone production; I’m a strong advocate of instead using a SERM such as Nolvadex or Clomid instead. However, if a person needs Arimidex even off-cycle due to his having naturally high aromatization, then his off-cycle dosage may be employed during PCT as well.',
        'Arimidex is best compared with letrozole. Either drug can be used effectively for aromatase control. The choice between them can be made entirely on personal preference, experience, and availability.',
    ],
    spCategories: [
    ],
    spCatDescriptions: [
      
    ],
  },
  {
    spName: 'Aromasin (Exemestane)',
    spId: 21,
    spMainCategory: 2,
    description: [
        'Control of estrogen levels is often necessary in anabolic steroids, due to aromatization of steroids such as testosterone, Dianabol, nandrolone (Deca), or boldenone (Equipoise.) In the aromatization process, the aromatase enzyme converts these androgens to estrogen. If this conversion is excessive, bloat, depression, loss of libido, impaired fat loss, or even gynecomastia can occur. An aromatase inhibitor (AI) is an effective way to solve this problem.',
        'In contrast to Arimidex and letrozole which work by reversibly blocking access to the aromatase enzyme, Aromasin actually inactives individual enzyme molecules when it binds to them.',
        'There is really neither advantage nor disadvantage to this different mode of action. As with all aromatase inhibitors, Aromasin should be dosed to achieve optimal estradiol levels, not the lowest possible levels. When dosed correctly and achieving the same estradiol levels, it doesn’t make a difference what inhibition mechanism was used.',
        
    ],
    spCategories: [
      'How then to choose between aromatase inhibitors?'
    ],
    spCatDescriptions: [
      ['The relevant factors really are only previous experience if any, availability, cost, convenience, and side effect profile.',
      'Not much distinguishes the aromatase inhibitors from each other in terms of side effect profile. I disfavor Aromasin slightly on this account as there’s some evidence of hepatotoxicity, but this is a rare enough side effect that as a matter of personal choice, factors such as personal experience may outweigh it.',
      'Aromasin is usually supplied in 25 mg tablets. Dosing of Aromasin in anabolic steroid cycles is most commonly 12.5 mg every other day, 12.5 mg daily, or at most 25 mg daily.',
      'Excessive dosing will be recognized by estradiol level falling below 20 pg/mL, or by depression, reduction of libido, muscle flatness, and/or joint pain. Experienced users frequently are able to identify a need for dose reduction purely from symptoms. However, blood testing is the gold standard for determining correct dosing level, and for verifying that symptoms actually are caused by low estrogen.',
      'As with other aromatase inhibitors, there’s a relation between the amount of aromatizable steroids used and the dose of inhibitor needed, but it’s not a purely proportional matter. For example, let’s say that a previous cycle a lifter used 250 mg/week of testosterone, along with 750 mg/week of non-aromatizable steroids. And let’s say he found 12.5 mg/day Aromasin to be suitable for that cycle. If he now wishes to use 1000 mg/week of testosterone, which is four times more, most likely he should not multiply his Aromasin dosage by four times. That would be 50 mg/day which is outside of the normal dosage range. I would recommend 25 mg/day as an estimated dose in this instance.',
      'I have never derived exact formulas for adjustment of dose according to change in amount of aromatizable steroids. Most likely there really is no universal formula. As general advice however, make a reasonable estimate which provides some increase to account for increased amount of aromatizable steroids, but without exceeding the recommended range unless blood testing proves that necessary.',
      'Some use Aromasin during PCT, but I’d do this only if blood testing shows elevated estradiol levels. If that’s the case, then Aromasin is to a small extent actually the preferred aromatase inhibitor over Arimidex or letrozole in this instance, because the other two may have some interaction with the metabolism of SERMs, while Aromasin does not.',
      'If used in PCT, dosing should be markedly less than during a cycle, as amount of aromatizable steroid in the blood will be much less. Generally no more than 12.5 mg every other day will be needed, or even only every third or fourth day.',
      'Although mentioned already above, as a closing point I’d like to re-iterate that there really is no issue of the relative strength of differing aromatase inhibitors, and no reason to chose one over another on account of claimed strength differences. Let’s say that a dosing of one aromatase inibitor is stronger – reduces estrogen more – than a dosing of another. If the first one reduced estrogen too much, then the dosing was too many milligrams. It’s not the product was “too strong.” Or, if the second one didn’t reduce estradiol enough, then the dosing was too few milligrams, rather than the product not being strong enough.',
      'All of the aromatase inhibitors will overshoot estrogen reduction if overdosed, or undershoot if underdosed. There is no reason to choose one over another because of what any may say about strength.',
      'Arimidex, letrozole, and Aromasin all are capable of working well and predictably for estrogen control. If already experienced with a given aromatase inhibitor, I recommend continuing with it, as personal dosing is already understood. If you have not yet tried any, then any of them can be effective. I hope the above explanation will assist you in your choice.'],

    ],
  },
  {
    spName: 'Cialis (Tadalifil)',
    spId: 22,
    spMainCategory: 2,
    description: [
        ['Cialis (Tadalifil citrate), a phosphodiesterase type 5 inhibitor, is used to treat erectile dysfunction (ED) and decreased libido in males. Cialis works by aiding relaxation of blood vessels and increasing blood flow in the penis during sexual arousal, resulting in improved erectile function. Cialis is much longer lasting than Viagra. Effects can be felt anywhere between 36-48 hours.'],
    ],
    spCategories: [
      'Background',
      'Action',
      'Technical Data',
      'User Notes'

    ],
    spCatDescriptions: [
      ['The FDA approved Cialis as a medicine for ED on November 21, 2003. It is available through prescription for those who are diagnosed with impotence. In 1998, ICOS Corporation, and Eli Lilly and Company, began manufacturing Cialis, which is available orally in 5mg, 10mg and 20mg tablets. It is also widely available by many research companies and underground labs.'],
      [
        'Tadalafil improves erectile function significantly, possibly increase testosterone through increased sexual activity, and is very safe.',
        'There are limited side effects associated with its use. The most common side effects are headache, upset stomach, back pain, and muscle aches, and usually subside with in a few hours.'
      ],
      [ 
        'In a six month study on men with erectile dysfunction ranging from mild to severe, 20 mgs of Cialis or placebo was administered to subjects as needed. It was discovered that Tadalafil improved ED compared to placebo. Ability to achieve erection and sucessful intercourse was 73% greater in those reciving Cialis verses the placebo group', 
        '1. Cialis was administered three times per week for an extended period of time, it was found to be extremely safe and very limited side effects were experienced, if any',
        '2. Cialis was also linked to increased testosterone levels in a one month study where testosterone levels were found to be significantly higher at the end of the month, due to increased sexual activity'
      ],
      [
        'How ironic. Some of the compounds which can be used to make men more physically attractive to women (Deca, Tren, etc…) can cause sexual dysfunction. Even more ironic is that many of the compounds which make them more attractive to us (Beer, Whiskey, etc…) can also do this.',
        'Cialis is useful for these occasions…or during PCT when sexual dysfunction may occur.',
        'Tadalifil citrate is the chemical name of active ingredient in Cialis. Cialis is a registered trademark of Lilly ICOS LLC in the United States and/or other countries.',
      ],
    ],
  },
  {
    spName: 'Clenbuterol',
    spId: 23,
    spMainCategory: 2,
    description: [
        'Clenbuterol (Spiropent) is a beta-receptor agonist drug used in the treatment of asthma and in bodybuilding for the purpose of fat loss. In some instances, it can also be used to enhance performance in endurance exercise.',
    ],
    spCategories: [
      'When to use clenbuterol',
      'Dosage and cycling of clenbuterol',
      'Mechanism of action of clenbuterol and of similar drugs',
      'Side effects of clenbuterol',
      'Summary',
    ],
    spCatDescriptions: [
      ['Clenbuterol should only be used when fat loss needs to be particularly accelerated over a relatively short period of time. For example, if the need or intent is to lose only 1 lb of fat per week, then there are better means to do this than to use clenbuterol. Where fat loss efforts are already maximized or near-maximized, however, clenbuterol use can add perhaps another 1 lb per week to the rate of loss.'

      ],
      [
        'The effective dose range of clenbuterol extends from 20 mcg/day as a minimum value to 40-140 mcg/day as more common values. I think it best to start at 40 mcg/day and evaluate results on an ongoing basis, increasing dose by 20 mcg at a time as necessary. Many users adjust dosage to the level where the hands begin to shake, but fat loss can be improved markedly with doses well below this point.',
        'Clenbuterol should be cycled rather than used continuously or for prolonged periods of time, but no exact cycling pattern is necessary. Period of use is typically from 2 to 8 weeks.',
        'Periods of use such as 2 weeks or more at doses much in excess of 40 mcg/day results in desensitization. This can be avoided by taking 1 mg ketotifen once per day, preferably prior to retiring.',
        'As clenbuterol clears the body only slowly, a rapid cycling pattern will result in clenbuterol never clearing the system.',
        'To enhance performance in endurance exercise, clenbuterol should be used only at low dose, such as 20 to 40 mcg before an event. Higher doses of clenbuterol may reduce performance.',

      ],
      [
        'Clenbuterol stimulates beta-2 receptors, which in turn increases metabolic rate and enhances lipolysis. Albuterol and salbuterol are other drugs which work similarly; ephedrine also works similarly. Clenbuterol differs from these other compounds by having a long half-life, which can make it more effective for fat loss – as few would choose to take ephedrine directly before going to bed, for example – but also may be responsible for greater tendency to desensitization.',
      ],
      [
        'Clenbuterol use typically gives feelings of “the jitters” and shaking of the hands. Heart rate and blood pressure may be elevated. Scientific literature gives some reason for concern that chronic use might cause cardiac hypertrophy.',
        'The most serious of these concerns are elevated blood pressure and/or heart rate; both these value should be monitored when using clenbuterol.'
      ],
      [
        'Clenbuterol can be a very effective aid to fat loss. A preferred method of administration is to begin use with 40 mcg/day, and increase dosage by 20 mcg/day only as necessary, with dosage not to exceed 140 mcg/day.'
      ],
    ],
  },
  {
    spName: 'Clomid (Clomiphene Citrate)',
    spId: 24,
    spMainCategory: 2,
    description: [
        'Along with Nolvadex, Clomid is one of the two principal SERMs (selective estrogen receptor modulators) used for enhanced recovery of testosterone production after anabolic steroid cycles.',
        'To understand how Clomid can aid this process, let’s look at how natural testosterone production is regulated.',
        'Testosterone production is regulated by a feedback loop which senses not only testosterone or other androgen levels, but also estrogen levels. This feedback loop includes the hypothalamus, the pituitary, and the testes (often referred to as the HPTA, or hypothalamic-pituitary-testicular axis.)',
        'When the hypothalamus senses low estrogen levels and does not sense high androgen levels, it’s stimulated to signal the pituitary by producing more LHRH, which stands for LH releasing hormone. On receiving this signal, the pituitary produces more LH (luteinizing hormone) which in turn signals the testes to produce more testosterone.',
        'During an anabolic steroid cycle, high androgen levels shut this process down entirely. If this is for only a relatively short period such as 8 weeks, this is not a real issue. Over a longer period of time, testicular atrophy might occur, however. That can be avoided with HCG usage. Something that cannot be avoided, however, is that the hypothalamus and pituitary respond not only according to the hormone levels which they sense at a given moment, but are affected in their response by their recent exposure. When that exposure has been an extended period of anabolic steroid use, responsiveness typically is poor even after anabolic steroid use has ceased.',
        'Clomid, as does Nolvadex, works by occupying the binding sites of estrogen receptors of cells, without activating the receptors. This reduces the extent to which estradiol can activate these receptors. In the case of the hypothalamus, this leads to the hypothalamus “concluding” that estrogen levels are low. If androgen levels are not elevated, as indeed they should not be after an anabolic steroid cycle, the hypothalamus is then stimulated to produce LHRH. This will act to increase LH and restart natural testosterone production.',
        'Clomid ordinarily is dosed at 50 mg/day. However, it’s important to note that clomiphene has a long half life. Where this has relevance is that when a daily dose is taken, the body will have not only that dose in it, but also an accumulated amount of about five days’ worth of previous doses as well. That’s fine: it results in correct blood levels. Where there can be a problem is when first starting use. If simply taking 50 mg/day from the beginning, there is no such buildup and levels will be low.',
        'To account for this, 300 mg is taken on the first day, as three doses of 100 mg, or optionally six doses of 50 mg. This immediately gets levels to where they should be. Ongoing 50 mg/day dosing will maintain this level.',
        'After day 1, doses of more than 50 mg are not needed and are not recommended. They will not improve results, but may increase adverse side effects.',
        'Adverse side effects of Clomid can include increased emotionalism or vision disturbance. If vision disturbance is experienced, Clomid should be discontinued immediately.',
        'Clomid rarely leads to libido issues, which can be a problem with Nolvadex. For this reason some prefer it to Nolvadex. Others, who do not have that issue with Nolvadex, may prefer that drug. Both are effective for restoring natural testosterone production. I have a slightly better opinion of Clomid for effectiveness, but where a person dislikes Clomid for emotional effects, or prefers Nolvadex for any reason, Nolvadex is a perfectly acceptable substitute.',
        'Clomid differs from Nolvadex in that while SERMs are always anti-estrogenic in some tissues, they are estrogenic in others. Fortunately, both Clomid and Nolvadex are anti-estrogenic in the hypothalamus, making them useful for post-cycle therapy (PCT), and anti-estrogenic in breast tissue, making them useful as anti-gyno agents. Clomid however is estrogenic in the pituitary, which in some instances may even enhance its value for PCT. It’s likely estrogenic to at least some other neurons in the brain as well, causing increased emotionality. With regard to body fat and muscle, or any observable physical property, Clomid and Nolvadex have no other adverse estrogenic effects, but instead are useful anti-estrogens.',
        'PCT with Clomid should begin as soon as androgen levels have dropped to normal, and continue until confident that natural testosterone production has been fully restored.',
        'Clomid, properly, is a brand name: typically clomiphene citrate products are used, but popularly called Clomid. Clomiphene citrate is widely available both as a pharmaceutical and even more widely as a research chemical, often in liquid form for oral use.',
        'Clomid can and usually should be used as the only SERM in PCT. In most instances, there is no point to combining with another SERM. Where a second SERM such as Nolvadex is combined with Clomid, each should be used at half dose rather than full dose. Using full dose of each does not provide enhanced efficacy and only increases risk of side effects.',
        'Clomiphene citrate is the chemical name of active ingredient in Clomid. Clomid is a registered trademark of Merrell Dow Pharmaceuticals in the United States and/or other countries.',
    ],
    spCategories: [
      ''
    ],
    spCatDescriptions: [
      []
    ],
  },
  {
    spName: 'Dostinex (Cabergoline)',
    spId: 25,
    spMainCategory: 2,
    description: [
        'Dostinex (Cabergoline) is a dopamine agonist. Dopamine is a chemical, found in the brain, which transmits nerve impulses and is involved in the formation of epinephrine. More likely than not, this is why the Life-Extentionistas are very big on this drug. Dopamine is also released by the hypothalamus, and hormone can inhibit the release of prolactin from the anterior lobe of the pituitary, so given all the bad things that we have already seen to be a result of excess. If you use anabolic steroids, Dostinex will help you reduce the chance of any of these prolactin related side-effects. It has actually been shown in numerous studies to have a very high success rate in lowering prolactin and prolactin related conditions and side-effects (1) (2).In fact, for management of hyperprolactinemia and it’s symptoms (got milk?), Dostinex is the preferred treatment in terms of effectiveness as well as having very few undesirable side effects (3). It does this very well for both men and women, it should be noted…almost identically actually (4)',
        'Since it lowers prolactin very efficiently, Dostinex will even get rid sexual dysfunction caused by excess prolactin (5) (which is (anecdotally at least) highly correlative with the use of certain steroids like the Nandrolones and Trenbolones (Deca and Tren). This is great news for everyone who loves Tren and Deca, because those two steroids are really great additions to almost any cycle- but many people avoid using them because of the possibility of them causing impotence (often called “deca dick”).',
        'Using Dostinex will allow you to include steroids like Tren and Deca in any cycle- and even combine them in the same cycle- without worrying about sexual dysfunction. In fact…even if you aren’t experiencing any sort of sexual dysfunction, Dostinex will shorten the time you need to recover and gain an erection between orgasms, and can significantly enhance all parameters of sexual drive and function (6). In other words, if you’re not worried about sexual issues and you take Dostinex anyway…it’ll still help you out in bed. And from what I have heard, it’s well worth the money for that effect.',
        'Of course you can actually use Dostinex safely for an extended amount of time (many studies go on for months if not years, and its efficacy and safety are well documented), but women need to be more careful than men, and certainly need to discontinue using it if they’re pregnant or trying to conceive. SO Dostinex can help you, the average steroid user, by combating gyno-like effects, as well as galactorrhea, and sexual dysfunction. Sounds great, right? Of course it is…but since Dostinex is a dopamine agonist, which means it’s good for a whole lot more.',
        'And it’s this structure which makes it very interesting to us. Dostinex as you already know is what’s known as a dopamine “agonist”- or substance that triggers a response in a specific body tissue or group of cells by binding to specific receptor on or inside the cells, as if it were actually the bodily substance that usually binds to that receptor. Probably the one that most people are familiar with, with regards to agonists is ephedrine, which is an andrenergic agonist. This is why ephedrine makes you feel “wired”…it “feels” like adrenaline to your body. Cabergoline is a dopamine agonist…which makes it “feel” like dopamine to your body.',
        'So what does that mean? Well, in the brain, dopamine helps control the flow of information from other areas of the brain. So a dopamine agonist will help you process information more quickly, and possibly improve your memory also. Some athletes use Dostinex because it helps them learn new motor skills more quickly and thus they can learn new techniques or plays at a faster rate than their competition; needless to say this gives the athletes using Dostinex a huge advantage over their competition. This ability to work on your bodies information pathways and nervous system are doubtless why it’s been successfully been used to fight Parkinsons disease (7)(8).',
        'But does this actually work in real athletes? Well, actually, that’s why I started reading about Dostinex. See, I have the fortune of being able to basically call some of the most famous strength coaches in the world whenever I want. And, recently the last time I spoke to one about training and anabolic steroids, I asked him about different training programs for a person on steroids- and his answer said that it depends on whether that person was taking a nootropics or not. And as you may remember, Dostinex is a nootropic. It was that conversation that made me really take a closer look at Dostinex. And of course, that strength coach told me that his athletes have used nootropics with great success. The down side of knowing internationally renowned strength coaches is that their sense of humor is usually a little off, and if you have the fortune of being able to pick their brains on training, you also invariably have the misfortune of ending up on their group e-mail list which gets you a whole host of bizarre forwarded e-mails…',
        'In fact, when you don’t have enough dopamine, you may even have difficulty concentrating…low dopamine levels have also been cited as a possible underlying cause for Attention deficit disorder (ADD) and Attention deficit/ Hyperactivity disorder (ADHD). In fact, many several medications used to treat ADD and ADHD will also serve to stimulate dopaminergic, and this could be one of their possible mechanisms of action.',
        'Dopamine is also what’s called a “pleasure chemical”…it’s usually released by your body when you experience a rewarding experience such as eating your favorite food, having sex, winning the lottery….whatever. Interestingly, since this “happy” effect is felt when you are satiated from food, it’s highly possible that Dopamine agonists will cause you to feel “full” more often and decrease desire for food without the discomfort that dieting usually brings. Dopamine is released when you eat a nice big meal…so…a dopamine agonist like Dostinex may make you not want to eat as much, and help you feel full even if you don’t eat enough. Dostinex, therefore, may be of great interest to precontest bodybuilders and other dieters, who want to avoid some of the discomfort and anxiety that calorie restriction can bring.',
        'Certain recreational drugs also have a lot to do with their effects on dopamine. Cocaine is what is known as a dopamine transporter blocker; what this means is that it competitively inhibits dopamine uptake to increase the amount of time released dopamine is active in your body. This makes you feel good, while the dopamine is floating around your body. Methamphetamine is another illicit (illegal) recreational drug that acts on dopamine as well. It actually serves to competitively inhibit dopamine uptake as well as increasing dopamine flow through a dopamine transporter pathway. That’s how those drugs make you “feel good.” Dostinex is, of course, neither physically nor mentally addictive, but since it is a dopamine agonist, its users often experience an enhanced positive sense of well being. So besides helping with all of the things discussed earlier, Cabergoline will also just make you feel damn good.',
        'So now that I told you about it, I’ll tell you how much Dostinex do you need to start experiencing these effects…or basically, how I’m going to use it, now that I did all this research on it!',
        'From the reading I’ve done, you only need about half a milligram (1/2mg) a week to experience all of the anti-prolactin, prosexual, antidepressant, and cognitive effects of Dostinex, but that’s on the very low end of the effectiveness scale. This stuff has an extremely long active life in the body, so once a week dosing is fine…but if it were me, and I were taking this stuff, I’d probably be using about .25mgs-.5mgs twice a week.',
        'It should be taken before bed-time, because it may actually help you sleep a bit better, (9), Can be taken with or without food and not alter the pharmacokinetics (how it functions in your body) (10), and (incidentally) according to the literature is a much more efficient drug than Bromocriptine (11).',
        'I think once people find out about this drug, it’s going to find it’s way into quite a few bodybuilders’ cycles alongside Tren, Deca, or both…and athletes are going to take advantage of it’s uses for skill acquisition and motor co-ordination help…and all the other stuff…the prosexual properties and general “feel good” properties of Dostinex make it a great choice for anyone interested in …err…feeling good and having better sex…which I suspect is basically everyone, not just bodybuilders and athletes. I guess I should have paid more attention to this stuff when it started appearing on those Life-Extension club pricelists a decade ago…but at least I figured it out now….even if I happen to be a bit late on this one.',
        'Cabergoline is the chemical name of active ingredient in Dostinex. Dostinex is a registered trademark of Pfizer Inc. in the United States and/or other countries.',
    ],
    spCategories: [
    ],
    spCatDescriptions: [
    ],
  },
  {
    spName: 'Evista (Raloxifene Hydrochloride)',
    spId: 26,
    spMainCategory: 2,
    description: [
        'Evista (raloxifene hydrochloride) is a second-generation Selective Estrogen Receptor Modulator (SERM). It is approved by the FDA for this use in women. Although it bears structural and mechanistic similarities to other SERMs which have been approved either as fertility drugs (Clomid) or Breast Cancer medication (Nolvadex), it has not been approved for either of those uses.',
    ],
    spCategories: [
      'Background',
      'Action',
      'Technical Data',
      'User Notes',

    ],
    spCatDescriptions: [
      [
        'Raloxifene was originally patented in 1983, and released thereafter by the pharmaceutical house Eli Lilly under the brand name Evista.'
      ],
      [
        'Ralixofene exerts its effects by antagonizing the estrogen receptor in some tissues, and agonizing it in others. In this way, certain estrogenic pathways are activated and others are blockaded. It seems to exert estrogenic effects on blood lipids, reducing LDL and total cholesterol, as well as estrogenic effects on bone, improving density. It would also appear to exert anti-estrogenic effects in breast tissue, displacing the traditional effects of estrogen, effectively helping prevent breast cancer in postmenopausal women.',
        'The three-dimensional protein folding around estradiol to activate the receptor complex. Helix 12 must fold across the steroid and seal the ligand-binding pocket. The critical amino acids 538, 542, and 545 in the AF-2 region are exposed to allow binding to coactivators before a transcription complex can be constructed. By contrast, when raloxifene occupies the binding pocket, Helix 12 is reposited and the critical amino acids in the AF-2 region are now masked. Reproduced from Brzozowski et al. (1997)',
      ],
      [
        'Although Raloxifene was only approved for the treatment of osteoperosis in women, it has potential indications in the use of breast cancer prevention and secondary treatment. However, as with most SERMs, it’s possible use has also been explored in men for a variety of conditions.',
        'Unlike Nolvadex which causes a very significant raise in testosterone levels, Raloxifene only causes a 20% increase (1). Thus, for Post Cycle purposes, it probably can not be relied on. For preventing Gynecomasia, it may be as good as other SERMs.',
      ],
      [
        'Raloxifene is a relatively new drug to the performance enhancement scene. As a result very few people have actually experimented with it. The few bodybuilders who have actually used it typically compare it to the more commonplace SERM known as Tamoxifen (Nolvadex). Since it’s relatively new, it’s not widely available to most people and therefore has not yet gotten the widespread acceptance that other similar medications have gotten. However, with respect to this drugs potential, it could be potentially as useful as either Nolvadex or Clomid as an estrogen agonist/antagonist. As a testosterone booster, I have my doubts.',
        'Raloxifene is the chemical name of active ingredient in Evista. Evista is a registered trademark of Eli Lilly and Company in the United States and/or other countries.'

      ],

    ],
  },
  {
    spName: 'HCG (Human Chorionic Gonadrotropin)',
    spId: 27,
    spMainCategory: 2,
    description: [
        'Proper functioning of the testes is of course necessary for normal male health, but may be impaired by anabolic steroid use or by medical conditions. HCG, an injectable drug, is a primary tool for normalizing impaired testicular function.',

    ],
    spCategories: [
      'What Exactly Is HCG?',
      'Pharmaceutical HCG',
      'HCG Dosing For Stimulation Of Testosterone Production',
      'Administration And Storage Of HCG',
      "Preventing Testicular Atrophy And/Or Testicular Sensitivity Loss During Anabolic Steroid Cycles",
      'Using HCG To Maintain Natural Estrogen Levels In Anabolic Steroid Cycles',
      'Using HCG To Correct Testicular Atrophy Or Insensitivity Which Has Already Occurred',
      'Using HCG To Improve Testosterone Production In Cases Of Secondary Hypogonadism',
      'Undesired Increase in Estrogen With HCG Use',
      'For Long Term Use, A Possible Practical Difference Between HCG Types',
      'Summary',









    ],
    spCatDescriptions: [
      [
        'HCG is a natural analog of LH, the pituitary-produced hormone which stimulates sex hormone production in the testes or ovaries. HCG binds and activates the same receptor as LH and is equally effective in stimulating testosterone production in men, or estrogen production in females.',
        'This LH analog exists because it provides additional functions required in pregnancy which LH cannot support. These additional functions cannot occur in men and have no practical effect or consequence with regard to using HCG for improved testicular function, and so are omitted here.',
      ],
      [
        'HCG is typically isolated from the urine of pregnant women: the pharmaceutical process of course involves extensive purification. Despite this esoteric sourcing I know of no quality problems with any genuine pharmaceutical brands of HCG.',
        'All or nearly all practical experience in bodybuilding is with this HCG type, which is generally sold in vials of lyophilized powder, typically at 5000 or 10,000 IU per vial.',
        'HCG may also be produced by recombinant DNA technology, similar to modern hGH production. The Ovidrel brand is manufactured this way, and is available only in preloaded syringes.',
        'Availability of recombinant HCG at best low at present and there is no performance advantage to be experienced from choosing this type.',
        'A technical difference, which makes no difference for bodybuilding, exists between these types of HCG. Namely, recombinant HCG consists only of dimeric HCG with two types of subunits. In contrast, urine-sourced HCG, as with HCG found in the bloodstream during pregnancy, also includes the subunits themselves as monomers. Since it’s possible that with chronic use this could have an outcome difference, that aspect will be discussed, but later on.',
      ],
      [
        'Medical dosing of HCG has traditionally been 5000 IU per injection. Prior to 1998, bodybuilding use for restoration of testicular function was the same. As a result, as this is extreme overdose, it was widely considered to be a harsh drug.',
        'At that time, I introduced lower dosing of no more than 1500 IU per injection, and more preferably no more than 1000 IU, with example recommended usage being 500 IU 3x/week.',
        'Since then my recommendation for maximum injection amount has dropped to 500 IU, because no added benefit has been found to using more than this at a time, provided that injections are reasonably frequent.',
        'The recommended weekly dosing range is from about 700 to 1750 IU. Example dosings are 100-250 IU daily, or 200-500 IU every other day, or 250-500 IU three times per week.',
        'In addition to such dosings being followed by a very large number of individuals over many years with excellent success, scientific study has since validated these dosings. As measured by intratesticular testosterone levels, this dosing level maximizes results. There is simply no point to more.',
        'Multiple injections per week are recommended because the half-life of HCG is only about 36 hours. Less frequent injections result in poor maintenance of blood levels.',
      ],
      [
        'Vials of HCG are first reconstituted with a convenient amount of sterile or preferably bacteriostatic. For example, a 5000 IU vial can conveniently be diluted with 2.5 mL of water. This provides a 2000 IU/mL solution, enabling easy calculation of dosing. For example, a 200 IU dose would then require 0.1 mL of solution, which would be marked “10 IU” on an insulin syringe.',
        'If the vial’s capacity allows, 5.0 mL of water can be added to a 5000 IU vial. The resulting solution would then obviously be 1000 IU/mL, enabling even easier calculation of dosing.',
        'Injection is intramuscular or subcutaneous according to personal preference.',
        'Unreconstituted vials of HCG should be stored in the refrigerator or freezer. They may be shipped at room temperature. Reconstituted vials should always be refrigerated; however, if a vial is accidentally left at room temperature for a day, it will not be ruined.',
        'It is even more important with HCG than with anabolic steroids to employ proper sterile handling of the vial. The septum (top) should always be thoroughly cleaned with alcohol, and the needle must be sterile as well. Aqueous peptide, or in this case glycopeptide solutions can support bacterial growth much more so than oil solutions can, so do not fail with regards to safe handling. Discard if cloudiness appears, or if in doubt.',
      ],
      [
        'Anabolic steroid usage inhibits LH production, which in turn reduces or usually eliminates testicular stimulation. Over short periods of time this is no problem. Over extended periods, however, testicular atrophy and/or insensitivity results. When this occurs, post-cycle recovery of natural testosterone production is impaired or fails.',
        'Prior to 1996, traditional use was to employ HCG post-cycle to correct this problem. Since then it has become a fairly accepted practice to instead prevent the problem from ever occurring. Why deliberately allow one’s testes to shrivel, lose sensitivity to LH, and lose ability to produce testosterone, even if thinking it can be cured later?',
        'That’s poor planning.',
        'So where an anabolic steroid cycle is long enough for testicular atrophy and/or sensitivity to possibly occur – typically but not always, 8 weeks or more – HCG is introduced during the cycle itself to prevent that. Rather little HCG is needed for this purpose: 100 IU daily, 200 IU every other day, or 250 IU 3x/week are all entirely sufficient. Usage need not be through the cycle either: it can begin at for example 4-6 weeks into the cycle and end with the last steroid injection.',
        'The testicular function problem really is that simple to avoid.',
      ],
      [
        'Some anabolic steroid cycles do not include any aromatizing steroids, such as cycles using only trenbolone, Masteron, Primobolan, or oxandrolone. Unless dosing is light on such cycles or duration is very short, estrogen levels fall abnormally low. This interferes with anabolism, libido, mood, joint function, and over the long term, cardiovascular health. An obvious way to solve this problem is to include at least a small amount of an aromatizing steroid, but that won’t always be desired.',
        'Another and quite excellent way is to include HCG, typically at the high end of the suggested dosing range. The resulting natural testosterone production',
      ],
      [
        'In some cases, steroid users allow testicular atrophy to occur and then need to correct it.',
        'In these cases, use of HCG at the high end of the suggested dosage range can restore testicular function within typically 4-8 weeks.',
      ],
      [
        'There are two fundamental causes of low natural testosterone. In primary hypogonadism, the testes themselves cannot produce sufficient natural testosterone despite normal simulation. HCG can do nothing in such cases. Testosterone replacment therapy is the only means of normalizing testosterone levels. In secondary hypogonadism, however, the problem is that not enough LH is produced, with the result that the testes are inadequately stimulated. With proper stimulation, they will produce testosterone at normal levels.',
        'Where the cause of low natural testosterone is secondary hypogonadism, HCG dosed as above can normalize testosterone production. Dosing for at least the first two months should be a the high end of the range. However, before adopting HCG as the solution, it should be determined if the secondary hypogonadism has causes which might be corrected. For example, the cause might be abnormally high estrogen. Such a case would be better treated with an anti-aromatase to correct that problem rather than with HCG to work around it.',
      ],
      [
        'In men, estradiol results principally from enyzmatic conversion (aromatization) of testosterone. Accordingly, increase in testosterone results in increase of estrogen, principally estradiol, if no steps are taken. Testosterone/estradiol ratio is dependent on amount of aromatase enzyme and tends to be characteristic of the invidual, though it can change with time. Men with a normal level of aromatase maintain normal estradiol levels even as testosterone levels increase to high normal from HCG use. However, men with high aromatase levels may experience excessive estradiol levels with increase in testosterone.',
        'Such a problem may be detected with blood testing and corrected by use of an aromatase inhibitor (AI) such as Arimidex or letrozole. Dosing of the AI should be adjusted according to the findings of followup blood testing.',
      ],
      [
        'As mentioned, recombinant HCG contains no monomers of HCG, while ordinary HCG does.',
        'How might this be of any importance? The beta monomer form found in ordinary HCG, but not in recombinant HCG, activates the TGF-beta receptor. I don’t think this activity provides an important risk for bodybuilding use. Women, for example, experience it for 9 months on end in some cases for multiple pregnancies and do not necessarily suffer much from it, and there’s a very extensive track record of safe bodybuilding use. However, TGF-beta receptor stimulation by this HCG monomer has a potential to promote some cancers. (In contrast, intact HCG is protective.)',
        'So, if using HCG for years on end continuously, there might be a slight safety advantage in using recombinant HCG over ordinary HCG. It may be very little advantage, since as mentioned the dimer form also found in ordinary HCG is protective and at least to some extent balances out the beta monomer form. Indeed, ordinary HCG is at the balance which nature provides. Still, if the day comes when recombinant HCG is as easy to obtain as regular HCG, one might prefer it for this reason. It might be an even safer product.',
        'In the meantime, personally I wouldn’t worry about the difference.'
      ],
      ['Intramuscular or subcutaneous injection of HCG at doses of typically 100-200 IU per day, 200-250 IU every other day, or 250 IU three times per week is effective in supporting testosterone production and testicular size and function where the cause of reduction has been reduced LH production. Doses higher than this provide no further advantage.',
      ],

    ],
  },
  {
    spName: 'HGH (Human Growth Hormone)',
    spId: 28,
    spMainCategory: 2,
    description: [
        'by Bill Roberts – Growth hormone, GH, is a peptide produced by the pituitary which enters circulation and works in multiple tissues of the body via the GH receptor. The activated GH receptor provides some effects directly and other effects indirectly by stimulating production of IGF-1. Some increase in GH may be achieved naturally by means of exercise; larger increases can be achieved by injection.',
        'Injectable GH is typically provided as lyophilized powder in vials.',
        'Any injectable GH product should be identical with natural GH, which has 191 amino acids. Some products however are a cheaper analog with 192 amino acids. These products can cause adverse immune response and should not be used.',

    ],
    spCategories: [
      'Desirable Effects of Growth Hormone Use',
      'Possible and Likely Adverse Side Effects of Growth Hormone Use',
      'Pharmacology of Growth Hormone',
      'Pharmacology of IGF-1',
      'Storage, Reconstitution, and Injection of GH',
      'Redness and Swelling in Response to GH Injection?',
      'Dosing of GH in Anabolic Steroid Cycles',
      'Dosing of GH for Quality of Life Improvement',
      'Dosing of GH for Longevity',
      'Cycling of GH',
      'IGF-1 Instead of GH, or with GH?',
      'Why Do Some Combine Insulin with GH in Mass Gain Cycles?',
      'Increase of GH by Administration of Peptides',
      'Summary',

    ],
    spCatDescriptions: [
      [
        'Growth hormone use can increase muscular size, increase metabolism, reduce fat mass, reduce elevated blood pressure, and improve healing, strength, natural testosterone production, recovery from training, sleep, and perceived quality of life. In those experiencing effects of aging, it can improve appearance of skin, reduce or reverse osteoporosis, and appear to partially reverse some signs of aging.',
        'All the above are potentials rather than guarantees.',
      ],
      [
        'Growth hormone can be used with good safety. However, there are possible adverse side effects, particularly with high dosing and especially with chronic high dosing.',
        'GH use can cause insulin resistance, increase serum free fatty acids, and increase TNF-alpha, lipoprotein (a), and fibrinogen. These effects can act towards increasing cardiovascular risk.',
        'Additionally, growth hormone use can cause carpal tunnel syndrome, tarsal tunnel syndrome, peripheral neuropathy, compressive myelopathy, water retention, and sleep apnea. Acne can be promoted. Prolactin can be increased. In women, hirsutism, menstrual irregularities or endometrial hyperplasia can occur.',
        'Of these, the most consistent effect is insulin resistance. This can essentially be guaranteed at sustained higher doses.',
        'Other possible adverse side effects of GH use include increase in skin tags, imbalance in phosphate levels, hypervitaminosis D, promotion of arthritis, and growth promotion of existing cancers or polyps.',
        'Incidentally, while increase in skin tags could seem a minor thing, there’s a strong correlation between having more than 3 skin tags and having increased risk of colon polyps. Gaining skin tags, then, may be a warning sign.',
        'Long term GH use giving levels comparable to those occurring naturally in acromegaly may yield the adverse consequences of that condition. These include diabetes, hypertension, heart disease, heart enlargement, joint thickening, arthritis, coarse facial features, enlargement of hands and feet, thick skin, darkened skin, chronic fatigue, impotence, increased rate of death from cardiovascular disease, increased rate of death from cancer, and a two to four times overall increase in mortality rate. Of course, in acromegaly high GH levels are experienced for years or decades on end, and not every person with the condition suffers all these effects.',
        'With all this said, when used in moderation GH’s track record for safety in bodybuilding is good. Problems usually are limited to worsening of insulin sensitivity and, commonly, carpal tunnel syndrome or other neuropathies which usually are reversible on cessation of use.',
        'Extreme use, especially over an extended period, likely carries significant health harms and risks.',
      ],
      [
        'What does growth hormone itself do at higher doses, exclusive of what may happen from released IGF-1?',
        'In skeletal muscle, GH at supraphysiological doses interferes with insulin signaling, decreases glucose uptake, increases fatty acid uptake, and increases basal rate of lipid oxidation (fat burning).',
        'Overall, the effects promote anabolism and a metabolic shift towards fat-burning over glucose-burning.',
        'At supraphysiological doses, GH also increases lipid storage within muscle cells, which is an adverse effect, yielding to some extent lipotoxicity. (Only adipose cells are capable of storing substantial amounts of lipids without self-harm and metabolic impairment.)',
        'At normal GH levels, the story in muscle is largely the same as above, except that normal GH levels actually support normal insulin signaling, rather than impair it, and do not cause abnormal lipid storage.',
        'In adipose tissue, GH increases lipolysis, decreases lipogenesis, decreases glucose uptake, and decreases local activity of corticosteroids by downregulating 11βHSD1. Some of the increase in lipolysis appears to be from upregulating adipose β2 and β3 receptors.',
        'All this helps in fat loss or to help in partitioning nutrients towards muscle gain. Additionally, the increase in serum free fatty acids produced from increased lipolysis appears to be GH’s mechanism for increasing muscle anabolism. When this increase in free fatty acids is blocked, GH no longer increases muscle anabolism.',
        'In the liver, GH stimulates conversion of glycogen to glucose, impairs insulin sensitivity and thereby reduces glucose uptake, increases uptake of triglycerides, increases storage of triglycerides, and increases production of VLDL (“bad cholesterol.”)',
        'In both muscle tissue and the liver, GH also stimulates production of IGF-1. IGF-1 produced by the liver provides systemic effect; the effect of IGF-1 produced in muscle is thought to be mostly local.',

      ],
      [
        'In skeletal muscle, IGF-1 stimulates glucose uptake, the opposite effect of growth hormone itself. It also promotes protein synthesis, reduces catabolism, decreases breakdown of glycogen, and can increase the number of nuclei per muscle cell by promoting myoblast proliferation and fusion.',
        'That last part is how GH or IGF-1 use can profoundly affect potential for muscular size.',
        'In adipose tissue, IGF-1 has relatively little effect. It does not appear to affect lipolysis or lipogenesis. While IGF-1 alone can aid leanness, this is principally from effect on muscle and overall metabolism, not from direct effect on fat cells.',
        'In the liver, IGF-1 has rather little effect as the liver has few IGF-1 receptors. The liver’s principal involvement with IGF-1 is as a producer of it in response to GH, rather than as a responder to it.',
      ],
      [
        'Unreconstituted growth hormone vials should generally be stored under refrigeration, but may be kept at room temperature for short periods, for example while shipping.',
        'Vials of GH are reconstituted with a convenient amount of bacteriostatic, sterile, or sterile saline water for injection. For example, an 8 IU vial can conveniently be diluted with 0.8 mL or 1.0 mL of water. In the first instance, each 0.1 mL, which is marked “10 IU” on an insulin syringe, will provide 1 IU of GH. This would allow easy calculation for any injection amount.',
        'Other amounts of water, such as 1.0 mL, may also be used.',
        'A problem with using small amounts of water can be that after drawing everything possible from a vial, a substantial percentage of it may remain, causing significant loss of GH. The loss can be about 10%.',
        'One solution is this: Reconstitute your next vial by first adding the needed amount of water to the near-empty vial, then drawing it, and finally adding to the new vial. This will transfer nearly all the remaining GH to the new vial. There is no need to worry about resulting dosing error: over time everything averages out.',
        'If that method does not appeal, another solution is to use a larger amount of water for reconstitution. For example, 2.4 mL of water can be used for an 8 IU vial. Each 0.3 mL of injection then provides 1 IU. And if the last 0.1 mL cannot be drawn from the vial, this is them only about a 4% loss. That’s still a waste, but it’s much reduced compared to reconstituting with less water.',
        'Reconstituted vials should always be refrigerated, although a vial accidentally left at room temperature for a single day need not be discarded, if completely confident about its sterility.',
        'Injection is typically with an insulin syringe, and is intramuscular (IM) or subcutaneous. As personal opinion IM is better as the delivery is a little faster, better matching natural release of GH. Intravenous injection provides even faster delivery of course but as personal opinion is not worth the added trouble. If personally finding it convenient, though, it’s another option.',
      ],
      [
        'Redness and swelling should not occur at a growth hormone injection site. That said, with legitimate, sterile GH a very small percentage of injections will go wrong in some unknown way, temporarily causing these effects. A reason for this could be the needle picking up some skin bacteria in the injection process, despite good procedure. If the redness or swelling subsides, a single such event should cause no suspicion of the product.',
        'If it’s a known-good product yet such responses occur twice in a row, the vial should be discarded. It may have become contaminated.',
        'If it’s a product which is new to you and unproven, then the most likely explanation is that it is a cheap, 192 amino acid product which no one should use. Continued use could result in developing immune response not only to the bad product, but to genuine GH as well.',
      ],
      [
        'For a high degree of improvement in muscle mass and/or fat loss in anabolic steroid cycles, 4 IU per day is a gold standard amount in my opinion. However, not all can tolerate this.',
        'Many need to limit growth hormone dosing to no more than 2 IU per day to avoid serious side effects, particularly carpal tunnel syndrome and other neuropathies. At this use level, dosing is preferably divided, but it’s a fine point. If individual preference calls for injecting GH only once per day, that will work fine.',
        'When using about 4 IU per day, it becomes very preferable to divide it into at least two doses per day. Even further division of the dose may be better yet.',
        'What about going over 4 IU/day? If there’s still room to improve results by increasing anabolic steroid dose, I think this is a safer and better path than taking GH usage beyond this amount. In fact, I recommend first optimizing the anabolic steroid component of a stack before exceeding the 2 IU/day level.',
        'It may also be better, rather than going past this amount of GH, to add IGF-1 to it rather than to add more GH. Not only to avoid further worsening of insulin sensitivity, but for greater effectiveness.',
        'At the other end of the spectrum, GH is sometimes used at lower dosing such as 1 IU/day or 2 IU every other day. Younger lifters will find such a use to be a waste: they will experience little benefit from it. For older lifters, however, such dosing can provide noticeable benefit, as their natural GH production is lower. Reasons to choose low dosing usually would be cost or conservatism rather than intolerance of midrange dosing.',
      ],
      [
        'Where an older person wishes benefits of GH such as improved skin appearance, a leaner set-point, and improved feeling of vitality, a good dosage range is about 7-10 IU per week, with individual doses preferably being about 1-2 IU at a time. Of course some use more, but I’m not convinced that over time this won’t be counterproductive.',
      ],
      [
        'By far the preponderance of evidence is that the reduction in growth hormone that occurs with age actually enhances longevity, and that taking GH in amounts causing supraphysiological levels will more likely shorten life rather than extend it.',
        'I suggest that if wishing to at least maintain the same longevity but with improved quality of life, then certain things should be closely monitored. Serum IGF-1 most likely should be kept within the normal range, and fasting glucose, oral glucose tolerance, lipoprotein(a), fibrinogen, and water retention should be monitored. If none of these are adversely affected at all by the GH administration, there’s reasonable hope I think that expected lifespan won’t be shortened, or if so, only minimally.',
        'If any parameters are adversely affected, my suggestion would be to reduce the dose if longevity is a key goal.',
      ],
      [
        'For younger users, growth hormone use can effectively be reserved for anabolic steroid cycles or for anabolic steroid cycles plus the first few weeks of PCT, with all other weeks of the year being “off.”',
        'For older users, higher dose use of GH for serious anabolic or fat loss results should be done within anabolic steroid cycles, but additional lighter use in some off weeks may be an aid.',
        'For those who do not use anabolic steroids but wish to use GH for enhanced quality of life, I don’t have specific cycling recommendations. As opinion, it is probably better to cycle GH than to use it continually. I would avoid allowing obvious physical decline between cycles. It could be reasonable to alternate moderate-dose use with maintenance low-dose use. This is an unstudied area.',    
      ],
      [
        'Some do obtain good results using IGF-1 without GH, but some don’t. Additionally, IGF-1 administration suppresses natural GH production, and as discussed above, GH has some beneficial anabolic and fat loss actions which IGF-1 does not have.',
        'If considering IGF-1, I recommend supporting it with GH.',
      ]
      [
        'GH impairs insulin sensitivity, which in turn impairs muscle building. Even with this adverse effect there’s still benefit to GH, but not as much as there can be. Injected insulin in a way compensates for impaired insulin sensitivity, but unfortunately it worsens it even more.'
      ],
      [
        'GH may also be increased by administration of GHRP’s such as GHRP-2, GHRP-6, hexarelin, or ipamorelin; or administration of GHRH’s such as Mod GRF 1-29 or CJC-1295.', 
        'Nutritional supplements purporting to increase GH generally do not do so, or don’t do so to a useful extent.',
        'Exercise, proper nutrition, and good sleep improve GH production. Excess bodyfat decreases it – a cruel fact for the overfat, as reduced GH makes fat loss harder.',
      ],
      [
        'Normal growth hormone levels are necessary for normal health. Elevated GH levels can promote anabolism and fat loss, but may have health risks. Typical dosing in bodybuilding is 2-4 IU per day, typically used concurrently with steroid cycles. Higher doses are used by some. Carpal tunnel syndrome and other neuropathies are the most common adverse side effects. Chronic supraphysiological dosing of GH probably shortens lifespan rather than increases it. Moderate GH using can improve quality of life for older persons and judicious use may be neutral in effect on lifespan, while improving its quality. GH levels may be increased by injecting GH itself, or by injecting peptides which increase natural production of GH. For means of doing this, see profiles on GHRP-6 and Mod GRF 1-29.' 
      ],
    
    ],
  },
  {
    spName: 'IGF-1 (Insulin-Like Growth Factor 1)',
    spId: 29,
    spMainCategory: 2,
    description: [
        'IGF-1 or insulin-like growth factor 1 (brand name: Increlex) is a growth factor which is very closely related to insulin. It carries the same amount of amino acids as insulin and responsible for the anabolic reactions to GH. IGF-1 is an important factor in childhood growth and is highly anabolic in adults. It is also known by the brand name Increlex and the generic name mecasermin.',
        ''
    ],
    spCategories: [
      'Background',
      'Action',
      'Technical Data',
      'User Notes',



    ],
    spCatDescriptions: [
      [
        'In the 1970’s, IGF-1 was known as as “Sulfation Factor” and “Nonsuppressible Insulin-like Activity” (NSILA). In the 1980’s, it was known as “Somatomedin C.” The most popular type of IGF-1 available on the Black Market is a longer lasting version (more amino acids in length) known as Long R3 Insulin-like Growth Factor-I or Long R3IGF-I. Lr3igf-1 is more potent than the lesser versions which are no longer available on the black market. Of that type of IGF, there are two types commonly available (Media and Receptor grades, respectively). These last two types of IGF mostly just refer to the purity of what is actually in the bottle.',
      ],
      [
        'IGF-1 is released in the liver and binds to the IGF receptors within the cells, which ultimately causes a stimulation of cell growth (both causing new tissue formation and existing tissue growth) and an inhibition of cell death. It is a highly anabolic and anti-catabolic compound. For the athlete or bodybuilder, this had many positive effects: increased nitrogen retention and protein synthesis because it is highly anabolic. IGF-1 (in the presence of sufficient protein) actually promotes growth of new muscle cells, which increases the overall number of cells in the muscle.',
        'IGF protects the neurons of the brain as well as promotes growth of new motor neurons, making it more possible to rapidly learn new skills during its use. IGF-1 is also responsible in connective tissue production, improves collagen formation and aids in cartilage repair. Similarly, it affects the bones by aiding in bone production and repair.',
    ],
    [
        'In a study done on young adult mice, a compound responsible for increased secretion of IGF-1 in muscle fibers was administered. There was an average increase of 15% in muscle mass and a 14% increase in strength. When the study was then conducted on adult mice, there was a 27% increase in strength in the injected muscles as compared with non-injected muscles. It was also found to prevent aging of the muscles. Muscle mass and muscle fiber growth were similar to the levels found in young adults. These effects are most likely due to the ability of IGF-1 to activate satellite cells, therefore stimulating muscle rejuvenation (1).',
        'In studies conducted where GH and IGF1 were used together, a greater increase of Lean Body Mass and fat reduction was found than by use with each compound alone (2). Researches also believe that use of testosterone would also increase IGF levels in muscle (3). In a 12 week study on subjects using IGF-1, IGF-1+GH, or GH alone subjects in this study, gained around 3kgs of lean mass, and lost around 2kgs of fat(4) .',
        'The complete human IGF-1 Long R3 IGF-1 is 2-3 times more potent than IGF-1 due to less ability to be made inactive by IGF binding proteins (5) (6).',
    ],
    [
        'I’m actually a very big fan of Lr3 IGF-1. For me, I’ve found that it’s had beneficial effects on helping me recover from training injuries and has shown to be very helpful in improving my strength, speed, and performance. I also noted some pretty enhanced muscle building effects and very enhanced fat burning when I’ve been on IGF…nothing on the level of Anabolic Steroids, but still, the effect was very pronounced.',
        'Most users opt for a dose of about 100mcg/day injected bilaterally in the muscle group just trained, immediately post workout.',
        'I suspect that in the coming years, more and more professional athletes will be using IGF, as it is very difficult to test for, and many have switched over from GH to this compound already.',
        'Anecdotally, IGF seems to stack best with Trenbolone and Testosterone, and there’s certainly some synergy between these compounds. Lately, MGF is being added to most IGF protocols.',
    
    ],


    ],
  },
  {
    spName: 'Nolvadex (Tamoxifen Citrate)',
    spId: 30,
    spMainCategory: 2,
    description: [
        'A concern about anabolic steroid use is the resulting suppression of natural testosterone production. During an anabolic steroid cycle itself, this suppression is unavoidable and isn’t necessarily a problem. However, extended post-cycle suppression results in loss of gains and can result in adverse side effects such as depression and loss of libido. In contrast, where recovery of natural testosterone production is rapid, adverse effects on mood or libido can be reduced or eliminated, and retention of gains can be excellent. Post-cycle therapy (PCT) with Nolvadex was introduced specifically to enable faster recovery.',
        'To understand how Nolvadex can speed recovery, it’s important to understand how inhibition occurs, and how it may be reversed by a selective estrogen receptor modulator (SERM) such as Nolvadex.',
        'Testosterone production is regulated in a chain process. The testes produce testosterone according to the amount of LH the pituitary produces. The pituitary produces LH according to the amount of LHRH the hypothalamus produces, as well as other factors. And the hypothalamus produces LHRH according to the current amount of estrogen and androgen in the blood, as well as other factors.',
        'Off-cycle, estradiol will typically be the most important estrogen in this process and testosterone the most important androgen, but in an anabolic steroid cycle, the androgen could be any anabolic steroid.',
        'For the moment, we’re going to assume that in an individual testosterone and estradiol are in a fixed ratio to each other. This usually is approximately true, because estradiol is produced from testosterone. When we look at things this way, then we’ll take it that when testosterone rises or falls, estradiol will rise or fall as well.',
        'In the normal condition – while not using anabolic steroids and being in good health – this process results in a balance where testosterone and estradiol remain in the normal range. If briefly they were to go relatively high for the individual, LHRH and LH production would decrease, reducing testosterone production and normalizing the levels.',
        'It’s also the case that if estradiol level is low – or more precisely is activity at the estrogen receptor is low – the hypothalamus will produce more LHRH in response. This gives more LH, and more testosterone.',
        'What happens in an anabolic steroid cycle? Here, the hypothalamus will always sense abnormally high androgen, and may sense abnormally high estrogen as well. It therefore shuts down LH production, so testosterone production shuts down as well.',
        'Again, that’s inevitably going to happen, and in and of itself doesn’t have to be a problem.',
        'But what about post-cycle? After levels of injected or oral androgen have dropped, shouldn’t LH production promptly resume? Androgenic inhibition will have ended.',
        'Unfortunately, often that won’t happen. As mentioned, besides the current androgen and estrogen levels there are other factors involved in the regulation of LHRH and LH production. The androgen and estrogen levels of preceding weeks are important as well. After the exposures involved in a steroid cycle, androgen and estrogen levels falling back to normal may not by itself be enough for LH production to restart, even if estradiol levels are normal.',
        'Now – finally! – is where Nolvadex comes in.',
        'By occupying the binding site of estrogen receptors of a cell without activating them, Nolvadex prevents these receptors from being activated by estradiol. The cell then “thinks” that estradiol levels are very low, and responds accordingly.',
        'In the case of the hypothalamus, it then produces more LHRH in response to apparently very low estrogen. This stimulates the pituitary to produce LH, which in turn stimulates the testes, restoring testosterone production.',
        'There are several proven PCT dosing protocols for Nolvadex.',
        'All of the good protocols first use a higher dose, and then an ongoing lower dose of 20 mg/day. The reason for this is that when taking the drug, the amount in one’s system isn’t simply the amount just taken, but also a buildup of about six days’ worth from previous dosings. At the start of usage that buildup isn’t there, and neither will the efficacy unless this is accounted for. If not accounted for, it takes weeks for levels to build up.',
        'One method of correcting for this is to take a total of 120 mg on the first day, as three doses of 40 mg. This promptly gets levels to about the same as would eventually be arrived at with 20 mg/day dosing. After this, dosing is the standard 20 mg/day.',
        'Another method to quickly obtain proper levels is to use double dosing for a limited time. I recommend only four days of it, as that is all that is needed, but many authors recommend two weeks. (This however overshoots the levels that result from ongoing 20 mg/day use.)',
        'Dosing should continue until confident that natural testosterone production has been fully restored. It’s reasonable to plan for 30 days’ use, but more or less may be needed.',
        'Please do realize that using more Nolvadex than the above does not give better results. Thee is absolutely no reason to use more than I’ve recommended. Doing so can only worsen side effects.',
        'Side effects even at correct dosing can include vision disturbance and reduction of libido. If vision disturbance is experienced, Nolvadex use should be discontinued immediately and an anti-aromatase such as Arimidex or letrozole should be used instead.',
        'If libido is reduced, the problem is only temporary. On future occasions, Clomid might be tried as an alternate SERM, because it can be more favorable in this regard.',
        'There is generally no reason to combine SERMs: for example, generally Clomid or Nolvadex should be used as the sole SERM, rather than in combination with each other. However, in some difficult cases it can be beneficial to use both Clomid and Nolvadex simultaneously, but at half-doses of each. While at the hypothalamus there is likely no difference between Clomid alone, Nolvadex alone, or both together at half dose, at the pituitary Clomid and Nolvadex work oppositely, so the combination differs from either alone. (It is from Dr. Scally that I learned the benefit of combining in some instances.)',
        'Prior to the advent of affordable anti-aromatases, Nolvadex was also popular as an anti-gynecomastia agent. Today, it’s best to use an anti-aromatase as a preventative, but if gyno symptoms flare up during a cycle, immediate treatment with Nolvadex can be helpful. Dosing for this use is as with PCT.',
        'Tamoxifen citrate is the chemical name of active ingredient in Nolvadex. Nolvadex is a registered trademark of AstraZeneca UK Limited in the United States and/or other countries.',
    ],
    spCategories: [
      ''
    ],
    spCatDescriptions: [
     
    ],
  },
  {
    spName: 'Proscar (Finasteride)',
    spId: 31,
    spMainCategory: 2,
    description: [
        'Propecia (finasteride) is used to treat male pattern baldness (androgenic alopecia) in men. [Finasteride is also used in combination with doxazosin in the treatment of benign prostatic hyperplasia (BPH) in a version marketed as Proscar.] Finasteride is an orally active testosterone Type II 5-alpha reductase inhibitor, inhibiting the enzyme that converts testosterone to dihydrotestosterone (DHT). Topically (on the scalp itself) it is of some effect in minimizing further hair loss. In combination with Nizoral and spironolactone (which smells awful, by the way), it can actually reverse loss moderately.',
        'Oral use of it though will reduce DHT levels systemically, which may adversely affect training and sex drive.',
        'For use in cycles with testosterone, I don’t think it is bad at all. One simply doesn’t want less DHT than normal. As the amount of testosterone in the system increases, the amount of finasteride needed to keep levels down to normal increases. I consider 5 mg/day reasonable at the gram per week level, and proportionally less at lower dosages of testosterone. Proscar comes in 5-milligram pills and is considerably less expensive than the 1-milligram Propecia pills.',
        'I personally wouldn’t use more than the recommended pharmaceutical dosage, or 5 mg/day. More might not be safe and might at some point cause DHT levels to become abnormally low.',
        'Finasteride is the chemical name of active ingredient in Proscar and Propecia. Proscar and Propecia are registered trademarks of Merck & Co. Inc. in the United States and/or other countries.',

    ],
    spCategories: [
      ''
    ],
    spCatDescriptions: [
    
    ],
  },
  {
    spName: 'TB-500',
    spId: 32,
    spMainCategory: 2,
    description: [
        'TB-500 is an injectable peptide drug which can be used to promote healing, enhance range of motion in cases of injury, or reduce pain in case of injury by reducing inflammation. As yet its human use has been relatively uncommon and limited to individuals who like to experiment. Use in race horses has been more widespread, though it’s not a prescription veterinary drug.',
    ],
    spCategories: [
      'When to use TB-500',
      'Possible increased effect from combining TB-500 with GH, or a GHRP',
      'How to use TB-500',
      'Pharmacological class of TB-500',
      'Other activities of TB-500',
      'Conclusion'
    ],
    spCatDescriptions: [
      [
        'I’d recommend considering TB-500 in cases of acute injury where, as judged from experience, healing might otherwise be slow, or in cases of chronic injury where healing otherwise simply isn’t occurring.',
        'The types of injury that are aided include tendinitis and muscle tears or strains, but may include many types of muscle and connective tissue injury, as well as skin injury. Where muscle injury results in impaired flexibility, TB-500 can provide substantial improvement. With regard to racing horses, it’s claimed to prevent the formation of adhesions. Whether this is true in humans or even horses I cannot say, but there’s substantial indication that it helps treat existing adhesions.',
      ],
      [
        'While TB-500 can be remarkably effective administered alone, at least some persons will experience better results with TB-500 taken together with GH than with TB-500 alone.',
        'Alternately, compared to using TB-500 alone, healing effect can be improved by combining TB-500 with a GHRP (such as GHRP-2, GHRP-6, ipamorelin, or hexarelin), or a GHRP / Mod GRF stack.',
        'Of those two approaches, from results I presently believe the TB-500 / GH stack to be the more effective.',
      ],
      [
        'TB-500 is typically provided as lyophilized (freeze-dried) powder in vials of 2.0 mg. A convenient amount of sterile or bacteriostatic water will be added, such as 1.0 mL. In the most common dosing protocol, the entire vial is taken at one time. If taking the entire vial, the entire amount will be drawn into a syringe, typically an insulin syringe, and injected. Injection may be subcutaneous, intramuscular, or intravenous, according to personal preference',
        'The most common dosing protocol is to take 2.0 or 2.5 mg of TB-500 twice per week for four to six weeks, and then reduce to a lower dosing rate such as once or twice per month for maintenance.',
        'The body of knowledge is as yet limited with regard to TB-500 dosage. The above protocol may or may not be the most efficient, but it is the protocol with the most track record. It’s entirely possible that as knowledge is gained, method of use will change. A reasonable possibility, for example, is to increase dosing frequency to 3x/week (which I have done personally to good effect), or to increase dosing to 4 or 5 mg twice per week. Before going to such a program, however, I recommend trying the standard protocol first.',
        'If interested in animal uses, a typical dosing protocol for horses is a course of six 10 mg injections at 7-10 day intervals. For more intensive treatment, 20 mg is used weekly. For racing dogs, a typical protocol is a course of six 5 mg injections taken weekly.'
      ],
      [
        'TB-500 is a short peptide segment or fragment of the naturally-occurring thymosin beta-4 hormone (TB-4.) To be clear, TB-500 most emphatically is not TB-4, although it’s often sold under that name. TB-4 itself is as yet an extremely pricey, rare research material that is not yet available to bodybuilding or to equine enhancement.',
        'In the body, TB-4 is produced is the thymus, a gland which is at its largest in children, atrophies as adulthood progresses, and is virtually non-existent in the elderly. It is also produced locally in various cells, and is particularly found in wound fluids, as well as in fairly high concentration in the cytoplasm of some cells. In various studies, TB-4 has been found able to promote various forms of wound healing, to promote differentiation of stem cells, and to reduce inflammation.',
        'Protein hormones such as TB-4 do not bind in their entirety to a receptor site, as they are too large to fit. Instead, an active region of the protein fits into receptor sites.',
        'The TB-500 peptide sequence is identical with the active region area of TB-4 and shares many and possibly all of the properties of TB-4, in a form which is more economical to produce.',
      ],
      [
        'TB-500 has been known to promote at least partial regrowth of hair which has been lost to male-pattern baldness, to darken at least a portion of hair which has grayed, and is reported to enhance muscle mass in horses. The latter effect may be due to promoting differentiation of satellite cells. Bodybuilders who have used TB-500 for healing purposes have however generally not reported such an effect. However, it might be that the effect occurred, but was attributed to other causes, or that effect did not occur because TB-500 is generally used only when injured, which tends not to be a time of training for best gains.',
        'I wouldn’t use TB-500 for the purpose of mass gains, but while using it would keep in mind that uninjured body parts might obtain a benefit from its use, and would train uninjured weak body parts especially hard while doing a TB-500 course, on the chance of benefit. In my personal use, it does seem likely there was some such effect, without having had the knowledge beforehand of muscle gain in horses. I had no expectation of muscle size or “quality” benefit at all.',
      ],
      [
        'TB-500 is a peptide which can be quite beneficial for healing of injuries, both acute and chronic, and for treatment of tendinitis. It can also improve flexibility where this has been impaired by injury, and in some cases has partially regrown lost hair. The ordinary course of treatment is 2.0 or 2.5 mg taken by injection twice per week for 4-6 weeks, followed by monthly or twice-monthly injection of 2.0 or 2.5 mg as needed.'
      ],
    ],
  },
  {
    spName: 'Viagra (Sildenafil Citrate)',
    spId: 33,
    spMainCategory: 2,
    description: [
        'Viagra (Sildenafil citrate) is a drug used to treat erectile dysfunction (impotence) in males and pulmonary arterial hypertension (PAH).',
    ],
    spCategories: [
      'Background',
      'Action',
      'Technical Data',

    ],
    spCatDescriptions: [
      [
        'The pharmaceutical company, Pfizer, originally developed Viagra. In 1006, the FDA approved Viagra as the first drug patented for use of erectile dysfunction in the United States. They are blue, diamond-shaped pills with “Pfizer” marked on one side and “VGR XX.” The XX is the dosage, which is either 25,50, or 100 mgs. Viagra is also sold under the brand name Revatio and generically under various other names. It is also widely availabe by many research companies and underground labs.',
      ],
      [
        'Viagra treats erectile dys function and works by relaxing muscles and increases blood flow to particular areas of the body (in this case, the penis, specifically). Its effects last up to four hours. The most common side effects of Viagra are headache, facial flushing, and upset stomach. Rarely, blurred vision, or sensitivity to light may briefly occur.',
        'As well as treating impotence, Sildenafil Citrate can also be effective in treating Pulmonary Arterial Hypertension (PAH). Similarly to how it acts to relax muscles of the penis, it relaxes arterial walls; therefore, reducing resistance and pressure in the arteries.',
        'Sildenafil Citrate is solely active in relaxing muscles and increasing blood flow to the heart, lungs and penis due to PDE-5, yet vasodilation is not increased in other parts of the body.',
      ],
      [
        'In studies where Viagra was administered to over 3700 patients, 19-87 years old in world wide clinical trials. More than 550 patients received treatment post 1 year. 2.5% of the Viagra subjects and 2.3% of the placebo subjects reported adverse effects, which were all considered mild. These reported side effects included abnormal or blurred vision and increased sensitivity to light (1). All other negative reactions reported, including URI, flu symptoms, back pain and arthralgia, occurred at a rate of less than 2% and equally common in the placebo and Viagra administered groups (1). Effects worsened and were reported more frequently as dosages increased (1).',
        'Other studies measured duration of sexually stimulated erection using a Rigiscan device. Duration of rigidity was increased by 60% with Viagra use (2).',
        'In 16 separate 4-24 week trials of men (over 18 years old) who suffered from impotence for longer than 6 months, dosages between 10-200 mgs were administered (3). Significant improvements in erectile function, including frequency of penetration as well as erection duration post penetration, were reported with Sildenfil treatment compared to the placebo group (4). Erections were improved in 72% of the Viagra group verses 18% of the placebo group (5). In all cases effects were dependant upon dosage and improvement was the greatest when dosage exceeded 100 mgs (6). Responses were decreased by 40-60% in patients suffering from diabetes and prostatectomy, respectively (7)(8). Withdrawal rates were less than 5% related to negative side effects and limited response rates (9).',
        'Sildenafil citrate is the chemical name of active ingredient in Viagra. Viagra is a registered trademark of Pfizer Inc. in the United States and/or other countries.',

      ]

    ],
  },
];

export default steroidProfilesData;