import vileOils from '../../images/vile v2.png'
import pillBottleOrals from '../../images/pill container v2.png'


const prodCategories = [
  {
    categoryId: 1,
    name: "Oils",
    img: vileOils,
    hasSubCat: true,
  },
  {
    categoryId: 2,
    name: "Orals",
    img: pillBottleOrals,
    hasSubCat: true,
  },
  // {
  //   categoryId: 3,
  //   name: "HGH",
  //   img: vileOils,
  //   hasSubCat: false,
  // },
  // {
  //   categoryId: 4,
  //   name: "HCG",
  //   img: vileOils,
  //   hasSubCat: false,
  // },
  {
    categoryId: 5,
    name: "Peptides",
    img: vileOils,
    hasSubCat: false,
  }
]

export default prodCategories;