

const allTestReports = [
  {
    name: "Test C (200mg - 201.94mg)",
    reportLink: "https://janoshik.com/tests/51760-Testosterone_Cypionate_200mg_G5Z3VBFTP6XL",
    within10: true,
  },
  {
    name: "Test C (400mg - 322.44mg)",
    reportLink: "https://janoshik.com/tests/51761-Testosterone_Cypionate_400mg_DULX2KG48DT2",
    within10: false,
  },
  {
    name: "Test No Ester (100mg - 95.69mg)",
    reportLink: "https://janoshik.com/tests/51762-Testosterone_No_Ester_100mg_765D97QHG3PN",
    within10: true,
  },
  {
    name: "Test E (500mg - 462.47mg",
    reportLink: "https://janoshik.com/tests/51763-Testosterone_Enanthate_500mg_8NJC8BBEPUU6",
    within10: true,
  },
  {
    name: "Test E (250mg - 253.95mg)",
    reportLink: "https://janoshik.com/tests/51765-Testosterone_Enanthate_250mg_YEZ4FD8JZQ3M",
    within10: true,
  },
  {
    name: "Test U (250mg - 260.59mg)",
    reportLink: "https://janoshik.com/tests/51766-Testosterone_Undecanoate_250mg_12DI8JEMC46K",
    within10: true,
  },
  {
    name: "Test P (200mg - 202.82mg)",
    reportLink: "https://janoshik.com/tests/51767-Testosterone_Propionate_200mg_6KUBXLQA9ISS",
    within10: true,
  },
  {
    name: "Test P (100mg - 100.21mg)",
    reportLink: "https://janoshik.com/tests/51768-Testosterone_Propionate_100mg_HCZ9VKXV6JFS",
    within10: true,
  },
  {
    name: "Tren E (200mg - 201.30mg)",
    reportLink: "https://janoshik.com/tests/51773-Trenbolone_Enanthate_200mg_CHRY7A9TMA2N",
    within10: true,
  },
  {
    name: "Tren E (400mg - 387.09mg)",
    reportLink: "https://janoshik.com/tests/51774-Trenbolone_Enanthate_400mg_K1E5FCVDXAMR",
    within10: true,
  },
  {
    name: "Tren A (100mg - 100.46mg)",
    reportLink: "https://janoshik.com/tests/51776-Trenbolone_Acetate_100mg_ARX1U414BRF3",
    within10: true,
  },
  {
    name: "Tren A (200mg - 189.70mg)",
    reportLink: "https://janoshik.com/tests/51778-Trenbolone_Acetate_200mg_1VAD34E877PP",
    within10: true,
  },
  {
    name: "Mast E (200mg - 199.22mg)",
    reportLink: "https://janoshik.com/tests/51769-Masteron_Enanthate_200mg_JU88G2UT2Q75",
    within10: true,
  },
  {
    name: "Mast E (400mg - 366.72mg)",
    reportLink: "https://janoshik.com/tests/51772-Masteron_Enanthate_400mg_9Y6QSDFKY52S",
    within10: true,
  },
  {
    name: "Mast P (200mg - 205.47mg)",
    reportLink: "https://janoshik.com/tests/51770-Masteron_Propionate_200mg_H3DYLMU9I9P2",
    within10: true,
  },
  {
    name: "Mast P (100mg - 97.18mg)",
    reportLink: "https://janoshik.com/tests/51771-Masteron_Propionate_100mg_LYJP38EYKXB5",
    within10: true,
  },
  // {
  //   name: "Methyl Tren (2mg - 2.07mg)",
  //   reportLink: "https://janoshik.com/tests/51775-Methyl_Trenbolone_2mg_FQB6XL6RSX8U",
  //   within10: false,
  // },
  {
    name: "Decadurabolin (400mg - 395.53mg)",
    reportLink: "https://janoshik.com/tests/51779-Decadurabolin_400mg_FKSDLZHDXPNR",
    within10: true,
  },
  {
    name: "Decadurabolin (200mg - 196.90mg)",
    reportLink: "https://janoshik.com/tests/51780-Decaduraboloin_200mg_SHYKWJC2YCTS",
    within10: true,
  },
  {
    name: "Decadurabolin (250mg - 247.11mg)",
    reportLink: "https://janoshik.com/tests/51781-Decaduraboloin_250mg_27P35NBRFC48",
    within10: true,
  },
  {
    name: "NPP (200mg - 132.93mg)",
    reportLink: "https://janoshik.com/tests/51782-NPP_200mg_6NNSELKVSAF2",
    within10: false,
  },
  {
    name: "NPP (100mg - 97.71mg)",
    reportLink: "https://janoshik.com/tests/51783-NPP_100mg_B23FPMXJU2CZ",
    within10: true,
  },
  {
    name: "EQ (300mg - 301.92mg)",
    reportLink: "https://janoshik.com/tests/51784-EQ_300mg_83K2DIGW57EW",
    within10: true,
  },
  {
    name: "EQ (500mg - 459.57mg)",
    reportLink: "https://janoshik.com/tests/51785-EQ_500mg_QW6CJ223VHSG",
    within10: true,
  },
  {
    name: "Test Sustanon (250mg - 275.10mg)",
    reportLink: "https://janoshik.com/tests/51764-Testosterone_Sustanon_250mg_ECHVY2637KMQ",
    within10: false,
  },
  {
    name: "Primo E (100mg - 94.30mg)",
    reportLink: "https://janoshik.com/tests/51786-Primo_E_100mg_G1AITZ7S27ZB",
    within10: true,
  },
  {
    name: "Primo E (200mg - 195.13mg)",
    reportLink: "https://janoshik.com/tests/51787-Primo_E_200mg_E2LRHH7CFJNM",
    within10: true,
  }, 
  {
    name: "Injectable Dbol (25mg - 27.85mg)",
    reportLink: "https://janoshik.com/tests/51796-Injectable_Dbol_25mg_1V47K4R6PGVR",
    within10: false,
  }, 
  {
    name: "Injectable Dbol (50mg - 49.11mg)",
    reportLink: "https://janoshik.com/tests/51794-Injecatable_Dbol_50mg_192CL58RQFGQ",
    within10: true,
  },
  {
    name: "Injectable Anadrol (25mg - 24.69mg)",
    reportLink: "https://janoshik.com/tests/51793-Injectable_Anadrol_25mg_VGWY41J9XYUV",
    within10: true,
  },
  {
    name: "Injectable Anadrol (50mg - 47.23mg)",
    reportLink: "https://janoshik.com/tests/51792-Injectable_Anandrol_50mg_I93CPCGNFWD2",
    within10: true,
  },
  {
    name: "DHB (100mg - 101.77mg)",
    reportLink: "https://janoshik.com/tests/51790-DHB_100mg_X3NZRMRHTE8U",
    within10: true,
  },
  {
    name: "Injectable Winstrol (30mg - 29.05mg)",
    reportLink: "https://janoshik.com/tests/51795-Injectable_Winstrol_30mg_FIYQGUISXLRG",
    within10: true,
  },
  {
    name: "Injectable Superdrol (10mg - 12.89mg)",
    reportLink: "https://janoshik.com/tests/51791-Injectable_Superdrol_10mg_LDIZF7SNKK1Z",
    within10: false,
  },
  {
    name: "Trestolone Ment (100mg - 95.89mg)",
    reportLink: "https://janoshik.com/tests/51789-Trestolone_Ment_100mg_VWVG4C3BXWC6",
    within10: true,
  },
  {
    name: "Trestolone Ment (30mg - 30.08mg)",
    reportLink: "https://janoshik.com/tests/51788-Trestolone_Ment_30mg_9VHKLB9PJ5LM",
    within10: true,
  },
];

export default allTestReports;