import React, { useState } from "react";
import './store.css';
import { Link } from "react-router-dom";

import prodSubCategories from "../../Comps/Products/productSubCategories";
import prodCategories from "../../Comps/Products/productCategories";

import scienceImg from '../../images/homepage/IMG_4126.jpeg'
import scienceImg2 from '../../images/homepage/IMG_4125.jpeg'

const Store = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="main-store-div">
      <div className="category-side-pics-1">
       <img src={scienceImg} alt="scienceImg"/>
      </div>
      <div className="product-categories">
        <h1>CATEGORIES</h1>
        {prodCategories.map((item, index) => (
          item.hasSubCat ? (
            <div className="prod-with-sub" key={item.categoryId}>
              <div onClick={() => toggleOpen(index)} style={{ cursor: 'pointer' }} className="prod-full">
                <div className="products-cat">
                  <img src={item.img} alt={item.name} />
                  <div>
                    <h4>{item.name}
                      <span>
                        {openIndex === index ? '▲' : '▼'}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              {openIndex === index && (
                <div className="subcategories">
                  {prodSubCategories.filter(sub => sub.mainCategory === item.categoryId).map(sub => (
                    <Link key={sub.subCatId} className="subcategory" to={('/store/' + sub.subName).toLowerCase()}>
                      {sub.subName.toLocaleUpperCase()}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="prod-full" key={item.categoryId}>
              <Link className="products-cat" to={('/store/' + item.name).toLowerCase()}>
                <img src={item.img} alt={item.name} />
                <div>
                  <h4>{item.name}</h4>
                </div>
              </Link>
            </div>
          )
        ))}
      </div>
      <div  className="category-side-pics-2">
        <img src={scienceImg2} alt="scienceImg2"/>
      </div>
    </div>
  );
};

export default Store;
