import React, { useEffect, useState } from "react";
import './ethPayment.css';
import { Link, useParams } from "react-router-dom";
// import qrCode from "../../../images/eth-qr-code-dynamic.png";
// import btcQrCode from "../../../images/btc-qr-code-dynamic3.png"
import qrCode from "../../../images/eth-qr-code-dynamic2.png";
import btcQrCode from "../../../images/btc-qr-code-dynamic3.1.png"

import QRCode, { QRCodeSVG } from 'qrcode.react'

const QRCodeGenerator = (data) => {
  console.log('cost', `ethereum:${data.pmtAddress}?value=${data.costInCrypto * 1000000000000000000}`)
  return (
    <div>
      <div>
        {data.pmtMethod === 'eth' ? 
        <QRCodeSVG id="pay-eth-qr-code" value={`ethereum:${data.pmtAddress}?value=${data.costInCrypto * 1000000000000000000}`}/>
        :
        data.pmtMethod === 'btc' ?
        <QRCodeSVG id="pay-eth-qr-code" value={`bitcoin:${data.pmtAddress}?amount=${data.costInCrypto}`}/>
        : ''}
      </div>
    </div>
  )
}



const InfoPopupModal = ({ onClose, clearLocalOrder, changeOpenMoreInfo }) => {
  

  return (
    <div className="info-modal-overlay">
      <div className="info-modal-content">

        <div className="info-modal-inner-div">
          <div className="info-modal-inner-div-upper">
            <h1>INFO</h1>
            <div className="bottom-pmt-eth-div">
              <p className="pmt-disclosure-p">Payment may take <span className="pmt-disclosure-span-ul">up to 48 hours</span> to settle. <span className="pmt-disclosure-span-bul">Your 
                funds are safe!</span> Once payment is verified you will receive an email for more information. <Link to={'mailto:Olympicpharma@proton.me'} className="pmt-disclosure-span-ul">Contact 
                  us if you have any questions!</Link>
              </p>
            </div>
            <hr className="info-modal-hrs"/>
          </div>
          <div className="info-modal-inner-div-lower">
            <div>
              <h2>Just Paid?</h2>
              <Link to='/order-status' className="pmt-bottom-btns-link">
                <button className="pmt-bottom-btns pulse-pmt-border">Check Order Status</button>
              </Link>
            </div>
            <hr/>
            <div>
              <h2>Have a different order?</h2>
              <button className="pmt-bottom-btns" onClick={() => clearLocalOrder()}>Clear Order Number</button>
            </div>
          </div>
        </div>

        <button
          id="info-modal-close-btn"
          className="close-modal"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  )
}

const EthPayment = () => {
  const { pmtMethod } = useParams();
  const [orderNumber, setOrderNumber] = useState(localStorage.getItem('jhlltd-order-num'));
  const [paymentMethod, changePmtMethod] = useState(localStorage.getItem('jhlltd-order-num-pmt'))
  const [costInGivenCrypto, setCostInGivenCrypto] = useState(null);
  const [costInUSD, setCostInUSD] = useState(null);
  const ethScAddress = "0x2366F4B9473951179bb09e2412d978e0B5efcD90";
  // const btcAddress = "bc1qdakelmmp7qnsz0y36k22zsk6fl5eeqf68r2f8l";
  const btcAddress = "bc1qjrq3lq7aluvx8paygrvfk0swefgzvhcpffwkta"
  const [openMoreInfo, changeOpenMoreInfo] = useState(false)
  
  const [copyState, changeCopyState] = useState(false);

  const handleCopyAddy = async () => {
    try {
      navigator.clipboard.writeText(pmtMethod === 'eth' ? ethScAddress : pmtMethod === 'btc' ? btcAddress : "");
      changeCopyState(true);
      setTimeout(() => {
        changeCopyState(false);
      }, 4000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const clearLocalOrder = () => {
    setOrderNumber('')
    changePmtMethod('')
    localStorage.removeItem('jhlltd-order-num')
    localStorage.removeItem('jhlltd-order-num-pmt')
    changeOpenMoreInfo(false)
  }

  const setLocalOrder = async (event) => {
    event.preventDefault();
    const orderNumber = event.target.elements.orderNumber.value;
    
      const response = await fetch(`/api/payment-method/${orderNumber}`)
      const data = await response.json();
      // console.log(data)
      if (data.error) {
        console.error('Not a correct order number')
        alert('Not a Correct Order Number')
      } else {
        if (data === "Ethereum") {
          localStorage.setItem('jhlltd-order-num-pmt', 'eth');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
          changePmtMethod('eth')
            window.location.replace('/cart/checkout/eth');
        } else if (data === "Bitcoin") {
          localStorage.setItem('jhlltd-order-num-pmt', 'btc');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
          changePmtMethod('btc')
            window.location.replace('/cart/checkout/btc');
        }
      }
  };  
  

  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;

  useEffect(() => {
    const fetchCostInGivenCrypto = async () => {
      try {
        const response = await fetch(`/api/cost-in-crypto/${orderNumber}`);
        if (response.headers.get('content-type').includes('application/json')) {
          const data = await response.json();
          if (data.error) {
            setCostInGivenCrypto({ error: data.error });
          } else {
            setCostInGivenCrypto(data);
          }
        } else {
          throw new Error('Received non-JSON response');
        }
      } catch (error) {
        console.error('Error fetching cost in crypto:', error);
        setCostInUSD({ error: 'Failed to fetch cost in crypto' });
      }
    };
  
    const fetchCostInUSD = async () => {
  try {
    const response = await fetch(`/api/cost-in-usd/${orderNumber}`);
    // console.log('response', response)
    if (response.headers.get('content-type').includes('application/json')) {
      const data = await response.json();
      // console.log('data', data)
      if (data.error) {
        setCostInUSD({ error: data.error });
      } else {
        setCostInUSD(data);
      }
    } else {
      throw new Error('Received non-JSON response');
    }
  } catch (error) {
    console.error('Error fetching cost in USD:', error);
    setCostInUSD({ error: 'Failed to fetch cost in USD' });
  }
};

  
    fetchCostInGivenCrypto();
    fetchCostInUSD();
  }, [orderNumber, formattedDate]);
  
  

  return (
    <div className="eth-pmt-main">
      {orderNumber && paymentMethod === pmtMethod ? (
        <div className="order-pay-info-1">
          <div>
            <h1>{orderNumber}</h1>
          </div>
          <div className="total-crypto-pmt-div">
            <h2 className="pulse-pmt-color">{!costInGivenCrypto ? "Loading..." : `Send Exactly: ${pmtMethod === 'eth' ? costInGivenCrypto + orderNumber.slice(4, -5) : pmtMethod === 'btc' ? costInGivenCrypto + orderNumber.slice(4, -5) : ""} $${pmtMethod.toUpperCase()} ${pmtMethod === 'eth' ? `(Mainnet Only)` : ''}`}</h2>
            <div className="qr-pmt-div">
              <h3>Payment QR Code</h3>
              {/* <img id="pay-eth-qr-code" src={pmtMethod === 'eth' ? qrCode : pmtMethod === 'btc' ? btcQrCode : ""} alt="qrCodeAddress" /> */}
              
              <QRCodeGenerator
                costInCrypto={pmtMethod === 'eth' ? costInGivenCrypto + orderNumber.slice(4, -5) : pmtMethod === 'btc' ? costInGivenCrypto + orderNumber.slice(4, -5) : ""}
                cryptoPmtMethod={pmtMethod}
                pmtAddress={pmtMethod === 'eth' ? ethScAddress : btcAddress}
                pmtMethod={pmtMethod}
              />
            </div>
            <div className="addy-pmt-div">
              <div>
                <h3>Payment Address</h3>
                <span className="order-pay-info-span-2">{pmtMethod === 'eth' ? ethScAddress : pmtMethod === 'btc' ? btcAddress : ""}</span>
                <button className={copyState ? 'coppied-popup' : ''} onClick={handleCopyAddy}>{copyState ? 'Coppied ✔' : 'Click to Copy'}</button>
              </div>
            </div>
          </div>
          {openMoreInfo ? (
            <div>
              <InfoPopupModal
                onClose={() => changeOpenMoreInfo(false)}
                clearLocalOrder={() => clearLocalOrder()}
              />
            </div>
          ) : (
            <div>
              <button className="open-close-info-btn" onClick={() => changeOpenMoreInfo(true)}>MORE INFO!</button>
            </div>
          )}
        </div>
      ) : (
        <div className="order-not-found-div">
          <h1>No Order Number Found!</h1>
          <h2>Continue by giving us your order number</h2>
          <form className="order-num-form" onSubmit={setLocalOrder}>
            <input 
              name="orderNumber"
              className="order-num-input"
              placeholder="Enter Order Number"
              type="text"
            />
            <button className="order-num-button" type="submit">
              Send Number
            </button>
          </form>
          <h2>Don't have your order number?</h2>
          <h2>Email Us! <Link to='mailto:Olympicpharma@proton.me'>Olympicpharma@proton.me</Link></h2>
        </div>
      )}
    </div>
  );  
}

export default EthPayment;
