import './BTS.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarDropdown from '../../Comps/Extras/datePicker';
import { format } from 'date-fns'; 


const IntData = () => {
  const [credData, setCredData] = useState(localStorage.getItem('hasAccess?') ? { credentials: localStorage.getItem('hasAccess?') } : { credentials: '' } );
  const [currentPass, changePass] = useState(false);
  const [currentData, changeData] = useState([]);
  const [accessCode, changeAccessCode] = useState('');
  const [loadingOrder, setLoadingOrder] = useState(null); // Add loading state
  const [filterDropdown, changeFilterDropdown] = useState(null)
  const [message, setMessage] = useState('');
  const [openFilters, changeFilterState] = useState(false);
  
  
  const [intDataJSONForm, setIntDataJSONForm] = useState(''); // Initialize as an empty string

  const handleDateChange = (date) => {
    console.log('Raw date object:', date); // Log the raw date object

    if (date) {
      const formattedDate = format(date, 'MM-dd-yyyy'); // Format the date
      console.log('Formatted date:', formattedDate); // Log the formatted date
      setIntDataJSONForm(formattedDate); // Set the formatted date to state
    } else {
      console.log('Date cleared or invalid.'); // Log if the date is cleared
      setIntDataJSONForm(''); // Reset to an empty string
    }
  };

  // Filters
  const [paymentMethod, setPaymentMethod] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [orderCryptoOwed, setOrderCryptoOwed] = useState('');
  const [orderUSDOwed, setOrderUSDOwed] = useState('');
  const [orderCusEmail, setOrderCusEmail] = useState('');
  const [orderCusFName, setOrderCusFName] = useState('');
  const [orderCusLName, setOrderCusLName] = useState('');


  useEffect(() => {
    if (currentPass) {
      const ws = new WebSocket('ws://localhost:3000') || new WebSocket('ws://olympianpharma.ltd/');
  
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // Only update state if necessary
        changeData((prevData) =>
          prevData.map((item) =>
            item.orderNumber === data.orderNumber && item.orderStatus !== data.orderStatus
              ? { ...item, orderStatus: data.orderStatus }
              : item
          )
        );
      };
  
      // Cleanup WebSocket connection when component unmounts or currentPass changes
      return () => {
        ws.close();
      };
    }
  }, [currentPass]);
  

  const reFetchData = async () => {
    try {
      const response = await fetch('/api/backend-auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ authCode: credData.credentials }),
    });
      const data = await response.json();
      if (data && data !== 'nope') {
        const changedData = Object.values(data).filter(
          (item) => item !== data['00OrderNumber']
        );
        changeData(changedData);
      }
    } catch (error) {
      console.error('Error re-fetching data:', error);
    }
  };

  const handleCredChange = (e) => {
    const { name, value } = e.target;
    setCredData({ ...credData, [name]: value });
  };

  const loginBack = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/backend-auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ authCode: credData.credentials }),
      });
      const data = await response.json();
      if (!response.ok) {
        alert('Incorrect Authentication');
      } else if (data === 'nope') {
        changePass(false);
        alert('Incorrect Authentication');
        console.error('Not correct credentials');
      } else {
        console.log('passed');
        const changedData = Object.values(data).filter(
          (item) => item !== data['00OrderNumber'] && item !== data['00users']
        );
        changeData(changedData);
        changeAccessCode(credData.credentials);
        localStorage.setItem('hasAccess?', credData.credentials)
        changePass(true);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  

  const changeOrderStatus = async (orderNumber, newOrderStatus) => {
    const authCode = accessCode;
    const url = `/api/backend-auth/${orderNumber}`;

    setLoadingOrder(orderNumber);

    try {
      const response = await axios.post(url, {
        authCode,
        orderStatus: newOrderStatus,
      });
      console.log('Order status updated successfully:', response.data);
      await reFetchData()
      setLoadingOrder(null)
    } catch (error) {
      console.error('Error updating order status:', error);
    } 
  };

  const [loadingAutoCheck, setLoadingAutoCheck] = useState(false)
  const checkPayments = async (event) => {
    event.preventDefault();
    const authCode = accessCode;
    try {
      setLoadingAutoCheck(true)
      const response = await axios.post('/api/backend-check/check-pmts', { authCode });
      setLoadingAutoCheck(false)
      reFetchData()
      setMessage(response.data.message || 'Payments checked successfully');
    } catch (error) {
      console.error('Error checking payments:', error);
      setMessage(error.response?.data?.error || 'Error checking payments');
    }
  };
  

  
  
  const handlePaymentMethodChange = (e) => {
    if (e.target.id === paymentMethod) {
      setPaymentMethod('');
    } else {
      setPaymentMethod(e.target.id);
    }
  };

  const handleOrderStatusChange = (e) => {
    if (e.target.id === orderStatus) {
      setOrderStatus('');
    } else {
      setOrderStatus(e.target.id);
    }
  };

  const handleSearch = (type) => {
    if (type === 'orderNumber') {
      console.log('Searching for Order Number:', orderNumber);
    } else if (type === 'orderDate') {
      console.log('Searching for Order Date:', orderDate);
    }
  };

  const filteredData = currentData.filter((item) => {
    return (
      (!paymentMethod || item.paymentMethod === paymentMethod) &&
      (!orderStatus || item.orderStatus === orderStatus) &&
      (!orderNumber || item.orderNumber.includes(orderNumber)) &&
      (!orderDate || item.date.includes(orderDate))&&
      (!orderCryptoOwed || item.costInGivenCrypto.includes(orderCryptoOwed))&&
      (!orderUSDOwed || item.totalCost.includes("$" + orderUSDOwed))&&
      (!orderCusEmail || item.userData.customerEmail.includes(orderCusEmail))
    );
  });


  const userDataChanger = (key) => {
    if (filterDropdown === key) {
      changeFilterDropdown(null)
    } else {
      changeFilterDropdown(key)
    }
  }



  
  
  const getDataFiles = async (e) => {
    e.preventDefault();
    const authCode = accessCode;
    console.log("intDataJSONForm: ", intDataJSONForm)

    try {
        const response = await fetch('/api/backend-check/create-all-files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authCode: authCode,
                jsonData: filteredData,
                dateGiven: intDataJSONForm === '' || intDataJSONForm === null ? null : intDataJSONForm,
            }),
        });

        if (response.ok) {
            const result = await response.json();
            const { files } = result;

            // Function to create and download a file
            const downloadFile = (data, fileName) => {
                const blob = new Blob([data], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            };

            // Trigger file downloads
            downloadFile(files.shippingList, `Shipping-List-${intDataJSONForm === '' || intDataJSONForm === null ? 'all-dates' : intDataJSONForm}.csv`);
            downloadFile(files.orderList, `Order-List-${intDataJSONForm === '' || intDataJSONForm === null ? 'all-dates' : intDataJSONForm}.csv`);

            console.log('Files created and downloaded successfully');
        } else {
            console.error('Failed to create files');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};


  
  
  
  



const [uniqueSortedDates, setUniqueSortedDates] = useState([]);
  const [activeMonthDropdown, setActiveMonthDropdown] = useState(null);
  const [activeDayDropdown, setActiveDayDropdown] = useState(null);
  const [expandedUserDropdown, setExpandedUserDropdown] = useState(null);
  const [loadingOrderStatus, setLoadingOrderStatus] = useState(null);

  useEffect(() => {
    // Check if filteredData is an array
    if (!Array.isArray(filteredData)) return; // Prevent errors if not an array

    // Extract dates from filteredData
    const dates = filteredData.map(item => item.date);

    // Check if dates array has valid dates
    if (dates.some(date => isNaN(new Date(date)))) {
      console.warn('One or more dates are invalid:', dates);
      return; // Skip sorting if any date is invalid
    }

    // Sort dates in descending order (latest first)
    const sorted = dates.sort((a, b) => new Date(b) - new Date(a));

    // Only update sorted dates state if different
    if (JSON.stringify(sorted) !== JSON.stringify(uniqueSortedDates)) {
      setUniqueSortedDates(sorted);
    }
  }, [filteredData, uniqueSortedDates]);

  // Function to toggle the month dropdown
  const toggleMonthDropdown = (month) => {
    setActiveMonthDropdown(prev => (prev === month ? null : month));
  };

  // Function to toggle the day dropdown
  const toggleDayDropdown = (month, day) => {
    const key = `${month}-${day}`;
    setActiveDayDropdown(prev => (prev === key ? null : key));
  };


  


  return (
    <div className="int-data-main">
      <div className="int-data-top">
        <h2>Integrated Data</h2>
        {currentPass ? (
          <div className='file-creation-form-div'>
            <form onSubmit={getDataFiles}>
              <DatePicker
                selected={intDataJSONForm ? intDataJSONForm : null} // Parse string back to Date for the calendar
                onChange={handleDateChange} // Ensure this is calling handleDateChange
                isClearable
                placeholderText="Select a date"
                dateFormat="MM/dd/yyyy" // Ensure date format is compatible
              />

              <button
                className='file-create-btn'
                type='submit'
              >
                Get Files
              </button>
            </form>
          </div>
        ) : ''}
      </div>
      {!currentPass ? (
        <div>
          <h2>Please Enter Credentials</h2>
          <form onSubmit={loginBack}>
            <input
              placeholder="credentials"
              type="text"
              name="credentials"
              value={credData.credentials}
              onChange={handleCredChange}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      ) : (
        <div className="all-data-bts-div">
          <div className='auto-eth-check-bts'>
            <form>
              <h3>$ETH Payments:</h3>
              <div>
                <button id='eth-autocheck-btn' onClick={checkPayments}>Auto Check</button>
                <span>{loadingAutoCheck ? "Loading..." : ""}</span>
              </div>
              <h5>It'll get 80%+ of Payments</h5>
              <a
              target='_blank' 
              rel="noopener noreferrer"
              href='https://etherscan.io/address/0x2366F4B9473951179bb09e2412d978e0B5efcD90'>
                Contract on Etherscan</a>
              <br/>
              <a 
              target='_blank' 
              rel="noopener noreferrer"
              href='https://platform.arkhamintelligence.com/explorer/address/0x2366F4B9473951179bb09e2412d978e0B5efcD90'>
                Contract on Arkham</a>
            </form>
            <form>
            <h3>$BTC Payments:</h3>
              <a 
              target='_blank' 
              rel="noopener noreferrer"
              href='https://www.blockchain.com/explorer/addresses/btc/bc1qjrq3lq7aluvx8paygrvfk0swefgzvhcpffwkta'>
                Address on Blockchain.com</a>
              <br/>
              <a 
              target='_blank' 
              rel="noopener noreferrer"
              href='https://platform.arkhamintelligence.com/explorer/address/bc1qjrq3lq7aluvx8paygrvfk0swefgzvhcpffwkta'>
                Address on Arkham</a>
              </form>
          </div>
          <div className='filters-div-bts'>
            <div>
              <h2 onClick={() => changeFilterState(!openFilters)}>{`Filters ${openFilters ? "▲" : "▼"}`}</h2>
            </div>
            {openFilters ? (
            <div>
              <div className="full-filter-form-bts">
                <form>
                  {/* Payment Methods */}
                  <div>
                    <h3>Payment Methods</h3>
                    <input
                      id='Ethereum'
                      type='checkbox'
                      checked={paymentMethod === 'Ethereum'}
                      onChange={handlePaymentMethodChange}
                    /> Ethereum
                    <br/>
                    <input
                      id='Bitcoin'
                      type='checkbox'
                      checked={paymentMethod === 'Bitcoin'}
                      onChange={handlePaymentMethodChange}
                    /> Bitcoin
                  </div>
                  {/* Order Status */}
                  <div>
                    <h3>Order Status</h3>
                    <input
                      id='Received'
                      type='checkbox'
                      checked={orderStatus === 'Received'}
                      onChange={handleOrderStatusChange}
                    /> Received
                    <br/>
                    <input
                      id='Confirmed'
                      type='checkbox'
                      checked={orderStatus === 'Confirmed'}
                      onChange={handleOrderStatusChange}
                    /> Confirmed
                    <br/>
                    <input
                      id='Shipped'
                      type='checkbox'
                      checked={orderStatus === 'Shipped'}
                      onChange={handleOrderStatusChange}
                    /> Shipped
                  </div>
                </form>
                {/* Order Number & Order Date */}

                <div>
                  <div>
                    <form className='customer-details-bts-1 input-forms-bts' onSubmit={(e) => { e.preventDefault(); handleSearch('orderNumber'); }}>
                      <h3>Order Info</h3>
                      <input
                        type='text'
                        placeholder='Order Number'
                        value={orderNumber}
                        onChange={(e) => setOrderNumber(e.target.value)}
                      />
                      <input
                        type='text'
                        placeholder='Order Date'
                        value={orderDate}
                        onChange={(e) => setOrderDate(e.target.value)}
                      />
                      {/* <button type='submit'>Search</button> */}
                    </form>
                  </div>
                  {/* Crypto Owed & USD Owed */}
                  <div>
                    <form className='customer-details-bts-1 input-forms-bts' onSubmit={(e) => { e.preventDefault(); handleSearch('orderDate'); }}>
                      <h3>Payment Info</h3>
                      <input
                        type='text'
                        placeholder='Crypto Owed'
                        value={orderCryptoOwed}
                        onChange={(e) => setOrderCryptoOwed(e.target.value)}
                      />
                      <input
                        type='text'
                        placeholder='USD Owed'
                        value={orderUSDOwed}
                        onChange={(e) => setOrderUSDOwed(e.target.value)}
                      />
                      {/* <button type='submit'>Search</button> */}
                    </form>
                  </div>
                </div>
                {/* Customer Email */}
                <div>
                  <form className='customer-details-bts-1 input-forms-bts' onSubmit={(e) => { e.preventDefault(); handleSearch('orderDate'); }}>
                    <h3>Customer Details</h3>
                    <input
                      type='text'
                      placeholder='Customer Email'
                      value={orderCusEmail}
                      onChange={(e) => setOrderCusEmail(e.target.value)}
                    />
                    <input
                      type='text'
                      placeholder='Customer First Name'
                      value={orderCusFName}
                      onChange={(e) => setOrderCusFName(e.target.value)}
                    />
                    <input
                      type='text'
                      placeholder='Customer Last Name'
                      value={orderCusLName}
                      onChange={(e) => setOrderCusLName(e.target.value)}
                    />
                    {/* <button type='submit'>Search</button> */}
                  </form>
                </div>
              </div>
            </div>
            ) : ''}
          </div>
           {/* Display Filtered Data */}
           <div className='dates-dropdown-total-div'>
      {uniqueSortedDates
        .map(date => new Date(date).toLocaleString('default', { month: 'long' })) // Extract month names
        .filter((month, index, self) => self.indexOf(month) === index) // Ensure unique months
        .map(month => {
          const days = uniqueSortedDates
            .filter(date => new Date(date).toLocaleString('default', { month: 'long' }) === month) // Filter days for the month
            .map(date => new Date(date).getDate()); // Get day numbers

          const uniqueDays = [...new Set(days)]; // Ensure unique days

          return (
            <div key={month} className='month-dropdown-div'>
              <button
                className='month-dropdown-btn'
                onClick={() => toggleMonthDropdown(month)}
              >
                {month}
                {activeMonthDropdown === month ? "▲" : "▼"}
              </button>
              {activeMonthDropdown === month && (
                <div className='days-dropdown-div'>
                  {uniqueDays.map(day => (
                    <div key={day} className='day-dropdown-bts-div'>
                      <button
                        className='day-dropdown-bts-btn'
                        onClick={() => toggleDayDropdown(month, day)} // Handle day dropdown
                      >
                        {`Day ${day}`}
                        <span id='received-total'>
                          {` (${filteredData.filter(item => new Date(item.date).getDate() === day && new Date(item.date).toLocaleString('default', { month: 'long' }) === month && item.orderStatus === 'Received').length})`}
                        </span>
                        <span id='confirmed-total'>
                          {`(${filteredData.filter(item => new Date(item.date).getDate() === day && new Date(item.date).toLocaleString('default', { month: 'long' }) === month && item.orderStatus === 'Confirmed').length})`}
                        </span>
                        <span id='shipped-total'>
                          {`(${filteredData.filter(item => new Date(item.date).getDate() === day && new Date(item.date).toLocaleString('default', { month: 'long' }) === month && item.orderStatus === 'Shipped').length})`}
                        </span>
                        <span>{`(${filteredData.filter(item => new Date(item.date).getDate() === day && new Date(item.date).toLocaleString('default', { month: 'long' }) === month).length}) `}</span>
                        {activeDayDropdown === `${month}-${day}` ? "▲" : "▼"}
                      </button>
                      {activeDayDropdown === `${month}-${day}` && (
                        <div>
                          {filteredData
                            .filter(item => new Date(item.date).getDate() === day && new Date(item.date).toLocaleString('default', { month: 'long' }) === month)
                            .map((item, key) => (
                              <div key={key}>
                                <hr className='bts-hr' />
                                <section key={item.orderNumber}>
                                  <div className='order-num-status-div-btc'>
                                    <div className='order-num-div-bts'>
                                      <h2>{`Order Number: `}</h2>
                                      <span
                                        className={
                                          item.orderStatus === 'Received'
                                            ? 'bts-rec'
                                            : item.orderStatus === 'Confirmed'
                                            ? 'bts-conf'
                                            : item.orderStatus === 'Shipped'
                                            ? 'bts-ship'
                                            : 'bts-error'
                                        }
                                      >{` ${item.orderNumber}`}</span>
                                    </div>
                                    <h3 className='status-bts'>
                                      {`Order Status: `}
                                      {loadingOrderStatus === item.orderNumber ? (
                                        <span>Loading...</span>
                                      ) : (
                                        <span
                                          className={
                                            item.orderStatus === 'Received'
                                              ? 'bts-rec order-stat-bts-span'
                                              : item.orderStatus === 'Confirmed'
                                              ? 'bts-conf order-stat-bts-span'
                                              : item.orderStatus === 'Shipped'
                                              ? 'bts-ship order-stat-bts-span'
                                              : 'bts-error order-stat-bts-span'
                                          }
                                        >{`${item.orderStatus}`}</span>
                                      )}
                                      <button
                                        onClick={() =>
                                          changeOrderStatus(item.orderNumber, 'Received')
                                        }
                                        className='bts-status-btns'
                                      >
                                        R
                                      </button>
                                      <button
                                        onClick={() =>
                                          changeOrderStatus(item.orderNumber, 'Confirmed')
                                        }
                                        className='bts-status-btns'
                                      >
                                        C
                                      </button>
                                      <button
                                        onClick={() =>
                                          changeOrderStatus(item.orderNumber, 'Shipped')
                                        }
                                        className='bts-status-btns'
                                      >
                                        S
                                      </button>
                                    </h3>
                                    <h3>{`Refer: ${item.orderReferral}`}</h3>
                                  </div>

                                  <div className='all-data-bts-div-2'>
                                    <div>
                                      <h3>{`Payment Method: ${item.paymentMethod}`}</h3>
                                      <h3>{`Dollar Amount: ${item.totalCost}`}</h3>
                                      <h3
                                        className='user-data-drop-bts'
                                        onClick={() => userDataChanger(key)}
                                      >
                                        {`User Data ${expandedUserDropdown === key ? '▲' : '▼'}`}
                                      </h3>
                                    </div>
                                    <div>
                                      <h3>{`Payment Owed: ${item.costInGivenCrypto}`}</h3>
                                      <h3>{`Date Paid: ${item.date}`}</h3>
                                      <h3
                                        className='user-data-drop-bts'
                                        onClick={() => userDataChanger(key + 0.1)}
                                      >
                                        {`Order Items ${expandedUserDropdown === key + 0.1 ? '▲' : '▼'}`}
                                      </h3>
                                    </div>
                                  </div>
                                </section>
                                {/* Add user data and order items rendering as per your original structure */}
                                {filterDropdown === key ? (
                            <div>
                              <hr className='bts-hr'/>
                              <div className='user-data-bts-div'>
                                <div className='user-data-bts-sections'>
                                  <div>
                                    <h3>{`First Name: `}</h3>
                                    <span className='bts-span-answers'>{item.userData.customerFName}</span>
                                  </div>
                                  <div>
                                    <h3>{`Last Name: `}</h3>
                                    <span className='bts-span-answers'>{item.userData.customerLName}</span>
                                  </div>
                                  <div>
                                    <h3>{`Email: `}</h3>
                                    <span className='bts-span-answers'>{item.userData.customerEmail}</span>
                                  </div>
                                </div>
                          
                                <div className='user-data-bts-2div'>
                                  <div className='user-data-bts-sections'>
                                    <div>
                                      <h3>{`Address: `}</h3>
                                      <span className='bts-span-answers'>{item.userData.customerAddress}</span>
                                    </div>
                                    <div>
                                      <h3>{`Country: `}</h3>
                                      <span className='bts-span-answers'>{item.userData.customerCountry}</span>
                                    </div>
                                  </div>
                                  <div className='user-data-bts-sections'>
                                    {
                                      item.userData.customerState === null ? '' : (
                                        <div>
                                          <h3>{`State: `}</h3>
                                          <span className='bts-span-answers'>{item.userData.customerState}</span>
                                        </div>
                                      )
                                    }
                                    <div>
                                      <h3>{`Postal Code: `}</h3>
                                      <span className='bts-span-answers'>{item.userData.customerPostalCode}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : filterDropdown === key + .1 ? (
                            <div>
                              <hr className='bts-hr'/>
                              <div className='user-items-bts-div'>
                                <div className='user-items-inner-bts'>
                                  {item.items.map((items) => (
                                    <div className='each-item-bts' key={items.name}>
                                      <div className='user-data-bts-sections'>
                                        <div className='each-item-bts-name'>
                                          <h3>{`Name: `}</h3>
                                          <span className='bts-span-answers'>{items.name}</span>
                                        </div>
                                        <div className='each-item-bts-quant-price'>
                                          <div>
                                            <h3>{`Quantity: `}</h3>
                                            <span className='bts-span-answers'>{items.quantity}</span>
                                          </div>
                                          <div>
                                            <h3>{`Price: `}</h3>
                                            <span className='bts-span-answers'>{items.price}</span>
                                          </div>
                                        </div>
                                        {items.concentration ? (
                                          <div className='bts-vol-conc-div'>
                                            <div>
                                              <h3>{`Concentration: `}</h3>
                                            </div>
                                            <div>
                                              <span className='bts-span-answers'>{items.concentration}</span>
                                            </div>
                                          </div>
                                        ) : ''}
                                        {items.volume ? (
                                          <div className='bts-vol-conc-div'>
                                            <div>
                                              <h3>{`Volume: `}</h3>
                                            </div>
                                            <div>
                                              <span className='bts-span-answers'>{items.volume}</span>
                                            </div>
                                          </div>
                                        ) : ''}
                                        {items.carrierOil ? (
                                          <div className='bts-vol-conc-div'>
                                            <div>
                                              <h3>{`Carrier Oil: `}</h3>
                                            </div>
                                            <div>
                                              <span className='bts-span-answers'>{items.carrierOil}</span>
                                            </div>
                                          </div>
                                        ) : ''}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
    </div>

        </div>
      )}
    </div>
  );
};

export default IntData;
