import React from "react";
import './subCat.css'
import { Link, useParams } from "react-router-dom";

import globalProducts from "../../Comps/Products/products";
import prodSubCategories from "../../Comps/Products/productSubCategories";

import OPLogo from '../../images/OPLogo3.png'


const SubCats = () => {
  const { subCatName } = useParams();

  return (
    <div className="sub-cat-main">
      <div className="sub-cat-side">
        <img src={OPLogo} alt="OPLogo"/>
      </div>
      <div className="all-products-outside">
      <h1>{subCatName?.toUpperCase()}</h1>
      <div className="all-products">
        <Link to='/store' className="back-btn-div-cats">
          <button className="subcat-back-btn">{`Back <`}</button>
        </Link>
      {globalProducts.map(item => (
        <div key={item.productId}>
          {prodSubCategories.filter(sub => sub.subName?.toLowerCase() === subCatName?.toLowerCase()).map(sub => (
            <div key={sub.subCatId}>
              {item.subCategory === sub.subCatId ? 
              (<Link className="subcat-products" to={'/store/' + sub.subName?.toLowerCase() + '/' + item.productId}>
                <img src={item.img} alt={item.name}/>
                <div className={item.discountedPrice === null ? "subcat-prod-main" : "subcat-prod-long"}>
                  <h4>{item.name}</h4>
                  {item.discountedPrice === null ? (
                    <h4>{'Price: $' + item.basePrice + ".00"}</h4>
                  ) : item.discountedPrice < 1 ? (
                    <div>
                      <h4 className="subcat-product-cost-slash">{'Price: $' + item.basePrice + ".00"}</h4>
                      {/* <h4>{`Price: $${item.basePrice * item.discountedPrice}`}</h4> */}
                      <h4>{(item.basePrice * item.discountedPrice % 1) === 0 ? `Price: $${item.basePrice * item.discountedPrice}.00` :  `Price: $${Math.round(item.basePrice * item.discountedPrice * 10)/10}0`}</h4>
                    </div>
                  ) : item.discountedPrice >= 1 ? (
                    <div>
                      <h4 className="subcat-product-cost-slash">{'Price: $' + item.basePrice + ".00"}</h4>
                      <h4>{`Price: $${item.basePrice - item.discountedPrice}.00`}</h4>
                    </div>
                  ) : (
                    <h4>{'Price: $' + item.basePrice + ".00"}</h4>
                  )}
                </div>
              </Link>)
                :
              null
            }
            </div>
          ))}
        </div>
      ))}
      </div>

      </div>
    </div>
  );
};


export default SubCats;