// Comps/CryptoPrice/ethPriceContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getEthereumPrice } from './ethPrice';

export const EthereumPriceContext = createContext();

export const EthereumPriceProvider = ({ children }) => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const ethPrice = await getEthereumPrice();
      setPrice(ethPrice);
    };

    fetchPrice();
  }, []);

  return (
    <EthereumPriceContext.Provider value={price}>
      {children}
    </EthereumPriceContext.Provider>
  );
};
