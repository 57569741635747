// context/EthereumPriceContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getSolanaPrice } from './solPrice';
import { getDatabase, onValue, ref, set, runTransaction } from 'firebase/database'


export const SolanaPriceContext = createContext();

export const SolanaPriceProvider = ({ children }) => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const solPrice = await getSolanaPrice();
      setPrice(solPrice);
    };

    fetchPrice();
  }, []);

  return (
    <SolanaPriceContext.Provider value={price}>
      { children }
    </SolanaPriceContext.Provider>
  );
};



export default SolanaPriceProvider





























































































































































































































































































































































































































































































































































