import React, { useEffect, useState } from "react";
import './postCheckout.css';
import { Link } from "react-router-dom";
import Spinner from "../../../Comps/Extras/loadingIcon";

const PostCheckout = () => {
  const [orderNumber, setOrderNumber] = useState(localStorage.getItem('jhlltd-order-num'));
  const [orderStatusInfo, setOrderStatusInfo] = useState("")
  const [orderItems, setOrderItems] = useState(false)
  const [currentUserInfo, setuserInfo] = useState(false)

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try{
        const response = await fetch(`/api/order-status/${orderNumber}`);
        const data = await response.json();
        console.log(data)
        if (data.error) {
          setOrderStatusInfo({ error: data.error });
        } else {
          setOrderStatusInfo(data);
        }
      } catch (error) {
        console.error('Error fetching order status:', error);
        setOrderStatusInfo({ error: 'Failed to fetch order status' });
      }
    }
    const fetchOrderItems = async () => {
      try{
        const response = await fetch(`/api/order-items/${orderNumber}`);
        const data = await response.json();
        console.log(data)
        if (data.error) {
          setOrderItems(false);
        } else {
          setOrderItems(data);
        }
      } catch (error) {
        console.error('Error fetching Order Items:', error);
        setOrderItems({ error: 'Failed to fetch Order Items' });
      }
    }
    const fetchUserInfo = async () => {
      try{
        const response = await fetch(`/api/user-info/${orderNumber}`);
        const data = await response.json();
        console.log(data)
        if (data.error) {
          setuserInfo(false);
        } else {
          console.log(data)
          setuserInfo(data);
        }
      } catch (error) {
        console.error('Error fetching User Data:', error);
        setuserInfo({ error: 'Failed to fetch User Data' });
      }
    }

    fetchOrderStatus()
    fetchOrderItems()
    fetchUserInfo()
  }, [orderNumber])

  const clearLocalOrder = () => {
    setOrderNumber('')
    localStorage.removeItem('jhlltd-order-num')
    localStorage.removeItem('jhlltd-order-num-pmt')
  }

  const setLocalOrder = async (event) => {
    event.preventDefault();
    const orderNumber = event.target.elements.orderNumber.value;
    
      const response = await fetch(`/api/payment-method/${orderNumber}`)
      const data = await response.json();
      console.log(data)
      if (data.error) {
        console.error('Not a correct order number')
      } else {
        if (data === "Ethereum") {
          localStorage.setItem('jhlltd-order-num-pmt', 'eth');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
        } else if (data === "Bitcoin") {
          localStorage.setItem('jhlltd-order-num-pmt', 'btc');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
        }
      }
  };  

  return (
    <div className="post-checkout-main">
    {orderNumber ? (
      <div className="post-checkout-main-div1">
        <div className="post-checkout-sec-1">
          <div>
            <h1>Order Status</h1>
            <h2>Order Number: <span>{orderNumber}</span></h2>
            <button onClick={clearLocalOrder}>Change Order Number</button>
          </div>
        </div>
        <div className="post-checkout-sections">
          <div>
            <div className="post-checkout-sec-2">
              <div className="post-checkout-sec-2-div">
                {!orderStatusInfo ? (
                  <div className="spinner-post-checkout">
                    <Spinner/>
                    <h2>Loading Status...</h2>
                  </div>
                ) : (
                  <div>
                    <h2><span>{orderStatusInfo === "Received" ? "🟢" : "🔴"}</span>Received</h2>
                    <h2><span>{orderStatusInfo === "Confirmed" ? "🟢" : "🔴"}</span>Confirmed</h2>
                    <h2><span>{orderStatusInfo === "Shipped" ? "🟢" : "🔴"}</span>Shipped</h2>
                  </div>
                )}
              </div>
            </div>
            <div className="post-checkout-sec-3">
              <div>
                <h2>Recieved Means:</h2>
                <p>
                  Your order has been submitted and recorded.  We are working on verifying your payment, this could take up to 48 hours to complete.  
                </p>
                <h2>Confirmed Means:</h2>
                <p>
                  Your payment has been confirmed.  We will send you confirmation with tracking once your order ships!
                </p>
                <h2>Shipped Means:</h2>
                <p>
                  We shipped your order!  Check the tracking info we sent to get more info!
                </p>
                <h2>Questions?:</h2>
                <p>
                  Contact Us: <Link to='mailto:Olympicpharma@proton.me'>Olympicpharma@proton.me</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="post-right-section">
            <div>
              <h2>Basic User Info</h2>
              {currentUserInfo ? (
                <div className="post-order-items-div">
                  <div className="post-order-items-indiv-div">
                    <div className="post-order-item-sections">
                      <h3>Name:</h3>
                      <p>{`${currentUserInfo.customerFName} ${currentUserInfo.customerLName}`}</p>
                    </div>
                    <div className="post-order-item-sections">
                      <h3>Email:</h3>
                      <p>{`${currentUserInfo.customerEmail}`}</p>
                    </div>
                    <div className="post-order-item-sections">
                      <h3>Country:</h3>
                      <p>{`${currentUserInfo.customerCountry}`}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Spinner/>
                  <h2>Loading User...</h2>
                </div>
              )}
              <h2>Order Items</h2>
              {orderItems && orderItems.length > 1 ? (orderItems.map((items) => (
                  <div className="post-order-items-div">
                    <div className="post-order-items-indiv-div">
                    <hr/>
                    <div className="post-order-item-sections">
                      <h3>Item:</h3>
                      <p>{items.name}</p>
                    </div>
                    <div className="post-order-item-sections">
                      <h3>Quantity:</h3>
                      <p>{items.quantity}</p>
                    </div>
                    {items.carrierOil ? (
                    <div className="post-order-item-sections">
                      <h3>Carrier Oil:</h3>
                      <p>{items.carrierOil}</p>
                    </div>
                    ) : ''}
                    {items.volume ? (
                    <div className="post-order-item-sections">
                      <h3>Volume:</h3>
                      <p>{items.volume}</p>
                    </div>
                    ) : ''}
                    {items.concentration ? (
                    <div className="post-order-item-sections">
                      <h3>Concentration:</h3>
                      <p>{items.concentration}</p>
                    </div>
                    ) : ''}
                  </div>
                </div>
                ))
              ) : orderItems && orderItems.length === 1 ? (
                <div className="post-order-items-div">
                  <div className="post-order-items-indiv-div">
                  <hr/>
                  <div className="post-order-item-sections">
                    <h3>Item:</h3>
                    <p>{orderItems[0].name}</p>
                  </div>
                  <div className="post-order-item-sections">
                    <h3>Quantity:</h3>
                    <p>{orderItems[0].quantity}</p>
                  </div>
                  {orderItems[0].carrierOil ? (
                  <div className="post-order-item-sections">
                    <h3>Carrier Oil:</h3>
                    <p>{orderItems[0].carrierOil}</p>
                  </div>
                  ) : ''}
                  {orderItems[0].volume ? (
                  <div className="post-order-item-sections">
                    <h3>Volume:</h3>
                    <p>{orderItems[0].volume}</p>
                  </div>
                  ) : ''}
                  {orderItems[0].concentration ? (
                  <div className="post-order-item-sections">
                    <h3>Concentration:</h3>
                    <p>{orderItems[0].concentration}</p>
                  </div>
                  ) : ''}
                </div>
              </div>
              )
             : (
                <div>
                  <Spinner/>
                  <h2>Loading Items...</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="order-not-found-div">
          <h1>No Order Number Found!</h1>
          <h2>Continue by giving us your order number</h2>
          <form className="order-num-form" onSubmit={setLocalOrder}>
            <input 
              name="orderNumber"
              className="order-num-input"
              placeholder="Enter Order Number"
              type="text"
            />
            <button className="order-num-button" type="submit">
              Send Number
            </button>
          </form>
          <h2>Don't have your order number?</h2>
          <Link to='mailto:Olympicpharma@proton.me'>
            <h2>{`Email Us! Olympicpharma@proton.me`}</h2>
          </Link>
        </div>
    )}
    </div>
  )
}

export default PostCheckout;