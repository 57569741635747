import React, { createContext, useContext, useReducer, useEffect } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      const existingCartItem = state.cart.find(
        item => item.productBackendId === action.payload.productBackendId
      );
      if (existingCartItem) {
        return {
          ...state,
          cart: state.cart.map(item =>
            item.productBackendId === action.payload.productBackendId
              ? { ...item, quantity: item.quantity + 1 }
              : item
          )
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, { ...action.payload, quantity: 1 }]
        };
      }
    case 'REMOVE_ITEM':
      return {
        ...state,
        cart: state.cart.map(item =>
          item.productBackendId === action.payload.productBackendId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        ).filter(item => item.productBackendId !== action.payload.productBackendId || item.quantity !== 0)
      };
    case 'REMOVE_ITEM_TOTAL':
      return {
        ...state,
        cart: state.cart.map(item =>
          item.productBackendId === action.payload.productBackendId
            ? { ...item, quantity: 0 }
            : item
        ).filter(item => item.productBackendId !== action.payload.productBackendId || item.quantity !== 0)
      };
    case 'CLEAR_CART':
      return {
        ...state,
        cart: []
      };
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.payload
      };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, { cart: [], paymentMethod: 0, totalItems: 0 }, () => {
    const localData = localStorage.getItem('cartState');
    return localData ? JSON.parse(localData) : { cart: [], paymentMethod: 0, totalItems: 0 };
  });

  useEffect(() => {
    localStorage.setItem('cartState', JSON.stringify(state));
  }, [state]);

  const totalCost = state.cart.reduce((total, item) => total + item.price * item.quantity, 0);
  const totalCostShipping = state.cart.reduce((total, item) => total + item.price * item.quantity, 0) >= 500 ? state.cart.reduce((total, item) => total + item.price * item.quantity, 0) : state.cart.reduce((total, item) => total + item.price * item.quantity, 0) + 14;
  const totalItems = state.cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <CartContext.Provider value={{ cart: state.cart, totalCost, totalCostShipping, totalItems, paymentMethod: state.paymentMethod, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
