import React from 'react';
import './loadingIcon.css';

const Spinner = () => (
  <div className="main-spinner">
    <div className="circle"></div>
  </div>
);

export default Spinner;
