import React, { useState } from "react";
import './faqs.css';
import faqDatabase from "../../Comps/Extras/faqsDatabase";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs-main">
      {/* Heading */}
      <div className="faqs-heading">
        <h1>FAQs</h1>

        {/* FAQS */}
        <div className="faqs-div">
          {faqDatabase.map((item, index) => (
            <div key={index} onClick={() => handleToggle(index)} className="faq-item">
              <div className="faq-question">
                <p>{item.Question}</p>
                <span className="faq-toggle">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
                {item.hasSubs ? (
                  <div>
                  {item.subTitles.map((subTitle, subIndex) => (
                    <div key={subIndex}>
                      <p className="faq-subtitle">{subTitle}</p>
                      <p>{item.Answer[subIndex]}</p>
                    </div>
                  ))}
                  {item.Note.length === 0 ? '' : 
                    <p><span className="note-span">Note: </span>{ item.Note}</p>
                  }
                  {item.Notice.length === 0 ? '' : 
                    <p><span className="note-span">Notice: </span>{ item.Notice}</p>
                  }
                  </div>
                ) : (
                  <div>
                  {item.Answer.map((line, lineIndex) => (
                    <p key={lineIndex}>{line}</p>
                  ))}
                  {item.Note.length === 0 ? '' : 
                    <p><span className="note-span">Note: </span>{ item.Note}</p>
                  }
                  {item.Notice.length === 0 ? '' : 
                    <p><span className="note-span">Notice: </span>{ item.Notice}</p>
                  }
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
