const { initializeApp } = require("firebase/app");
const { getDatabase } = require("firebase/database");

let firebaseApp;
let db;

const fetchFirebaseConfig = async () => {
  try {
    const response = await fetch('/api/firebase-config', {
      credentials: 'include' // Include cookies in the request
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const config = await response.json();
    return config;
  } catch (error) {
    console.error('Error fetching Firebase config:', error);
    throw error;
  }
};

const initializeFirebase = async () => {
  if (!firebaseApp) {
    const firebaseConfig = await fetchFirebaseConfig();
    firebaseApp = initializeApp(firebaseConfig);
    db = getDatabase(firebaseApp);
  }
  return { firebaseApp, db };
};

module.exports = { initializeFirebase };
