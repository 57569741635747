import React, { useEffect } from "react";
import './testReports.css'
import { Link } from "react-router-dom";

import allTestReports from "../../Comps/Products/testReports";

const TestReports = () => {
  const totalWithin10 = allTestReports.filter(item => item.within10).length;
  const totalAbove10 = allTestReports.filter(item => !item.within10).length;

  return (
    <div className="test-reports-main">
      {/* Heading */}
      <div className="test-reports-heading">
        <h1>Test Reports</h1>
      </div>
      <div className="lower-test-report-div">
        <div>
          <h3>{`(${totalWithin10}) Within 10%`}</h3>
          {allTestReports.filter((items) => items.within10).map((tests) => (
            <Link className="testing-links" to={tests.reportLink} target="_blank">
              <p>{tests.name}</p>
            </Link>
          ))}
        </div>
        <hr/>
        <div>
          <h3>{`(${totalAbove10}) Outside 10%`}</h3>
          {allTestReports.filter((items) => !items.within10).map((tests) => (
            <Link className="testing-links" target="_blank" to={tests.reportLink}>
              <p>{tests.name}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TestReports;