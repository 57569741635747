import React from "react";
import './steroidProfiles.css'
import { Link } from "react-router-dom";

import steroidProfilesData from "../../Comps/Products/steroidProfiles";

const SteroidProfiles = () => {
  

  return (
    <div className="steroid-profiles">
      <h2 className="steroid-profiles-h2">Steroid Profiles</h2>
      <div className="steroid-profiles-div">
        <h3>Anabolic Steroids and Performance-Enhancing Drugs</h3>
        <p className="steroid-profiles-sub-1">Anabolic Steroids</p>

        <div>
          {steroidProfilesData.filter((item) => item.spMainCategory === 1).map((item2) => (
            <Link 
            className="steroid-p-links pulse-links-ster"
            to={'/steroid-profiles/' + item2.spId}>
              {item2.spName}
              </Link>
          ))}
        </div>

        <p className="steroid-profiles-sub-1">Other Performance - Enhancing Drugs (PEDs) and Ancillary Drugs</p>

        <div>
          {steroidProfilesData.filter((item) => item.spMainCategory === 2).map((item2) => (
            <Link 
            className="steroid-p-links pulse-links-ster"
            to={'/steroid-profiles/' + item2.spId}>
              {item2.spName}
              </Link>
          ))}
        </div>
      <h3 className="about-author-h2">About the Author</h3>
      <h4 className="about-author-h4">Bill Roberts</h4>

      <p className="about-author-p">Bill Roberts is an internationally recognised expert on anabolic steroids and performance-enhancing drugs (PEDs). His education was not specifically ‘’geared’ towards anabolic steroids other than expertise with pharmacological principles having broad applications. This has allowed Bill to provide unique insight into the field of anabolic pharmacology with knowledge of points which he would not have known otherwise.</p>

      </div>
    </div>
  )
}

export default SteroidProfiles;